import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Tooltip,
  IconButton,
  Grid
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

function FormActions(props) {
  const { noEdit, noDelete, customActions } = props;

  if (!(props.onEdit || props.onCancel || props.onSave || props.onDelete))
    console.warn('You made an instance of FormActions with no supplied actions');

  return (
    <>
      <Grid container spacing={2}>
        {customActions && !props.onSave && customActions.map((item, i) => <Grid item key={i}> {item} </Grid>)}
        {props.onEdit && !noEdit &&
          <Grid item>
            <Tooltip title="Edit">
              <IconButton
                edge='start'
                color='inherit'
                aria-label='Edit'
                onClick={props.onEdit}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        }
        {props.onDelete && !noDelete &&
          <Grid item>
            <Tooltip title="Delete">
              <IconButton
                edge='start'
                color='inherit'
                aria-label='Delete'
                onClick={props.onDelete}
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        }
        {props.onSave &&
          <Grid item>
            <Tooltip title="Save">
              <div>
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='Save'
                  onClick={props.onSave}
                  disabled={props.disableSave}
                >
                  <SaveIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Grid>
        }
        {props.onCancel &&
          <Grid item>
            <Tooltip title="Cancel">
              <IconButton
                edge='start'
                color='inherit'
                aria-label='Cancel'
                onClick={props.onCancel}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        }
      </Grid>
    </>
  );
}

FormActions.propTypes = {
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  customActions: PropTypes.arrayOf(PropTypes.node),

  disableSave: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    role: state.user.getIn(['user', 'role']),
  };
}

export default connect(mapStateToProps)(FormActions);
