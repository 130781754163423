import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
//import PropTypes from 'prop-types';

import { CompleteForm } from '../Form';

import {
  adminChangePasswordAPIPost
} from '../../actions/sites';
import { camelToSnake } from '../../lib/api';

const defaultPassword = {
  email: '',
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
}

function AdminChangePassword(props) {
  const { role, email, accessToken } = props;

  defaultPassword.email = email

  /*
   * Return an error message if anything is bad, otherwise null.
   */
  function validateEdits(defaultPassword) {

    // TODO other validation

    if (_.some([['oldPassword'], ['newPassword'], ['confirmPassword']], k => !_.get(defaultPassword, k)))
      return "You haven't updated all the required fields!";

    return null;
  }

  async function onSave(defaultPassword) {
    defaultPassword = camelToSnake(defaultPassword);

    try {
      const data = {
        email: defaultPassword.email,
        oldPassword: defaultPassword.old_password,
        newPassword: defaultPassword.new_password,
        accessToken: accessToken
      }
      await props.adminChangePasswordAPIPost(data, true);
      return {success: true, message: 'Administrator Password Changed!!!'};
    }
    catch(error) {
      return {success: false, message: 'Administrator Password Change failed!!!'};
    }
  }

  return (
    <CompleteForm
      title={'Change Password'}
      object={defaultPassword}
      //newObject={!admin}
      validateFunc={validateEdits}
      //noEdit
      onSave={onSave}
      //onCancel={hist.push(Routes.HOMEPAGE)}
      role={role}
      fields={{
        ...GENERAL_FIElDS,
      }} />
  );
}

const GENERAL_FIElDS = {
  General: [{
    label: 'Email',
    key: ['email'],
    disabled: true
  }, {
    label: 'Old Password',
    key: ['oldPassword'],
  }, {
    label: 'New Password',
    key: ['newPassword']
  }, {
    label: 'Confirm Password',
    key: ['confirmPassword']
  }, ],
};

function mapStateToProps (state) {
  return {
    email: state.user.getIn(['user', 'email']),
    accessToken: state.user.getIn(['accessToken']),
    role: state.user.getIn(['user', 'role'])
  }
}

export default connect(mapStateToProps, { adminChangePasswordAPIPost })(AdminChangePassword);

