import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import { NavLink } from 'react-router-dom'
// import { List, ListItem } from '@material-ui/core'
// import ListItemText from '@material-ui/core/ListItemText/ListItemText'

import Copyright from './Copyright'

export default function Footer (props) {
	 //const [showDialog, setShowDialog] = useState(false)
	 const classes = useStyles();

  return (
    <div className={classes.container}>
      {/* <List className={classes.linkBox}>
        <ListItem button component={NavLink} to='/dashboard'>
          <ListItemText primary='DASHBOARD' />
        </ListItem>
        <ListItem button component={NavLink} to='/site'>
          <ListItemText primary='SITE' />
        </ListItem>
        <ListItem button component={NavLink} to='/affiliates'>
          <ListItemText primary='AFFILIATES' />
        </ListItem>
        <ListItem button component={NavLink} to='/orders'>
          <ListItemText primary='ORDERS' />
        </ListItem>
        <ListItem button component={NavLink} to='/about'>
          <ListItemText primary='ABOUT' />
        </ListItem>
      </List> */}
      <Copyright />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      height: 10
    },
    [theme.breakpoints.up('md')]: {
      height: 10
    },
    [theme.breakpoints.up('lg')]: {
      height: 10
    }
  },
  linkBox: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
      width: 200,
      padding: 10
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 8,
      width: 500,
      padding: 10
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 8,
      width: 800,
      padding: 10
    }
  }
}))
