import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import immutableTransform from 'redux-persist-transform-immutable'
import storage from 'redux-persist/lib/storage'

import display from './display';
import sites from './sites';
import user from './user';

const rootConfig = {
  key: 'root',
  storage,
  transforms: [immutableTransform()],
  whitelist: ['user', 'sites'],
}

const rootReducer = persistReducer(rootConfig, combineReducers({
  display,
  sites,
  user,
}));

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))
export const persistor = persistStore(store);
//persistor.purge();
