import { getAffiliateURL, getAffiliateDisableURL } from '../lib/api';
import { siteAPIGet } from './sites';
import { apiPut, apiPost, apiDelete } from '.';

export function getAffiliates(params) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getAffiliates without a site');

    return dispatch(siteAPIGet(getAffiliateURL(site.id), params));
  };
}

export function getAffiliateDownline(affiliateId, params) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getAffiliates without a site');

    return dispatch(
      siteAPIGet(getAffiliateURL(site.id) + `/${affiliateId}/summary`, params)
    );
  };
}

export function getAffiliate(affiliateId, params) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call selectAffiliate without a site');

    return dispatch(
      siteAPIGet(getAffiliateURL(site.id) + `/${affiliateId}`, params)
    );
  };
}

export function affiliateAPIPut(affiliateId, params, camelTransform) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call affiliateAPIPut without a site');

    return dispatch(
      apiPut(
        getAffiliateURL(site.id) + `/${affiliateId}`,
        params,
        camelTransform
      )
    );

    //await dispatch(selectAffiliate(affiliateId));
  };
}

export function affiliateAPIPost(affiliate, camelTransform) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call affiliateAPIPost without a site');

    return dispatch(
      apiPost(getAffiliateURL(site.id), affiliate, camelTransform)
    );

    //await dispatch(selectAffiliate(affiliateId));
  };
}

export function affiliateAPIDisable(params, camelTransform) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    console.log(params);
    if (!site) throw Error('Tried to call affiliateAPIPut without a site');

    return dispatch(apiPost(getAffiliateDisableURL(site.id), params));

    //await dispatch(selectAffiliate(affiliateId));
  };
}

export function affiliateAPIDelete(affiliateId) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call affiliateAPIPut without a site');

    return dispatch(apiDelete(getAffiliateURL(site.id) + `/${affiliateId}`));
  };
}
