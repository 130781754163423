import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, LinearProgress, Tooltip } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';

import { CompleteForm } from '../components/Form';
import PlanStructure from '../components/MLM/PlanStructure';

import { postSiteCommisions, updateSiteCommisions, getSiteCommissionsSnapshot, updateSiteCommisionsSnapshot } from '../actions/sites';
import { hasAdmin, hasSuper } from '../lib';
import { useHistory } from 'react-router';
import Routes from '../routes';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select
} from '@material-ui/core';
import { createMonthlySnapshot } from '../actions/sites';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { getSiteCommissionsHistory } from '../actions/sites';

function Commissions(props) {
  const { role, getSiteCommissionsHistory, getSiteCommissionsSnapshot, updateSiteCommisionsSnapshot } = props;
  const classes = useStyles();
  const history = useHistory();
  const [isSnapshot, setIsSnapshot] = useState(false);
  const [commissions, setCommissions] = useState(undefined);
  const [period, setPeriod] = useState("");
  const [monthData, setMonthData] = useState([]);
  const [snapshotMonth, setSnapshotMonth] = useState('');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [processing, setProcessing] = useState(false);
  const [addAlert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [getPlanData, setPlansDataStaus] = useState(false);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    if (getPlanData) {
      setProcessing(true);
      getSiteCommissionsHistory()
      .then((res) => setPlans(res.compSnapshots)) // add catch block here
      .catch((error) => setCommissions(undefined), console.error(error.message))
      .finally(() => setProcessing(false),setPlansDataStaus(false));
    }
  }, [getPlanData, error, getSiteCommissionsHistory]);

  const handleClickOpen = () => {
    setPlansDataStaus(true);
    setSnapshotMonth('');
    setError('');
    setOpen(true);
  };

  const handleClose = () => {
    if(!processing) {      
      setOpen(false);
      setError('');
      setProcessing(false);
      setSnapshotMonth(''); // Reset the selected month
    }
  };

  async function handleProcess() {
    // Check if the selected value is valid
    if (!snapshotMonth) {
      setError('Please select a snapshot month.');
    } else {
      setProcessing(true);
      try {
        await props.createMonthlySnapshot(snapshotMonth);
        setAlert(true);
        setAlertMessage('New Snapshot Created!!!');
        setAlertSeverity('success');
        setProcessing(false);
        setOpen(false);
      }
      catch(error) {
        setAlert(true);
        setAlertMessage(error.message);
        setAlertSeverity('error');
        setProcessing(false);
      }
    }
  };
  
  const handleMonthChange = (event) => {
    let monthValue = event.target.value;
    setSnapshotMonth(event.target.value);
    if(monthValue) {
      setError('');
    }
  };

  function getYearAndMonthObjects() {
    const today = new Date();
    const year  = today.getFullYear();
    const month = today.getMonth() + 1; // Adding 1 because months are 0-based
    const yearAndMonthObjects = [];
  
    for (let i = 0; i < 4; i++) {
      let currentMonth  = month - i;
      let currentYear   = year;

      if (currentMonth <= 0) {
        currentMonth += 12; // Adjust month to a positive value by looping back to December
        currentYear -= 1; // Decrease year by 1 since we've moved to the previous year
      }

      const formattedMonth = currentMonth.toString().padStart(2, '0');
  
      const yearMonthObject = {
        value: `${currentYear}-${formattedMonth}`,
        label: `${currentYear}-${formattedMonth}`
      };
  
      yearAndMonthObjects.push(yearMonthObject);
    }
  
    return yearAndMonthObjects;
  }

  //subscribe to route changes
  useEffect(() => {
    setMonthData(getYearAndMonthObjects());
    const unsubscribe = history.listen((location, action) => {
      refreshIsSnapshot();
    });
    refreshIsSnapshot();
    return unsubscribe;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //refresh on isSnapshot change
  useEffect(() => {
    if (isSnapshot) {
      setCommissions(undefined);
      getSiteCommissionsSnapshot(period)
        .then((res) => setCommissions(res.compSnapshot.levels));
    } else {
      setCommissions([...props.commissions]);
    }
  }, [isSnapshot, props.commissions]); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshIsSnapshot = () => {
    const periodIndex = history.location.pathname.lastIndexOf('history/') + 8;
    //if not found periodIndex = -1
    if (periodIndex !== 7) {
      setPeriod(history.location.pathname.substring(periodIndex));
      setIsSnapshot(true);
    } else {
      setIsSnapshot(false);
    }
  }

  async function onSave(updatedComs) {

    if (isSnapshot) {
      try {
        await updateSiteCommisionsSnapshot(period, { comp_snapshot: { levels: updatedComs } });
        return { success: true, message: "Snapshot updated succesfully" };
      }
      catch (e) {
        console.log('Commissions saving failed!', { e });
        return { success: false, message: "Action unsuccessfull!" };
      }
    }

    try {
      await props.postSiteCommisions({ configuration: { levels: updatedComs } });
    }
    catch (error) {
      console.log('Commissions saving failed!', { error });
      return { success: false, message: "Something broke" };
    }

    //refresh commisions in local storage, without this changes are visible only after new login
    props.updateSiteCommisions(updatedComs);
    return { success: true, message: "Comp plan updated succesfully!!!!" };
  }

  function validateComms(levels) {
    let error = null;

    _.forEach(levels, lvl => {
      if (error)
        return;

      if (!lvl.type)
        error = 'You must set a type for your level';

      _.forEach(lvl.tiers, (tier, i) => {
        if (!_.every([tier.from, tier.rate], _.isNumber)
          && (i === 0 || lvl.tiers[i - 1].from < tier.from))
          error = "Invalid tier settings";
      });

      if (lvl.trigger) {
        if (!lvl.trigger.bucket)
          error = 'Trigger requires a bucket';

        else if (lvl.trigger.sumLevels.length === 0)
          error = 'Trigger requires selected levels';
      }
    });

    return error;
  }

  // if you click things too fast you can beat the commissions loading, this is just a safety
  if (!commissions)
    return <LinearProgress />;

  return (
    <>
    {processing &&
      <LinearProgress className={classes.progress} />
    }
    {addAlert &&
      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={true}
        autoHideDuration={2000}
        onClose={() => setAlert(false)} >
        <Alert severity={alertSeverity}>{alertMessage}</Alert>
      </Snackbar>
    }
    <CompleteForm
      className={classes.header}
      title={isSnapshot ? `Commission Plan Snapshot - ${period}` : "Commission Plan"}
      object={commissions}
      noEdit={isSnapshot ? !hasSuper(role) : !hasAdmin(role)}
      noDelete
      onSave={onSave}
      validateFunc={validateComms}
      formRender={({ editing, object, onUpdate }) => (
        <PlanStructure
          editing={editing}
          levels={object}
          onUpdate={onUpdate}
          isCompPlan
        />
      )}
      extraFormActions={[
        <>
          { hasAdmin(role) &&
          <div>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} style={{marginTop:"5px"}}>
              Create Snapshot
            </Button>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Select a Month for Create Snapshot</DialogTitle>
              <DialogContent>
                <Select
                  value={snapshotMonth}
                  displayEmpty
                  placeholder="Snapshot Month"
                  className={classes.selectEmpty} 
                  onChange={handleMonthChange}
                  disabled={processing}
                >
                  <MenuItem value="">
                    <em>Select Snapshot Month</em>
                  </MenuItem>
                  { monthData && monthData.map(month => (
                    <MenuItem key={`site-${month.value}`} value={month.value} 
                      disabled={plans.some((item) => item.snapshotPeriod === month.value)}
                    >
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
                { error && <p style={{ color: 'red' }}>{error}</p> }
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="secondary" disabled={processing}>
                  Cancel
                </Button>
                <Button onClick={handleProcess} color="primary" disabled={processing}>
                  Process
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          }
        </>,
        <Tooltip title="History">
          <IconButton
            edge='start'
            color='inherit'
            aria-label='History'
            onClick={() => history.push(Routes.COMMISSIONS_HISTORY)}
          >
            <HistoryIcon />
          </IconButton>
        </Tooltip>
      ]}
    />
    </>
  );
}

Commissions.propTypes = {
  commissions: PropTypes.array,
  postSiteCommisions: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  const site = state.sites.get('site');

  // Check if site exists and has the 'commissions' property, otherwise use an empty array
  const commissions = site && site.commissions ? site.commissions : [];

  // Check if state.user.getIn(['user', 'role']) exists, otherwise use an empty string
  const role = state.user.getIn(['user', 'role']) || '';

  return {
    commissions,
    role
  };
}

export default connect(mapStateToProps, { postSiteCommisions, updateSiteCommisions, getSiteCommissionsSnapshot, updateSiteCommisionsSnapshot, createMonthlySnapshot, getSiteCommissionsHistory })(Commissions);

const useStyles = makeStyles({
  header: {
    marginBottom: 25,
  },
});
