import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import DeleteButton from '../DeleteButton';
import NumericInput from '../NumericInput';
import VolumeTypeInput from './VolumeTypeInput';
import InclusionInput from './InclusionInput';

export default function Trigger(props) {
  const {
    trigger,
    editable,
    isBonuses,
    levelOptions,
    onChangeBucket,
    onChangeInclusionInput,
    onChangeThreshold,
    onChangeLevels,
    onChangeRecruitBucket,
    onChangeRecruitThreshold,
    onChangeRecruits,
    onDeleteTrigger,
    onCreateTrigger,
  } = props;

  const classes = useStyles();

  function onLevelSelect(index) {
    return () => {
      const newLevels = [...trigger.sumLevels];

      const currIndex = newLevels.indexOf(index);

      if (currIndex >= 0) newLevels.splice(currIndex, 1);
      else {
        newLevels.push(index);
        newLevels.sort();
      }

      onChangeLevels(newLevels);
    };
  }

  return (
    <>
      <Grid container alignItems='center' spacing={2}>
        <Grid item>
          <Typography variant='h6'>Trigger(s):</Typography>
        </Grid>
        {editable && (
          <Grid item>
            {trigger ? (
              <DeleteButton
                aria-label='Delete Trigger(s)'
                onDelete={onDeleteTrigger}
              />
            ) : (
              <IconButton
                edge='start'
                color='inherit'
                aria-label='Create Trigger'
                onClick={onCreateTrigger}
              >
                <AddIcon />
              </IconButton>
            )}
          </Grid>
        )}
      </Grid>
      {trigger && (
        <>
          <Grid container alignItems='center' spacing={1}>
            <Grid item>
              <NumericInput
                value={trigger.threshold}
                onChange={onChangeThreshold}
                minValue={0}
                label='Threshold'
                unitText='$'
                unitTextPosition='start'
                textFieldProps={{
                  InputProps: {
                    readOnly: !editable,
                  },
                }}
              />
            </Grid>
            <Grid item>
              <VolumeTypeInput
                value={trigger.bucket}
                onChange={onChangeBucket}
                readOnly={!editable}
              />
            </Grid>
            {isBonuses && (
              <>
                <Grid item>
                  <InclusionInput
                    value={trigger.logic}
                    onChange={onChangeInclusionInput}
                    readOnly={!editable}
                  />
                </Grid>
                <Grid item>
                  <NumericInput
                    value={trigger.recruits}
                    onChange={onChangeRecruits}
                    minValue={0}
                    label='Recruits'
                    unitText='#'
                    unitTextPosition='start'
                    textFieldProps={{
                      InputProps: {
                        readOnly: !editable,
                      },
                    }}
                  />
                </Grid>
                <Grid item>
                  <NumericInput
                    value={trigger.recruitThreshold}
                    onChange={onChangeRecruitThreshold}
                    minValue={0}
                    label='Recruit Threshold'
                    unitText='$'
                    unitTextPosition='start'
                    textFieldProps={{
                      InputProps: {
                        readOnly: !editable,
                      },
                    }}
                  />
                </Grid>
                <Grid item>
                  <VolumeTypeInput
                    value={trigger.recruitBucket}
                    onChange={onChangeRecruitBucket}
                    readOnly={!editable}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container alignItems='center' spacing={1}>
            <Grid item key={`trigger-sum-level-label`}>
              <Typography display='inline' variant='subtitle2'>
                Sum Levels:
              </Typography>
            </Grid>
            {levelOptions.map((i) => (
              <Grid item key={`trigger-${i}`}>
                <FormControlLabel
                  label={`Level ${i}`}
                  control={
                    <Checkbox
                      color='primary'
                      disabled={!editable}
                      checked={trigger.sumLevels.includes(i)}
                      onChange={onLevelSelect(i)}
                      classes={{ disabled: trigger.sumLevels.includes(i) ? classes.primaryColor : undefined }}
                    />
                  }
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {!editable && !trigger && <Typography>None</Typography>}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  primaryColor: {
    color: `${theme.palette.primary.main} !important`
  }
}));

Trigger.propTypes = {
  trigger: PropTypes.shape({
    bucket: PropTypes.string.isRequired,
    threshold: PropTypes.number.isRequired,
    logic: PropTypes.string,
    recruitBucket: PropTypes.string,
    recruitThreshold: PropTypes.number,
    recruits: PropTypes.number,
    sumLevels: PropTypes.arrayOf(PropTypes.number).isRequired,
  }),
  levelOptions: PropTypes.arrayOf(PropTypes.number),
  editable: PropTypes.bool.isRequired,
  isBonuses: PropTypes.bool.isRequired,

  onChangeBucket: PropTypes.func.isRequired,
  onChangeThreshold: PropTypes.func.isRequired,
  onChangeLevels: PropTypes.func.isRequired,
  onDeleteTrigger: PropTypes.func.isRequired,
  onCreateTrigger: PropTypes.func.isRequired,
};

Trigger.defaultProps = {
  editable: true,
};
