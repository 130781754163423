import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import moment from 'moment';

import {
  COLUMNS,
  formatAffiliates,
  getChildrenToDepth,
  parseCommission,
} from '../../lib/reports';
import { useMonthlyCommissionsData } from '../../lib/hooks';
import Report from '.';


function processData({ affiliates, comms, levels, periodDate, filterThisPeriod }) {
  function addValue(aff, key, value) {
    aff[key] = (aff[key] || 0) + value;
  }

  function joinedThisPeriod(aff) {
    return moment(aff.createdAt).isSame(periodDate, 'month');
  }

  affiliates = formatAffiliates({ affiliates, periodDate });
  // calculate stuff (aggregating everything for available levels, some to be pruned later)
  _.forEach(comms, comm => {
    const aff = affiliates[comm.affiliateId];
    if (!aff) {
      console.warn('Missing Affilate', comm)
      return
    }
    
    if (comm.level === 0) {
      aff.PV = comm.personalVolume;
      aff.CV = comm.customerVolume;
      aff.AV = aff.PV + aff.CV;
    }
    else {
      const lvlPrefix = `level${comm.level}`;
      const result = parseCommission(comm);
      if (result.CV)
        addValue(aff, `${lvlPrefix}-CV`, result.CV);
      if (result.PV)
        addValue(aff, `${lvlPrefix}-PV`, result.PV);
    }
  });
  _.forEach(affiliates, aff => {
    // calculate level AVs
    _.forEach(_.range(1, levels), l => {
      aff[`level${l}-AV`] = (aff[`level${l}-PV`] || 0) + (aff[`level${l}-CV`] || 0);
      aff[`level${l}-recruits`] = 0;
    });

    // calculate level recruiting
    const deepChildren = getChildrenToDepth(affiliates, aff.id, levels);
    _.forEach(deepChildren, (lvlChildren, i) => {
      const l = i + 1;
      _.forEach(lvlChildren, childId => {
        // add the recruit if we aren't filter or the recruit joined this period
        if(!filterThisPeriod || joinedThisPeriod(affiliates[childId])) {
          addValue(aff, `level${l}-recruits`, 1);
        }
        else {
          // in this case, we are both filtering AND the affiliate did not join this period
          // so we need to remove their commisions from the level totals
          const commsToSub = _.filter(comms, comm => {
            return comm.affiliateId === parseInt(childId) && comm.level === 0;
          });

          _.forEach(commsToSub, comm => {
            addValue(aff, `level${l}-CV`, -comm.customerVolume);
            addValue(aff, `level${l}-PV`, -comm.personalVolume);
            addValue(aff, `level${l}-AV`, -comm.personalVolume-comm.customerVolume);
          })
        }
      });
    });
  });
  return _.values(affiliates);
}

export default function Recruits(props) {
  const { filterThisPeriod } = props;

  function calculateRows(config) {
    return processData({filterThisPeriod, ...config});
  }

  const report = useMonthlyCommissionsData({ calculateRows });
  const { levels } = report;

  const columns = COLUMNS.BASE_AFFILIATE_COLS.concat([
    // TODO(kev) put these back in if you want
    //COLUMNS.PV,
    //COLUMNS.CV,
    //COLUMNS.NV,
    //COLUMNS.AV,
    ..._.flatMap(_.range(1, levels), l => [{
        title: `L${l} Recruits`,
        field: `level${l}-recruits`,
      }, {
        title: `L${l} CV`,
        field: `level${l}-CV`,
        type: 'currency',
      }, {
        title: `L${l} PV`,
        field: `level${l}-PV`,
        type: 'currency',
      }, {
        title: `L${l} AV`,
        field: `level${l}-AV`,
        type: 'currency',
      }
    ]),
  ]);

  return (
    <Report
      title={`${filterThisPeriod ? 'New ' : 'Total '}Recruits Report`}
      exportTitle={`Recruits${filterThisPeriod ? '-Period' : ''}`}
      columns={columns}
      {...report} />
  )
}

Recruits.propTypes = {
  filterThisPeriod: PropTypes.bool
};
