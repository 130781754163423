import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import MaterialTable from '@material-table/core';
import ExportPdf from '@material-table/exporters/pdf';
import ExportCsv from '@material-table/exporters/csv';

export default function CommissionsTable(props) {
  const { rows } = props;

  const columns = useMemo(() =>
    [
      {
        title: 'Period',
        field: 'snapshotPeriod',
        align: 'left',
        render: rowData => (
          <Link to={{ pathname: `/commissions/history/` + rowData.snapshotPeriod }}>{rowData.snapshotPeriod}</Link>),
        defaultSort: 'desc'
      },
    ]
    , []);

  return (
    <MaterialTable
      title='Commission Plan History'
      columns={columns}
      data={rows}
      options={{
        sorting: true,
        idSynonym: 'snapshotPeriod',
        showTitle: false,
        searchFieldAlignment: 'left',
        exportAllData: true,
        filtering: false,
        paging: true,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50, 100, 500],
        tableLayout: 'fixed',
        padding: 'dense',
        emptyRowsWhenPaging: false,
        exportMenu: [{
          label: 'Export PDF',
          exportFunc: (cols, datas) => ExportPdf(cols, datas, "Commission_Plan_History")
        },
        {
          label: 'Export CSV',
          exportFunc: (cols, datas) => ExportCsv(cols, datas, "Commission_Plan_History")
        }]
      }}
    />
  );
}

CommissionsTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      snapshotPeriod: PropTypes.string.isRequired,
    })
  )
};
