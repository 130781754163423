import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom'

import { useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  CssBaseline,
  Drawer,
  AppBar,
  Grid,
  Button,
  Divider,
  IconButton,
  ListItemText,
  Popper,
  Grow,
  Paper,
  MenuList,
  ClickAwayListener,
  Tooltip,
  MenuItem,
  Toolbar,
  Select
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Routes from '../routes';
import { selectSite } from '../actions/sites';
import { apiGet } from '../actions';
import { logOut } from '../actions/user';
import { MainListItems, SecondaryListItems } from './LeftNavItems';

import { hasSuper } from '../lib';

function Nav (props) {
  const { user, site, sites, role } = props
  const classes = useStyles();
  const [open, setOpen] = useState(true)
  const path = useLocation().pathname;
  const history = useHistory();
  const [openProfile, setOpenProfile] = useState(false);
  const anchorRef = useRef(null);

  const disableSiteSelect = (
    path.includes(Routes.AFFILIATE.slice(0, path.indexOf('/', 1))) ||
    path.includes(Routes.SHARE.slice(0, path.indexOf('/', 1))) ||
    path.includes(Routes.BONUS.slice(0, path.indexOf('/', 1))) ||
    path.includes(Routes.ORDER.slice(0, path.indexOf('/', 1)))
  );

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleToggle = () => {
    setOpenProfile((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenProfile(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenProfile(false);
    }
  }

  const onSelectSite = event => {
    props.selectSite(event.target.value)
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position='absolute' className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <IconButton
                edge='start'
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                className={clsx(classes.menuButton, open && classes.menuButtonHidden)} >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item>
              {sites && (
                <Select
                  value={site?.commissions ? site.id : ''}
                  displayEmpty
                  placeholder="Select a Site"
                  className={classes.selectEmpty}
                  onChange={onSelectSite}
                  inputProps={{
                    disabled: disableSiteSelect,
                  }} >
                  <MenuItem value="">
                    <em>Select Site</em>
                  </MenuItem>
                  {sites.map(s => (
                    <MenuItem key={`site-${s.id}`} value={s.id}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Grid>
            <Grid item>
            {hasSuper(role) &&
              <Tooltip title="Add Site">
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='Add Site'
                  onClick={() => {
                    props.selectSite(null);
                    history.push(Routes.NEW_SITE);
                  }}>
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
              }
            </Grid>
          </Grid>

          <div style={{flexGrow: 1}} />

          <Grid container alignItems='center' justifyContent='flex-end' spacing={2}>
            {/* <Grid item>
              <Button onClick={() => apiGet("https://api.vitalibis.com/sites", true)}>
                TEST THINGS
              </Button>
            </Grid> */ }
            <Grid item>
              <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                >
                {user.email}
              </Button>
              <Popper open={openProfile} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                          <MenuItem
                              button
                              component={NavLink}
                              to={Routes.ADMIN_CHANGE_PASSWORD}
                              onClick={handleClose}
                              // onClick={() => {
                              //   handleClose();
                              //   history.push(Routes.ADMIN_CHANGE_PASSWORD);
                              // }}
                              >Change Password
                          </MenuItem>
                          <MenuItem onClick={e => {
                            handleClose(e);
                            props.logOut();
                          }}>
                            Logout
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
        }}
        open={open}
        >
        <div className={classes.toolbarIcon}>
          <ListItemText>Partner Portal</ListItemText>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
          <MainListItems role={role} />
        <Divider />
          <SecondaryListItems role={role} />
      </Drawer>
    </div>
  );
}

function mapStateToProps (state) {
  return {
    appState: state,
    site: state.sites.get('site'),
    sites: state.sites.get('sites'),
    user: state.user.get('user'),
    role: state.user.getIn(['user', 'role'])
  }
}

export default connect(mapStateToProps, {logOut, selectSite, apiGet})(Nav);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  },
  toolbar: {
    padding: 0,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
}));
