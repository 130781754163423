import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  Grid,
  Paper,
  LinearProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Title from "../../components/Title";
import PeriodPicker from "../PeriodPicker";
import Performance from './AffiliatePerformance';
import OrdersByHour from './AffiliateOrdersByHour';
import Revenue from './AffiliateRevenue';
import OrderTable from '../Orders/OrderTable';
import DownlineTable from './AffiliateDownlineTable';
import { getAffiliateDownline } from '../../actions/affiliates';
import { getOrders } from '../../actions/orders';
import { getMonthly, getMonthlyShares, getMonthlyBonuses } from '../../actions/payouts';
import { getSiteCommissionsSnapshot, getSiteShares } from '../../actions/sites';
import { paperStyle } from '../../styles';

function AffiliateDashboard(props) {
  const { getOrders, getMonthly, getMonthlyShares, getAffiliateDownline, affiliateId, shareIds, getMonthlyBonuses, getSiteCommissionsSnapshot, getSiteShares } = props;

  const classes = useStyles()

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState(null);
  const [monthly, setMonthly] = useState(null);
  const [monthlyShares, setMonthlyShares] = useState([]);
  const [monthlyBonuses, setMonthlyBonuses] = useState(null);
  const [snapshotCompPlan, setSnapshotCompPlan] = useState(null);
  const [summary, setSummary] = useState(null);
  const [periodDate, setPeriodDate] = useState(moment().format('YYYY-MM'));

  const createMonthlyShares = (id, name) => {monthlyShares.push({id: id, name: name}); setMonthlyShares([...monthlyShares]);}
  const addMonthlyShares = (shares) => {
    let i = monthlyShares.findIndex(sh => sh.id === shares?.groupId);
    if (i > -1){
      monthlyShares[i] = {...monthlyShares[i], ...shares};
      setMonthlyShares([...monthlyShares]);
    }
  }

  useEffect(() => {
    getAllData();
  }, [getMonthlyShares, shareIds, periodDate, affiliateId, getOrders, getMonthly, getAffiliateDownline, getMonthlyBonuses, getSiteCommissionsSnapshot, getSiteShares]) // eslint-disable-line react-hooks/exhaustive-deps

  const getAllData = async () => {
    setLoading(true);
    //instantly reset array (make it empty)
    monthlyShares.length = 0;

    let startDate = moment(periodDate, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
    let endDate = moment(periodDate, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');

    let setStateFunc = [
      { func: setOrders, attr: 'orders' }, { func: setMonthly, attr: 'commissions' }, 
      { func: setMonthlyBonuses, attr: 'bonuses' }, { func: setSummary, attr: 'summary' }, 
      { func: setSnapshotCompPlan, attr: 'compSnapshot'}, { func: addMonthlyShares, attr: 'share' }
    ];

    //make array of api calls
    let apiCalls = [
      getOrders({ affiliate: affiliateId, from: startDate, to: endDate }),
      getMonthly({ affiliate: affiliateId, period: periodDate }),
      getMonthlyBonuses({ affiliate: affiliateId, period: periodDate }),
      getAffiliateDownline(affiliateId, { from: startDate, to: endDate }),
      getSiteCommissionsSnapshot(periodDate)
    ];

    //get public share plans and add them to array + add private share plans to array
    let shares = await getSiteShares();
    shares.shares.filter(sh => sh.access === 'PUBLIC' || (shareIds && shareIds !== -1 && shareIds.includes(sh.id)))
    .forEach(obj => {apiCalls.push(getMonthlyShares(obj.id, { affiliate: affiliateId, period: periodDate })); createMonthlyShares(obj.id, obj.name)});

    //add private share plans to array
    if (shareIds && shareIds !== -1)
      shareIds.forEach(shareId => apiCalls.push(getMonthlyShares(shareId, { affiliate: affiliateId, period: periodDate })));

    //set states after resolving all api calls
    Promise.allSettled(apiCalls)
      .then(results => {
        results.forEach((apiRes, index) => {
          if (apiRes.status === 'fulfilled') {
            setStateFunc[Math.min(index, 5)].func(apiRes.value[setStateFunc[Math.min(index, 5)].attr]);
          } else {
            console.error(apiRes.reason);
          }
        });
        setLoading(false);
      });
  };

  if (loading)
    return <LinearProgress />;

  return (
    <>
      <div className={classes.paperTitle}>
        <Title>Performance for Period</Title>
        <PeriodPicker
          onDateChange={setPeriodDate}
          typeDisabled
          type='monthly'
          date={periodDate}
          disabled={loading} />
      </div>

      {/* {loading || !monthly || !orders && <LinearProgress className={classes.progress} />} */}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Performance monthly={monthly} shares={monthlyShares} bonuses={monthlyBonuses} periodDate={periodDate} setPeriodDate={setPeriodDate} customLevels={snapshotCompPlan?.levels}/>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className={classes.fixedHeight}>
            <OrdersByHour orders={orders} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={classes.fixedHeight}>
            <Revenue orders={orders} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <OrderTable rows={orders} title='Orders - This Period' affiliate={affiliateId} />
        </Grid>
        <Grid item xs={12}>
          <DownlineTable rows={summary} title='Downline - This Period' affiliate={affiliateId} />
        </Grid>
      </Grid>
    </>
  );
}

AffiliateDashboard.propTypes = {
  //site: PropTypes.object.isRequired,
  // actions
  getOrders: PropTypes.func.isRequired,
  getMonthly: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    site: state.sites.get('site'),
  };
}

export default connect(mapStateToProps, { 
  getOrders, getMonthly, getMonthlyShares, getAffiliateDownline, getMonthlyBonuses, getSiteCommissionsSnapshot, getSiteShares
 })(AffiliateDashboard)

const useStyles = makeStyles(theme => ({
  fixedHeight: {
    ...paperStyle,
    height: 260
  },
  paperTitle: {
    marginBottom: theme.spacing(2),
    ...paperStyle,
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  }
}));
