import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { CountryRegionData } from 'react-country-region-selector';
import MTZ from 'moment-timezone'

import SelectInput from './SelectInput';

const COUNTRY_WHITELIST = new Set(["US", "AU", "CA", "GB", "SG", "HK", "IE", "MX", "CN", "PH"]);
const REGION_BLACKLIST = {
  US: new Set(["American Samoa", "Northern Mariana Islands",
      "Marshall Islands", "Guam", "Armed", "Virgin Islands",
      "Micronesia", "Palau", "Puerto Rico"]),
};

/*
 * Resulting structure of this is:
 * {
 *   "United States": {
 *     code: "US",
 *     name: "United States",
 *     regions: [{
 *       code: "CA",
 *       name: "California"
 *     },
 *     ...]
 *   }
 *   ...
 * }
 */
const COUNTRY_DATA = _.fromPairs(
  CountryRegionData
    .filter(arr => COUNTRY_WHITELIST.has(arr[1]))
    .map(arr => {
      const country = {
        name: arr[0],
        code: arr[1],
        regions: arr[2].split('|').map(region => {
          const [name, code] = region.split('~');
          return {name, code};
        })
      };

      const regionBlacklist = REGION_BLACKLIST[arr[1]];

      if (regionBlacklist)
        country.regions = country.regions.filter(r => !regionBlacklist.has(r.name));

      return [country.name, country]
    })
);

export function CountryPicker(props) {
  const { country, ...other } = props;

  return (
    <SelectInput
      label="Country"
      value={country}
      options={_.keys(COUNTRY_DATA)}
      {...other} />
  );
}

CountryPicker.propTypes = {
  country: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export function RegionPicker(props) {
  const { country, region, ...other } = props;

  const data = COUNTRY_DATA[country];

  return (
    <SelectInput
      label="Region"
      value={region}
      options={data ? _.map(data.regions, 'name') : []}
      {...other} />
  );
}

RegionPicker.propTypes = {
  country: PropTypes.string,
  region: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
}

export function TZPicker(props) {
  const { country, timezone, ...other } = props;
  //const timezoneArray = country && MTZ.tz.zonesForCountry(COUNTRY_DATA[country].code);

  //console.log('TimeZone Array:', timezoneArray)
  const code = COUNTRY_DATA[country] ? COUNTRY_DATA[country].code : null;

  return (
    <SelectInput
      label="Timezone"
      value={timezone}
      options={code ? MTZ.tz.zonesForCountry(code) : []}
      {...other} />
  );
}

TZPicker.propTypes = {
  country: PropTypes.string,
  timezone: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
}
