import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import MaterialTable from '@material-table/core';
import ExportPdf from '@material-table/exporters/pdf';
import ExportCsv from '@material-table/exporters/csv';

export default function SharesTable(props) {
  const { rows, isSnapshots } = props;

  const columns = useMemo(() =>
    [
      ...isSnapshots ? [{
        title: 'Period', field: 'snapshotPeriod', align: 'left',
        render: rowData => <Link to={{ pathname: `/share/snapshot/${rowData.shareId}/${rowData.snapshotPeriod}` }}>{rowData.snapshotPeriod}</Link>,
        defaultSort: 'desc'
      },
      {
        title: 'Share ID', field: 'shareId', type: 'numeric', align: "left",
        render: rowData => <Link to={{ pathname: `/share/snapshot/${rowData.shareId}/${rowData.snapshotPeriod}` }}>{rowData.shareId}</Link>
      }]
        :
        [{
          title: 'Share ID', field: 'id', type: 'numeric', align: "left",
          render: rowData => <Link to={{ pathname: `/share/` + rowData.id }}>{rowData.id}</Link>
        }],
      { title: 'Group', field: 'name' },
      { title: 'Rate', field: 'rate' },
      // { title: 'Levels', field: 'levels' },
    ]
    , [isSnapshots]);

  return (
    <MaterialTable
      title="Shares"
      columns={columns}
      data={rows}
      isLoading={props.isLoading}
      options={{
        sorting: true,
        showTitle: false,
        searchFieldAlignment: 'left',
        exportAllData: true,
        filtering: false,
        paging: true,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50, 100, 500],
        tableLayout: "fixed",
        padding: "dense",
        draggable: false,
        emptyRowsWhenPaging: false,
        exportMenu: [{
          label: 'Export PDF',
          exportFunc: (cols, datas) => ExportPdf(cols, datas, "Shares")
        },
        {
          label: 'Export CSV',
          exportFunc: (cols, datas) => ExportCsv(cols, datas, "Shares")
        }]
      }}
    />
  );
}

SharesTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  isSnapshots: PropTypes.bool,
  isLoading: PropTypes.bool
}
