import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import MaterialTable from '@material-table/core';
import ExportPdf from '@material-table/exporters/pdf';
import ExportCsv from '@material-table/exporters/csv';

import { makeStyles } from '@material-ui/core/styles';

import { paperStyle } from '../../styles';

import Title from "../Title";
import { Link } from 'react-router-dom';

export default function OrderTable(props) {
  const { rows, title } = props;
  const classes = useStyles();

  const renderLink = useCallback(rowData => <Link to={{ pathname: 'order/' + rowData.id }}>{rowData.orderNumber}</Link>, []);

  // we want to display the date without converting to local
  const formatDate = useCallback(rowData => moment.parseZone(rowData.orderDate).format('l LT'), []);

  return (
    <>
      {title &&
        <div className={classes.paperTitle}>
          <Title>{title}</Title>
        </div>
      }
      <MaterialTable
        title="Orders"
        columns={[
          {
            title: 'Order ID', field: 'orderNumber', type: 'numeric', align: "left",
            render: renderLink
          },
          { title: 'Affiliate ID', field: 'affiliateId', type: 'numeric', align: "left",
            render: rowData => <Link to={{ pathname: `/affiliate/` + rowData.affiliateId }}>{rowData.affiliateId}</Link>,
          },
          {
            title: 'Referrer ID', field: 'referrerId',
            render: rowData => <Link to={{ pathname: `/affiliate/` + rowData.referrerId }}>{rowData.referrerId}</Link>,
          },
          { title: 'Email', field: 'customerEmail' },
          {
            title: 'PV', field: 'personalVolume', type: 'currency', headerStyle: { textAlign: 'center' }
          },
          {
            title: 'CV', field: 'customerVolume', type: 'currency', headerStyle: { textAlign: 'center' }
          },
          {
            title: 'NV', field: 'newVolume', type: 'currency', headerStyle: { textAlign: 'center' }
          },
          {
            title: 'Date', field: 'orderDate', align: "left", overflow: 'nowrap', headerStyle: { textAlign: 'center' },
            cellStyle: { whiteSpace: 'nowrap' }, defaultSort: 'desc',
            render: formatDate,
            exportTransformer: formatDate
          }
        ]}
        data={rows}
        options={{
          showTitle: false,
          searchFieldAlignment: 'left',
          exportAllData: true,
          filtering: false,
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 50, 100, 500],
          tableLayout: "fixed",
          padding: "dense",
          draggable: false,
          emptyRowsWhenPaging: false,
          exportMenu: [{
            label: 'Export PDF',
            exportFunc: (cols, datas) => ExportPdf(cols, datas, "Orders")
          },
          {
            label: 'Export CSV',
            exportFunc: (cols, datas) => ExportCsv(cols, datas, "Orders")
          }]
        }}
      />
    </>
  );
}

OrderTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
  title: PropTypes.string,
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  paperTitle: {
    marginBottom: theme.spacing(2),
    ...paperStyle,
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  }
}));
