import React, { useState } from 'react';
import PropTypes from 'prop-types'

import {
  Tooltip,
  IconButton,
  Grid
} from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import OrdersIcon from '@material-ui/icons/ShoppingCart';
import DownlineIcon from '@material-ui/icons/AccountTree';
import InfoIcon from '@material-ui/icons/Info';
import PayoutIcon from '@material-ui/icons/AttachMoney';

function TabButton(props) {
  return (
    <Tooltip title={props.label}>
      <IconButton
        // button
        edge='start'
        aria-label={props.label}
        color={props.selectedTab === props.value ? 'secondary' : 'primary'}
        onClick={() => props.onSelect(props.value)} >
        {props.children}
      </IconButton>
    </Tooltip>
  );
}

export default function AffiliateSubMenu(props) {
  const [selectedTab, setTab] = useState(props.tab || 'information');

  function onSelectTab(value) {
    setTab(value);
    props.onChangeTab(value);
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
        <TabButton value='information' label='Information' onSelect={onSelectTab} selectedTab={selectedTab}>
          <InfoIcon/>
        </TabButton>
      </Grid>
      <Grid item>
        <TabButton value='dashboard' label='Dashboard' onSelect={onSelectTab} selectedTab={selectedTab}>
          <DashboardIcon/>
        </TabButton>
      </Grid>
      <Grid item>
        <TabButton value='orders' label='Orders' onSelect={onSelectTab} selectedTab={selectedTab}>
          <OrdersIcon/>
        </TabButton>
      </Grid>
      <Grid item>
        <TabButton value='downline' label='Downline' onSelect={onSelectTab} selectedTab={selectedTab}>
          <DownlineIcon/>
        </TabButton>
      </Grid>
      <Grid item>
        <TabButton value='payouts' label='Earnings' onSelect={onSelectTab} selectedTab={selectedTab}>
          <PayoutIcon/>
        </TabButton>
      </Grid>
    </Grid>
  );
}

AffiliateSubMenu.propTypes = {
  onChangeTab: PropTypes.func.isRequired,
  tab: PropTypes.string,
}
