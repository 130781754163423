import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';

import { getOrders } from '../actions/orders';
import { paperStyle } from '../styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';

import Title from "../components/Title";
import PeriodPicker from "../components/PeriodPicker";
import Chart from '../components/Dashboard/Chart';
import Revenue from '../components/Dashboard/Revenue';
import OrderTable from '../components/Orders/OrderTable';

function Dashboard (props) {
  const { getOrders, site } = props;

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState(null);
  const [periodDate, setPeriodDate] = useState(moment().format('YYYY-MM'));

  useEffect(() => {
    setLoading(true);
    let startDate = null;
    let endDate = null;

    // console.log("PeriodDate:", periodDate)

    if (periodDate === moment().format('YYYY-MM-DD')){
      startDate = periodDate;
      endDate = periodDate;
    } else {
      startDate = moment(periodDate, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
      endDate = moment(periodDate, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
    }

    getOrders({from: startDate, to: endDate})
      .then(resp => {
        setOrders(resp.orders);
        setLoading(false);
      })
      .catch(error => {
        // TODO show error
        console.log({error});
      })
      .finally(() => setLoading(false));
  }, [site, getOrders, periodDate])

  if (loading || !orders)
    return <LinearProgress />;

  return (
    <>
      <div className={classes.paperTitle}>
        <Grid item xs>
          <Title>Performance for Period {periodDate === moment().format('YYYY-MM-DD') &&
              ' - Today' }</Title>
        </Grid>
        <PeriodPicker
          onDateChange={setPeriodDate}
          type='monthly'
          typeDisabled
          date={periodDate}
          disableToday={true}
          disabled={loading} />
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper className={classes.fixedHeight}>
            <Chart orders={orders} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper className={classes.fixedHeight}>
            <Revenue orders={orders} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <OrderTable rows={orders} title='Orders'/>
        </Grid>
      </Grid>
    </>
  );
}

Dashboard.propTypes = {
  site: PropTypes.object.isRequired,
  // actions
  getOrders: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    site: state.sites.get('site'),
  };
}

export default connect(mapStateToProps, { getOrders })(Dashboard)

const useStyles = makeStyles(theme => ({
  fixedHeight: {
    ...paperStyle,
    height: 260
  },
  paperTitle: {
    marginBottom: theme.spacing(2),
    ...paperStyle,
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  }
}));