import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CompleteForm } from '../Form';
import { hasUser } from "../../lib";
import * as _ from 'lodash';

const OrderForm = (props) => {
    const { order, role } = props;

    const validateEdits = (newOrder) => {
        if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newOrder.customerEmail))
            return "Invalid customer email!"
        if(newOrder.volumeTotal < 0)
            return "Volume can't be a negative value!";
        return null;
    }

    return <CompleteForm
        title={order ? 'Details' : 'New Order'}
        object={order}
        newObject={!order.id}
        validateFunc={validateEdits}
        noEdit={!hasUser(role)}
        noDelete
        onSave={props.onSave}
        onDelete={props.onDelete}
        message='Order'
        onCancel={props.onCancel}
        fields={{
            General: [
                {
                    label: 'Order Number',
                    key: 'orderNumber'
                },
                {
                    label: 'Affiliate Id',
                    key: 'affiliateId'
                },
                {
                    label: 'Customer Email',
                    key: 'customerEmail'
                },
                {
                    label: 'Date',
                    key: 'orderDate',
                    type: 'date'
                },
            ],
            Volumes: [
                {
                    label: 'Personal Volume',
                    key: 'personalVolume',
                    type: 'numeric'
                },
                {
                    label: 'Customer Volume',
                    key: 'customerVolume',
                    type: 'numeric'
                },
                {
                    label: 'New Volume',
                    key: 'newVolume',
                    type: 'numeric'
                },
            ]
        }}
        fieldsWhenEditing={{
            General: [
                {
                    label: 'Order Number',
                    key: 'orderNumber'
                },
                {
                    label: 'Affiliate Id',
                    key: 'affiliateId'
                },
                {
                    label: 'Customer Email',
                    key: 'customerEmail'
                },
                {
                    label: 'Date',
                    key: 'orderDate',
                    type: 'date'
                },
            ],
            Volumes: [
                {
                    label: 'Volume Type',
                    key: 'volumeType',
                    type: 'select',
                    selectOptions: [
                        { value: 'PERSONAL', label: 'Personal' },
                        { value: 'CUSTOMER', label: 'Customer' },
                    ]
                },
                {
                    label: 'Volume Total',
                    key: 'volumeTotal',
                    type: 'numeric',
                    formatter: (v) => (_.isNil(v) ? v : _.round(v, 2)), // Adjusted to round to 2 decimal
                    extraProps: {
                        inputProps: {
                            onChange: (e) => {
                                let value = e.target.value;
                                // Check if value contains a decimal point and more than two digits after it
                                if (value.includes('.') && value.split('.')[1].length > 2) {
                                    // Limit to two digits after the decimal point
                                    e.target.value = parseFloat(value).toFixed(2);
                                }
                            },
                            onPaste: (e) => {
                                e.preventDefault(); // Prevents pasting into the field
                            }
                        },
                        numericOptions: { floatingPoint: true }
                    }
                },
            ]
        }}
    />;
};

const mapStateToProps = (state) => ({
    site: state.sites.get('site'),
    role: state.user.getIn(['user', 'role']),
});

export default connect(mapStateToProps, {

})(OrderForm);

OrderForm.propTypes = {
    order: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
};