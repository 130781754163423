import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Grid } from '@material-ui/core';

import { paperStyle } from '../styles';
import Title from '../components/Title';
import AffiliateForm from '../components/Affiliates/AffiliateForm';
import SubMenu from '../components/Affiliates/AffiliateSubMenu';
import Dashboard from '../components/Affiliates/AffiliateDashboard';
import Downline from '../components/Affiliates/AffiliateDownline';

import Orders from '../components/Orders';
import Earnings from '../components/Payouts';

import {
  getAffiliate,
  affiliateAPIPut,
  affiliateAPIDelete,
  affiliateAPIPost,
  affiliateAPIDisable,
} from '../actions/affiliates';
import { setGlobalAlert } from '../actions';
import Routes from '../routes';
import { camelToSnake } from '../lib/api';

const disallowedKeys = new Set(['createdAt', 'updatedAt', 'id']);

const defaultAffiliate = {
  name: '',
  address: {
    phoneNumbers: [],
  },
};

function Affiliate(props) {
  const {
    getAffiliate,
    affiliateAPIPost,
    affiliateAPIPut,
    affiliateAPIDisable,
    site,
    role,
  } = props;
  const hist = useHistory();
  const match = useRouteMatch();
  const classes = useStyles();

  const [affiliate, setAffiliate] = useState(null);
  const [subMenu, setSubMenu] = useState('information');

  useEffect(() => {
    if (match.params.id) {
      getAffiliate(match.params.id).then((resp) =>
        setAffiliate(resp.affiliate)
      );
    } else {
      setAffiliate(defaultAffiliate);
    }
  }, [getAffiliate, site, match.params.id]);

  async function onSave(newAffiliate) {
    newAffiliate = _.pickBy(newAffiliate, (v, k) => !disallowedKeys.has(k));

    //set some properties to null if empty
    newAffiliate.referrerId = newAffiliate.referrerId === '' ? null : newAffiliate.referrerId;
    newAffiliate.groupIds = newAffiliate.groupIds === -1 ? null : newAffiliate.groupIds;

    // ignore the line keys in transform
    newAffiliate = camelToSnake(newAffiliate, new Set(['line1', 'line2']));

    try {
      if (affiliate.id) {
        let newAff = await affiliateAPIPut(affiliate.id, newAffiliate, false);
        setAffiliate(newAff.affiliate);
        return { success: true, message: 'Affiliate Updated Successfully!' };
      } else {
        let newAff = await affiliateAPIPost(newAffiliate, false);
        setAffiliate(newAff.affiliate);
        return { success: true, message: 'Affiliate Saved Successfully!' };
      }
    } catch (error) {
      console.error({ error });
      return {
        success: false,
        message: affiliate.id
          ? 'Affiliate Update Failed!'
          : 'Affiliate Save Failed!',
      };
    }
  }

  async function onDelete() {
    const deleteAffiliate = [match.params.id];

    try {
      await affiliateAPIDisable(deleteAffiliate);
      hist.push(Routes.AFFILIATES);
    } catch (error) {
      return {
        success: false,
        message: 'Something went wrong when deleting the Affiliate!',
      };
    }
    return { success: true, message: 'Successfully deleted Affiliate!' };

    // try {
    //   //await props.affiliateAPIDelete(affiliateID);
    //   await props.affiliateAPIDisable(deleteAffiliate);
    //   props.setGlobalAlert('Successfully deleted Affiliate!');
    //   hist.push(Routes.AFFILIATES);
    // } catch (error) {
    //   console.error({ error });
    //   props.setGlobalAlert(
    //     'Something went wrong when deleting the Affiliate!',
    //     'error'
    //   );
    // }
  }

  if (!affiliate) return <LinearProgress className={classes.progress} />;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={classes.paper}>
          <Grid container justifyContent='space-between'>
            <Grid item xs>
              <Title>
                {affiliate.id
                  ? 'Affiliate - ' +
                  affiliate.name +
                  ' ' +
                  affiliate.familyName +
                  ' - ' +
                  affiliate.id +
                  ' '
                  : 'New Affiliate'}
              </Title>
            </Grid>
            <Grid item>
              <SubMenu tab={subMenu} onChangeTab={setSubMenu} />
            </Grid>
          </Grid>
        </div>
      </Grid>

      <Grid item xs={12}>
        {subMenu === 'information' && (
          <AffiliateForm
            affiliate={affiliate}
            onSave={onSave}
            onDelete={onDelete}
            onCancel={() => hist.push(Routes.AFFILIATES)}
            role={role}
          />
        )}
        {subMenu === 'dashboard' && (
          <Dashboard affiliateId={affiliate.id} shareIds={affiliate.groupIds} />
        )}
        {subMenu === 'downline' && <Downline affiliateId={affiliate.id} />}
        {subMenu === 'orders' && <Orders affiliateId={affiliate.id} />}
        {subMenu === 'payouts' && <Earnings affiliateId={affiliate.id} />}
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state) {
  return {
    site: state.sites.get('site'),
    role: state.user.getIn(['user', 'role']),
  };
}

export default connect(mapStateToProps, {
  getAffiliate,
  affiliateAPIPut,
  affiliateAPIPost,
  affiliateAPIDelete,
  affiliateAPIDisable,
  setGlobalAlert,
})(Affiliate);

const useStyles = makeStyles({
  paper: paperStyle,
  progress: {
    marginTop: 20,
    marginBottom: 20,
  },
});
