import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import * as _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Typography, Divider } from '@material-ui/core';

import SelectInput from '../SelectInput';
import NumericInput from '../NumericInput';
import {
  CountryPicker,
  RegionPicker,
  TZPicker,
} from '../CountryRegionTZPicker';
import ToggleSwitch from '../ToggleSwitch';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';

class GeneralForm extends Component {
  
  onChangeFormField(key) {
    const { object } = this.props;
    return (value) => {
      this.props.onUpdate(object.setIn(key, value));
      if (key[0] === 'payoutType') {
        this.props.handlePayoutTypeSelect(value);
      }
    };
  }

  onChangeCountry(country) {
    const { object } = this.props;
    const depFields = this.findDependentLocaleFields();

    const countryField = _.find(depFields, (f) => f.type === 'country');
    const nonCountryKeys = _.map(
      _.filter(depFields, (f) => f.type !== 'country'),
      this.parseFieldKey
    );

    this.props.onUpdate(
      object.withMutations((o) => {
        o.setIn(this.parseFieldKey(countryField), country);
        _.forEach(nonCountryKeys, (k) => o.setIn(k, ''));
      })
    );
  }

  onChangeAutocomplete(key, getOptionValue) {
    const { object } = this.props;
    return (e, value, reason) => {
      this.props.onUpdate(
        object.setIn(key, value.map(option => getOptionValue(option)))
      );
    }
  }

  onChangeToggle(key, options) {
    const { object } = this.props;
    return (value) => {
      this.props.onUpdate(
        object.setIn(key, value === true ? options.value2 : options.value1)
      );
    };
  }

  onChangeDate(key) {
    const { object } = this.props;
    return (value) => {
      this.props.onUpdate(
        object.setIn(key, moment(value).toISOString(true))
      );
    };
  }

  findDependentLocaleFields() {
    const { fields } = this.props;

    let allFields = fields;
    if (typeof fields === 'object') allFields = [].concat(..._.values(fields));

    const depTypes = new Set(['country', 'region', 'timezone']);

    return _.fromPairs(
      _.filter(allFields, (f) => depTypes.has(f.type)).map((f) => [f.type, f])
    );
  }

  parseFieldKey(field) {
    const { key } = field;
    return typeof key === 'string' ? [key] : key;
  }

  getFieldValue(field) {
    const { object } = this.props;
    let keysList = this.parseFieldKey(field);
    let result = object.getIn(keysList, undefined);
    return result == null ? '' : result;
  }

  // Render

  renderFormField(field) {
    const { editing } = this.props;
    const {
      label,
      type,
      disabled,
      required,
      selectOptions,
      autocompleteProps,
      toggleOptions,
      formatter = (x) => x,
      parser = (x) => x,
      extraProps,
    } = field;
    const key = this.parseFieldKey(field);
    const inputProps = extraProps ? extraProps.inputProps : {};

    const sharedProps = {
      key: `ff-field-${key}`, // react "key" for rendering arrays
      label,
      margin: 'dense',
      value: formatter(this.getFieldValue(field)),
      placeholder: label,
      onChange: (eventOrValue) => {
        const v = eventOrValue.target
          ? eventOrValue.target.value
          : eventOrValue;
        return this.onChangeFormField(key)(parser(v));
      },

      fullWidth: true,
      required: _.isNil(required) ? true : required,
      disabled,
      ...extraProps,
      // we've already included the input props from extraProps, so don't want to blow away
      inputProps: {
        ...inputProps,
        readOnly: Boolean(!editing),
      },
    };

    if (!type || type === 'text') {
      return <TextField fullWidth type='text' {...sharedProps} />;
    } else if (type === 'select') {
      return (
        <SelectInput
          {...sharedProps}
          options={selectOptions}
          onChange={this.onChangeFormField(key)}
        />
      );
    } else if (type === 'autocomplete') {

      const clearedAutocompleteProps = { ...autocompleteProps };
      clearedAutocompleteProps.value = this.getFieldValue(field) ? autocompleteProps.options.filter(o => this.getFieldValue(field).includes(autocompleteProps.getOptionValue(o))) : [];

      delete clearedAutocompleteProps.getOptionValue;
      delete sharedProps.inputProps;

      return (
        <StyledAutocomplete
          {...sharedProps}
          {...clearedAutocompleteProps}
          onChange={this.onChangeAutocomplete(key, autocompleteProps.getOptionValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={sharedProps.label}
            />
          )}
          disabled={!editing}
        />
      );
    } else if (type === 'toggle') {
      return (
        <ToggleSwitch
          {...sharedProps}
          toggleOptions={toggleOptions}
          onChange={this.onChangeToggle(key, toggleOptions)}
        />
      );
    } else if (type === 'numeric') {
      return <NumericInput {...sharedProps} />;
    } else if (type === 'date') {
      let dateProps = {
        ...sharedProps,
        value: new Date(sharedProps.value),
        variant: 'inline',
        format: 'MM/dd/yyyy',
        disabled: sharedProps.inputProps.readOnly,
        onChange: this.onChangeDate(key),
        KeyboardButtonProps: {
          'aria-label': 'change date',
        }
      };

      return <MuiPickersUtilsProvider utils={DateFnsUtils} key={sharedProps.key}>
        <KeyboardDatePicker {...dateProps} />
      </MuiPickersUtilsProvider>;
    } else {
      // rendering fields that depend on others
      let { country } = this.findDependentLocaleFields();
      country = this.getFieldValue(country);

      delete sharedProps.label;

      if (type === 'country')
        return (
          <CountryPicker
            {...sharedProps}
            country={sharedProps.value}
            onChange={this.onChangeCountry.bind(this)}
          />
        );
      else if (type === 'region')
        return (
          <RegionPicker
            {...sharedProps}
            country={country}
            region={sharedProps.value}
            onChange={this.onChangeFormField(key)}
          />
        );
      else if (type === 'timezone')
        return (
          <TZPicker
            {...sharedProps}
            country={country}
            timezone={sharedProps.value}
            onChange={this.onChangeFormField(key)}
          />
        );
    }
  }

  renderSectionHeader(name) {
    const { classes } = this.props;
    return (
      <>
        <Typography
          className={classes.dividerFullWidth}
          color='textSecondary'
          display='block'
        >
          {name}
        </Typography>
        <Divider className={classes.divider} />
      </>
    );
  }

  render() {
    const { fields, classes } = this.props;
    if (_.isArray(fields)) {
      return _.map(fields, this.renderFormField.bind(this));
    } else {
      return _.map(fields, (fieldArr, sectionName) => (
        <div key={`ff-section-outer-${sectionName}`}>
          {this.renderSectionHeader(sectionName)}
          <div className={classes.formFieldsDiv}>
            {_.map(fieldArr, this.renderFormField.bind(this))}
          </div>
        </div>
      ));
    }
  }
}

const fieldArrayPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.any),
    ]).isRequired,
    type: PropTypes.oneOf([
      'text',
      'select',
      'autocomplete',
      'numeric',
      'country',
      'region',
      'timezone',
      'toggle',
      'date'
    ]),
    selectOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      })
    ),
    autocompleteProps: PropTypes.object,
    toggleOptions: PropTypes.shape({
      value1: PropTypes.any.isRequired,
      value2: PropTypes.any.isRequired,
    }),
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    formatter: PropTypes.func,
    parser: PropTypes.func,
    extraProps: PropTypes.object,
  })
);

GeneralForm.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  handlePayoutTypeSelect: PropTypes.func,

  object: ImmutablePropTypes.map.isRequired,
  editing: PropTypes.bool.isRequired,
  fields: PropTypes.oneOfType([
    fieldArrayPropType,
    PropTypes.objectOf(fieldArrayPropType),
  ]).isRequired,
};

const styles = {
  divider: {
    margin: '0 0 10px 0',
  },
  dividerFullWidth: {
    margin: `5px 0 0 0px`,
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  formFieldsDiv: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
};

const StyledAutocomplete = withStyles({
  tag: {
    opacity: '1 !important'
  }
})(Autocomplete);

export default withStyles(styles)(GeneralForm);
