import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { LinearProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { paperStyle } from '../styles';
import Title from '../components/Title';
import { getSiteCommissionsHistory } from '../actions/sites';

import CommissionsTable from '../components/Commissions/CommissionsTable';

function CommissionsHistory(props) {
    const { getSiteCommissionsHistory, site } = props;
    const classes = useStyles();

    const [plans, setPlans] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        getSiteCommissionsHistory()
            .then((res) => setPlans(res.compSnapshots))
            .finally(() => setLoading(false));
    }, [getSiteCommissionsHistory, site]);

    return (
        <>
            <div className={classes.paper}>
                <Grid container justifyContent='space-between'>
                    <Grid item xs>
                        <Title>Commission Plan History</Title>
                    </Grid>
                </Grid>
            </div>

            {loading ?
                <LinearProgress className={classes.progress} />
                :
                <CommissionsTable rows={plans || []} />
            }
        </>
    );
}

CommissionsHistory.propTypes = {
    rows: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        site: state.sites.get('site'),
        role: state.user.getIn(['user', 'role']),
    };
}

export default connect(mapStateToProps, { getSiteCommissionsHistory })(CommissionsHistory);

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(2),
        ...paperStyle,
    },
    progress: {
        marginTop: 20,
        marginBottom: 20,
    },
}));
