import {
  getMonthlyURL,
  getWeeklyURL,
  getMonthlySharesURL,
  apiPost as libAPIPost,
  getBonusesMonthlyURL,
} from '../lib/api';
import { siteAPIReq, siteAPIGet } from './sites';

export function getMonthly(params) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site)
      throw Error('Tried to call getMonthly without a site');

    return dispatch(siteAPIGet(getMonthlyURL(site.id), params));
  }
}

export function getWeekly(params) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site)
      throw Error('Tried to call getWeekly without a site');

    return dispatch(siteAPIGet(getWeeklyURL(site.id), params));
  }
}

export function getMonthlyShares(shareId, params) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site)
      throw Error('Tried to call getShare without a site');

    return dispatch(siteAPIGet(getMonthlySharesURL(site.id)+ `/${shareId}`, params));

    // let levels = null;
    // try {
    //   const resp = await dispatch(siteAPIGet(getSharesURL(site.id)+ `/${shareId}`, params));
    //   levels = resp.share.affiliates;
    // }
    // catch (error) {
    //   console.log(error.response)
    //   console.error(error)
    // }
  }
}

export function getMonthlyBonuses(params) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if(!site)
    throw Error('Tried to call getMonthlyBonuses without a site');

    return dispatch(siteAPIGet(getBonusesMonthlyURL(site.id), params));
  }
}

export function processPayouts(payouts, type='monthly') {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    const endpoint = type === 'monthly' ? getMonthlyURL(site.id) : getWeeklyURL(site.id);

    return dispatch(siteAPIReq(libAPIPost, endpoint, payouts));
  };
}
