import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Grid, Typography, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import VolumeTypeInput from './VolumeTypeInput';
import DeleteButton from '../DeleteButton';
import NumericInput from '../NumericInput';

export default function Tier(props) {
  const {
    tier,
    minFromValue,
    minRate,
    minShares,
    minAmount,
    onChangeTier,
    onAddTier,
    onRemoveTier,
    editable,
    payoutType,
    isBonuses,
    hasTierTrigger,
    ...other
  } = props;
  const classes = useStyles();
  const inpProps = { InputProps: { readOnly: !editable } };

  const renderPayout = () => {
    return payoutType === 'PERCENTAGE' ? (
      <NumericInput
        label='Rate'
        value={!_.isNil(tier.rate) ? _.round(tier.rate * 100, 3) : tier.rate}
        minValue={minRate ? _.round(minRate * 100, 3) : 0}
        maxValue={100}
        onChange={(v) => onChangeTier({ rate: v / 100 })}
        unitText='%'
        numericOptions={{ floatingPoint: true }}
        inputProps={{
          size: 100,
          onChange: (e) => {
            const value = e.target.value;
            if (value.includes('.') && value.split('.')[1].length > 3) {
              e.target.value = parseFloat(value).toFixed(3);
            }
          },
          onPaste: (e) => {
            e.preventDefault();
          },
        }}
        textFieldProps={{
          className: classes.rateInput,
          ...inpProps,
        }}
      />
    ) : (
      <NumericInput
        label='Amount'
        value={tier.amount}
        minValue={minAmount || 0}
        onChange={(v) => onChangeTier({ amount: v })}
        unitText='#'
        numericOptions={{ floatingPoint: true }}
        inputProps={{
          size: 100,
        }}
        textFieldProps={{
          className: classes.rateInput,
          ...inpProps,
        }}
      />
    );
  };

  return (
    <Grid container alignItems='flex-end' spacing={2} {...other}>
      <Grid item>
        <NumericInput
          label='From'
          unitText='$'
          unitTextPosition='start'
          value={tier.from}
          minValue={minFromValue}
          onChange={(v) => onChangeTier({ from: v })}
          numericOptions={{ floatingPoint: true }}
          textFieldProps={inpProps}
        />
      </Grid>
      <Grid item>
        {tier.rate !== undefined && !isBonuses && (
          <NumericInput
            label='Rate'
            value={
              !_.isNil(tier.rate) ? _.round(tier.rate * 100, 3) : tier.rate
            }
            minValue={minRate ? _.round(minRate * 100, 3) : 0}
            maxValue={100}
            onChange={(v) => onChangeTier({ rate: v / 100 })}
            unitText='%'
            numericOptions={{ floatingPoint: true }}
            inputProps={{
              size: 100,
              onChange: (e) => {
                const value = e.target.value;
                if (value.includes('.') && value.split('.')[1].length > 3) {
                  e.target.value = parseFloat(value).toFixed(3);
                }
              },
              onPaste: (e) => {
                e.preventDefault();
              },
            }}
            textFieldProps={{
              style: { width: '200px' },
              className: classes.rateInput,
              ...inpProps,
            }}
          />
        )}
        {(tier.rate || tier.amount) !== undefined &&
          isBonuses &&
          renderPayout()}
        {tier.shares !== undefined && (
          <NumericInput
            label='Shares'
            unitText='#'
            unitTextPosition='start'
            value={tier.shares}
            minValue={minShares || 0}
            onChange={(v) => onChangeTier({ shares: v })}
            textFieldProps={inpProps}
          />
        )}
      </Grid>
      {editable && onAddTier && (
        <Grid item>
          <IconButton
            onClick={onAddTier}
            edge='start'
            color='inherit'
            aria-label='Add'
          >
            <AddIcon />
          </IconButton>
        </Grid>
      )}
      {editable && onRemoveTier && (
        <Grid item>
          <DeleteButton onDelete={onRemoveTier} />
        </Grid>
      )}
      {hasTierTrigger &&
        <>
          {onAddTier && editable && !tier.recruits &&
            <Grid item xs container spacing={2} direction='column'>
              <Grid item style={{ marginLeft: '12px' }}>
                <Typography variant='h6'>Tier Trigger</Typography>
              </Grid>
              <Grid item container spacing={2} alignItems="center">
                <Divider orientation='vertical' flexItem style={{ margin: '0 6px 0 6px' }} />
                <Grid item>
                  <IconButton
                    onClick={() => onChangeTier({ recruits: 1, recruitThreshold: 0, recruitBucket: 'CV' })}
                    edge='end'
                    color='inherit'
                    aria-label='Add Tier Trigger'
                    title='Add Tier Trigger'
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          }
          {onAddTier && tier.recruits &&
            <Grid item xs container spacing={2} direction='column'>
              <Grid item style={{ marginLeft: '12px' }}>
                <Typography variant='h6'>Tier Trigger</Typography>
              </Grid>
              <Grid item container spacing={2} alignItems="center">
                <Divider orientation='vertical' flexItem style={{ margin: '0 6px 0 6px' }} />
                {editable &&
                  <Grid item style={{ marginLeft: '7px' }}>
                    <DeleteButton onDelete={() => onChangeTier({ recruits: null, recruitThreshold: null, recruitBucket: null })} />
                  </Grid>
                }
                <Grid item>
                  <NumericInput
                    label='Recruits'
                    value={tier.recruits}
                    minValue={1}
                    unitText='#'
                    unitTextPosition='start'
                    onChange={v => onChangeTier({ recruits: v })}
                    textFieldProps={inpProps}
                  />
                </Grid>
                <Grid item>
                  <NumericInput
                    label='Treshold'
                    value={tier.recruitThreshold}
                    unitText='$'
                    unitTextPosition='start'
                    minValue={0}
                    onChange={v => onChangeTier({ recruitThreshold: v })}
                    textFieldProps={inpProps}
                  />
                </Grid>
                <Grid item>
                  <VolumeTypeInput
                    value={tier.recruitBucket}
                    onChange={v => onChangeTier({ recruitBucket: v })}
                    readOnly={!editable}
                  />
                </Grid>
              </Grid>
            </Grid>
          }
        </>
      }
    </Grid>
  );
}

Tier.propTypes = {
  tier: PropTypes.shape({
    from: PropTypes.number,
    rate: PropTypes.number,
    shares: PropTypes.number,
    bonuses: PropTypes.number,
  }).isRequired,
  minFromValue: PropTypes.number,
  minRate: PropTypes.number,
  minShares: PropTypes.number,
  minAmount: PropTypes.number,
  editable: PropTypes.bool.isRequired,
  isBonuses: PropTypes.bool,
  hasTierTrigger: PropTypes.bool,

  onChangeTier: PropTypes.func.isRequired,
  onAddTier: PropTypes.func,
  onRemoveTier: PropTypes.func,
};

Tier.defaultProps = {
  editable: true,
  isBonuses: false,
};

const useStyles = makeStyles({
  rateInput: {
    minWidth: 80,
  },
});
