import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

export default function ActionDialog(props) {
  const {
    open,
    title,
    message,
    cancelLabel,
    submitLabel,
    onSubmit,
    onCancel,
    disableCancel,
    disableSubmit,
  } = props;
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {message && <DialogContentText>{message}</DialogContentText>}
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button disabled={disableCancel} onClick={onCancel} color='secondary'>
          {cancelLabel}
        </Button>
        <Button
          disabled={disableSubmit}
          onClick={onSubmit}
          color='primary'
          autoFocus
        >
          {submitLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ActionDialog.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  submitLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
};

ActionDialog.defaultProps = {
  cancelLabel: 'Cancel',
  submitLabel: 'Submit',
  open: true,
};
