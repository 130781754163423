import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import { TextField, MenuItem } from '@material-ui/core';

export default function SelectInput(props) {
  const { options, readOnly, inputProps, onChange, ...other } = props;
  return (
    <TextField
      select
      onChange={(e) => onChange(e.target.value)}
      inputProps={{
        readOnly: Boolean(readOnly),
        ...inputProps,
      }}
      {...other}
    >
      {_.map(options || [], (opt) => (
        <MenuItem
          value={opt.value || opt}
          key={`b2b-select-${opt.value || opt}`}
        >
          {opt.label || opt}
        </MenuItem>
      ))}
    </TextField>
  );
}

SelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
      }),
    ])
  ),
};
