import React from 'react';
import * as _ from 'lodash';

import Report from '.';
import { formatDateMY, formatDateMDY, parseCommission } from '../../lib/reports';
import { useMonthlyCommissionsData } from '../../lib/hooks';


function calculateRows({ affiliates, comms }) {
  // calculate stuff
  affiliates = _.keyBy(affiliates, 'id');
  return _.map(comms, comm => {
    const aff = affiliates[comm.affiliateId];
    return {
      ...comm,
      affFirstName: aff.name,
      affLastName: aff.familyName,
      periodEnd: formatDateMDY(comm.periodEnd),
      period: formatDateMY(comm.period),
      periodRate: _.round(100 * comm.periodRate, 2),
      commisionType: `Level${comm.level}`,
      sales: _.sum(_.values(parseCommission(comm))),
    };
  });
}

export default function KeyMetrics(props) {
  const report = useMonthlyCommissionsData({
    calculateRows,
    monthlyFilters: { processed: true }
  });

  const columns = [{
    title: 'Affiliate ID',
    field: 'affiliateId',
  }, {
    title: 'First Name',
    field: 'affFirstName',
  }, {
    title: 'Last Name',
    field: 'affLastName',
  }, {
    title: 'Last Name',
    field: 'periodEnd',
  }, {
    title: 'Commission Type',
    field: 'commisionType',
  }, {
    title: 'Commission Amount',
    field: 'periodEarnings',
    type: 'currency',
  }, {
    title: 'Reference Sales',
    field: 'sales',
    type: 'currency',
  }, {
    title: 'Reference Percent',
    field: 'periodRate',
    render: r => `${r.periodRate}%`,
  }, {
    title: 'Reference Info',
    field: 'period',
  }];


  return (
    <Report
      title="Commissions for Period"
      exportTitle="Commissions"
      columns={columns}
      {...report} />
  )
}
