import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router';
import * as _ from 'lodash';

import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { makeStyles } from '@material-ui/core/styles';

import { LinearProgress } from '@material-ui/core';

import { paperStyle } from '../styles';

import AdminForm from '../components/Admins/AdminForm';
import ActionDialog from '../components/ActionDialog';

import { getSiteAdmins, adminAPIPost, adminAPIDelete } from '../actions/sites';
import Routes from '../routes';
import { camelToSnake } from '../lib/api';

function ProcessDialog(props) {
  return (
    <ActionDialog
      title='Delete Administrator'
      message={`Are you sure you want to delete this Administrator?`}
      submitLabel='Delete'
      {...props}
    />
  );
}

function Admin(props) {
  const { getSiteAdmins, site } = props;
  const [admin, setSiteAdmin] = useState(null);
  const match = useRouteMatch();

  const [refresh, setRefresh] = useState(false);
  const loc = useLocation();
  const hist = useHistory();
  const [adminId, setAdminId] = useState(match.params.id);
  const [deleteAdmin, setDeleteAdmin] = useState(null);

  const [creatingNewAdmin, setNewAdmin] = useState(
    loc.pathname === Routes.NEW_ADMIN
  );

  const [addAlert, setAlert] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (!creatingNewAdmin) {
      getSiteAdmins().then((resp) => {
        setSiteAdmin(_.find(resp.admins, (s) => s.username === adminId));
      });
    }
  }, [adminId, creatingNewAdmin, getSiteAdmins, refresh]);

  async function onSave(newAdmin) {
    newAdmin = camelToSnake(newAdmin);

    try {
      if (!creatingNewAdmin) {
        //PUT
        // There is currently no Update/PUT option for Admins
        setRefresh(!refresh);
        return {
          success: true,
          message: 'Administrator information cannot be Updated!!!',
        };
      } else {
        const fuckedUpData = {
          //This is totally fucked API Structure for POST/GET are different
          name: newAdmin.attributes.name,
          family_name: newAdmin.attributes.family_name,
          email: newAdmin.attributes.email,
        };
        await props.adminAPIPost(fuckedUpData, false);
        setAdminId(newAdmin.attributes.email);
        setNewAdmin(false);
        setRefresh(!refresh);
        return { success: true, message: 'Administrator Added!!!' };
      }
    } catch (error) {
      console.error({ error });
      return { success: false, message: 'Administrator Update failed!!!' };
    }
  }

  async function onDelete() {
    // TODO only implement for exising, there is no delete action for new site
    console.log('TODO delete handler', adminId, deleteAdmin);

    const data = {
      email: adminId,
    };

    try {
      await props.adminAPIDelete(data, false);
      //TODO need to set site to null
      //TODO I would like the alert to pop???
      setAlert(true);
      hist.push(Routes.ADMINS);
      return { success: true, message: 'Administrator Deleted!!!' };
    } catch (error) {
      console.error({ error });
      return { success: false, message: 'Administrator Delete failed!!!' };
    }
    //setDeleteAdmin(null);
  }

  if (!admin && !creatingNewAdmin)
    return <LinearProgress className={classes.progress} />;

  return (
    <>
      <ProcessDialog
        open={Boolean(deleteAdmin)}
        onSubmit={onDelete}
        onCancel={() => setDeleteAdmin(null)}
      />
      {addAlert && site && (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          autoHideDuration={2000}
          onClose={() => setAlert(false)}
        >
          <Alert severity='success'>
            {admin.name + ' ' + admin.familyName} Created!!!
          </Alert>
        </Snackbar>
      )}
      <AdminForm
        admin={admin}
        onSave={onSave}
        onDelete={onDelete}
        message='Administrator'
        onCancel={() => hist.push(Routes.ADMINS)}
        noEdit
        newAdmin={creatingNewAdmin}
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    site: state.sites.get('site'),
    //creatingNewSite: state.sites.get('creatingNewSite'),
    role: state.user.getIn(['user', 'role']),
  };
}

export default connect(mapStateToProps, {
  getSiteAdmins,
  adminAPIPost,
  adminAPIDelete,
})(Admin);

const useStyles = makeStyles({
  paper: paperStyle,
  progress: {
    marginTop: 20,
    marginBottom: 20,
  },
});
