import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { useHistory } from 'react-router';

import { CompleteForm } from '../Form';
import { hasAdmin, hasSuper } from '../../lib';

const defaultSite = {
  id: '',
  apiToken: '',

  name: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    phoneNumbers: [''],
  },
  admin: {
    name: '',
    familyName: '',
    email: ''
  },
  timezone: '',
  payoutPeriod: '',
  disableType: '',
  noCompression: '',
  thresholdCheck: '',
};

export default function SiteForm(props) {
  const { site, role } = props;
  const hist = useHistory();
  const newSite = !site;

  /*
   * Return an error message if anything is bad, otherwise null.
   */
  function validateEdits(newSite) {

    // TODO other validation

    if (_.some([['address', 'country'], ['address', 'state'], ['timezone']], k => !_.get(newSite, k)))
      return "You haven't selected all the address fields!";

    return null;
  }

  return (
    <CompleteForm
      title={site ? `Site - ${site.name}` : 'New Site'}
      object={site || defaultSite}
      newObject={newSite}
      noEdit={!hasAdmin(role)}
      noDelete={!hasSuper(role)}
      message='Site'
      onSave={props.onSave}
      onCancel={newSite ? () => hist.goBack() : null}
      onDelete={!newSite ? props.onDelete : null}
      validateFunc={validateEdits}
      fields={{
        ...GENERAL_FIELDS,
        ...(newSite ? ADMIN_FIELDS : TECH_FIELDS),
      }} />
  );
}

SiteForm.propTypes = {
  site: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
}

const GENERAL_FIELDS = {
  General: [{
    label: 'Company Name',
    key: 'name'
  }, {
    label: 'Address Line 1',
    key: ['address', 'line1'],
  }, {
    label: 'Address Line 2',
    key: ['address', 'line2'],
    required: false,
  }, {
    label: 'City',
    key: ['address', 'city'],
  }, {
    // Country
    key: ['address', 'country'],
    type: 'country',
  }, {
    // Region
    key: ['address', 'state'],
    type: 'region',
  }, {
    // Timezone
    key: 'timezone',
    type: 'timezone',
  }, {
    label: 'Postal/Zip Code',
    key: ['address', 'zipCode'],
  }, {
    label: 'Phone Number',
    key: ['address', 'phoneNumbers', 0],
  }, {
    label: 'Payout Period',
    key: 'payoutPeriod',
    type: 'select',
    selectOptions: [{
      label: 'Monthly',
      value: 'monthly'
    }, {
      label: 'Weekly',
      value: 'weekly',
    }],
  }, {
    label: 'Re-enroll as New Affiliate',
    key: 'disableType',
    type: 'toggle',
    toggleOptions: {
      value1: false,
      value2: true
    }
  }, {
    label: 'No Compression',
    key: 'noCompression',
    type: 'toggle',
    toggleOptions: {
      value1: false,
      value2: true
    }
  }, {
    label: 'Enforce Level Triggers',
    key: 'thresholdCheck',
    type: 'toggle',
    toggleOptions: {
      value1: false,
      value2: true
    }
  }
  ],
};
//test
const ADMIN_FIELDS = {
  'Administrator': [{
    label: 'First Name',
    key: ['admin', 'name'],
  }, {
    label: 'Last Name',
    key: ['admin', 'familyName'],
  }, {
    label: 'Email',
    key: ['admin', 'email'],
  }],
};

const TECH_FIELDS = {
  'Technical Details': [{
    label: 'Site ID',
    key: 'id',
    disabled: true,
    required: false,
  }, {
    label: 'API Key',
    key: 'apiToken',
    disabled: true,
    required: false,
  }],
};
