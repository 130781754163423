import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from '@material-table/core';
import ExportPdf from '@material-table/exporters/pdf';
import ExportCsv from '@material-table/exporters/csv';

import Title from '../Title';


export function ReportTable(props) {
  const { options, ...other } = props;
  const myOptions = {
    showTitle: false,
    searchFieldAlignment: 'left',
    exportAllData: true,
    filtering: false,
    paging: true,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50, 100, 500],
    tableLayout: 'fixed',
    padding: 'dense',
    draggable: false,
    emptyRowsWhenPaging: false,
    exportMenu: [{
      label: 'Export PDF',
      exportFunc: (cols, datas) => ExportPdf(cols, datas, "Reports")
    },
    {
      label: 'Export CSV',
      exportFunc: (cols, datas) => ExportCsv(cols, datas, "Reports")
    }],
    ...options,
  };

  return (
    <MaterialTable options={myOptions} {...other} />
  );
}


export default function Report(props) {
  const classes = useStyles();
  const { title, exportTitle, periodDate, PeriodDate, loading, columns, data } = props;

  return (
    <>
      <div className={classes.top}>
        <Title>{title}</Title>
        { PeriodDate }
      </div>

      {loading && <LinearProgress className={classes.progress} />}

      <ReportTable columns={columns} data={data} title={`${exportTitle} - ${periodDate}`} />
    </>
  )
}

Report.propTypes = {
  title: PropTypes.string.isRequired,
  exportTitle: PropTypes.string.isRequired,
  PeriodDate: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

const useStyles = makeStyles(theme => ({
  top: {
    marginBottom: theme.spacing(2),
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  }
}));

export { default as Commissions } from './Commissions';
export { default as KeyMetrics } from './KeyMetrics';
export { default as Recruits } from './Recruits';
