import React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';



export function formatDateMDY(date) {
  return moment.parseZone(date).format('MM/DD/YYYY');
}
export function formatDateMY(date) {
  return moment(date).format('MM/YYYY');
}

/*
 * Get referred affiliate ids for a certain depth
 */
export function getChildrenToDepth(affiliates, affiliateId, depth=2) {
  let children = affiliates[affiliateId].children;
  if (!children)
    return [];

  const levelChildren = [children];

  while (--depth > 0) {
    let nextLevel = [];
    children.forEach(childId => {
      if (affiliates[childId].children)
         nextLevel = nextLevel.concat(affiliates[childId].children);
    });

    levelChildren.push(nextLevel);
    children = nextLevel;
  }
  return levelChildren;
}

/*
 * Do a bunch of common formatting on affiliates returned from the API
 * to make reports easier
 */
export function formatAffiliates({affiliates, periodDate}) {
  affiliates = _.keyBy(_.map(affiliates, aff => ({
        ..._.pick(aff, ['id','name','familyName', 'createdAt', 'referrerId']),
        periodDate: formatDateMY(periodDate),
        PV: 0,
        CV: 0,
        NV: 0,
        AV: 0,
        TV: 0,
        createdAt: formatDateMDY(aff.createdAt),
      })
    ), 'id');

  _.forEach(affiliates, (aff, id) => {
      aff.createdAt = formatDateMDY(affiliates[id].createdAt);
    if (!_.isNil(aff.referrerId) && affiliates[aff.referrerId]) {
      affiliates[aff.referrerId].children = affiliates[aff.referrerId].children || [];
      affiliates[aff.referrerId].children.push(id);

      aff.referrerFName = affiliates[aff.referrerId].name;
      aff.referrerLName = affiliates[aff.referrerId].familyName;
    }
  });
  //console.log(moment.parseZone('2023-04-30T22:07:05-07:00').format('MM-DD-YYYY'))
  return affiliates;
}


/*
 * Extract the relevant monetary values from a commission
 */
export function parseCommission(comm) {
  const pairs = _.map(_.split(comm.type, '_'), a => {
    switch (a) {
      case 'CV':
        return [a, 'customerVolume'];
      case 'PV':
        return [a, 'personalVolume'];
      case 'NV':
        return [a, 'newVolume'];
      default:
        throw Error('wut');
    }
  });

  return _.fromPairs(_.map(pairs, ([dest, attr]) => [dest, comm[attr]]));
}

export const COLUMNS = {
  BASE_AFFILIATE_COLS: [
    {
      title: 'Affiliate ID',
      field: 'id',
      type: 'numeric',
      align: 'left',
      render: rowData => <Link to={{ pathname: `/affiliate/` + rowData.id }}>{rowData.id}</Link>,
    }, {
      title: 'Name',
      field: 'name',
    }, {
      title: 'Last Name',
      field: 'familyName',
    }, {
      title: 'Enrollment Date',
      field: 'createdAt',
      align: 'center',
    }, {
      title: 'Referrer ID',
      field: 'referrerId',
      render: rowData => <Link to={{ pathname: `/affiliate/` + rowData.referrerId }}>{rowData.referrerId}</Link>,
    }, {
      title: 'Ref. First Name',
      field: 'referrerFName',
    }, {
      title: 'Ref. Last Name',
      field: 'referrerLName',
    }, {
      title: 'Period',
      field: 'periodDate',
    },
  ],
  PV: {
    title: 'PV',
    field: 'PV',
    type: 'currency',
  },
  CV: {
    title: 'CV',
    field: 'CV',
    type: 'currency',
  },
  NV: {
    title: 'NV',
    field: 'NV',
    type: 'currency',
  },
  AV: {
    title: 'AV',
    field: 'AV',
    type: 'currency',
  },
  TV: {
    title: 'TV',
    field: 'TV',
    type: 'currency',
  },
};
