import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import * as _ from 'lodash';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import { LinearProgress } from '@material-ui/core';

import { CompleteForm } from '../components/Form';
import PlanStructure from '../components/MLM/PlanStructure';
import {
  getSiteShare,
  postSiteShares,
  deleteSiteShare,
  getSiteShareSnapshot,
  postSiteShareSnapshot
} from '../actions/sites';
import { hasAdmin, hasSuper } from '../lib';
import Routes from '../routes';

const defaultShare = {
  name: '',
  rate: 0,
  levels: [],
};
function Share(props) {
  const { getSiteShare, deleteSiteShare, postSiteShares, getSiteShareSnapshot, postSiteShareSnapshot, commissions, role } =
    props;
  const [share, setShare] = useState(null);
  const [share_fields, setShareFields] = useState(SHARE_FIELDS);
  const [reloadShare, setReload] = useState(false);
  const [isSnapshot, setIsSnapshot] = useState(false);
  const match = useRouteMatch();
  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    if (match.params.id) {
      match.params.period ?
        getSiteShareSnapshot(match.params.id, match.params.period)
          .then(res => { setShare(res.sharesSnapshot); setIsSnapshot(true) })
        :
        getSiteShare(match.params.id)
          .then((res) => { setShare(res.shares); setIsSnapshot(false) });
      setShareFields(SHARE_FIELDS);
    } else {
      setShare(defaultShare);
      SHARE_FIELDS[0].disabled = false;
      setShareFields(SHARE_FIELDS);
      setIsSnapshot(false);
    }
  }, [match.params.id, match.params.period, reloadShare]); // eslint-disable-line react-hooks/exhaustive-deps

  async function onSave(newShare) {

    if (isSnapshot) {
      try {
        await postSiteShareSnapshot(match.params.period, { shares_snapshot: newShare });
        return { success: true, message: 'Share snapshot saved successfully' };
      } catch (error) {
        return {
          success: false,
          message: 'Something went wrong while saving share snapshot!',
        };
      }
    }

    delete newShare.id;
    delete newShare.siteId;
    try {
      await postSiteShares({ shares: newShare });
      setReload(!reloadShare);
    } catch (error) {
      console.log('Failed to save shares', { error });
      return {
        success: false,
        message: 'Something went wrong while saving share.',
      };
    }

    return { success: true, message: 'Share saved successfully!' };
  }

  async function onDelete() {
    try {
      const shareId = share.id;
      setShare(null);
      await deleteSiteShare(shareId);
      history.push(Routes.SHARES);
    } catch (error) {
      return { success: false, message: 'Something broke deleting share.' };
    }
    return { success: true, message: 'Share deleted successfully.' };
  }

  function validateShare(newShare) {
    let error = null;

    _.forEach(newShare.levels, (lvl) => {
      if (error) return;

      if (lvl.sumLevels.length === 0)
        error = 'You must select level for you group';

      if (!lvl.type) error = 'You must set a type for your group';

      _.forEach(lvl.tiers, (tier, i) => {
        const prevTier = i > 0 ? lvl.tiers[i - 1] : null;

        if (
          !_.every([tier.from, tier.shares], _.isNumber) &&
          (!prevTier ||
            (prevTier.from < tier.from && prevTier.shares < tier.shares))
        )
          error = 'Invalid tier settings';
      });

      if (lvl.trigger) {
        if (!lvl.trigger.bucket) error = 'Trigger requires a bucket';
        else if (lvl.trigger.sumLevels.length === 0)
          error = 'Trigger requires selected levels';
      }
    });

    return error;
  }

  if (!share) return <LinearProgress className={classes.progress} />;

  return (
    <CompleteForm
      className={classes.header}
      title={share.name ? `Share Plan - ${share.name}${isSnapshot ? ` - Snapshot on ${match.params.period}` : ''}` : 'New share plan'}
      object={share}
      newObject={match.params.id ? false : true}
      fields={share_fields}
      noEdit={isSnapshot ? !hasSuper(role) : !hasAdmin(role)}
      noDelete={isSnapshot ? true : !hasAdmin(role)}
      onSave={onSave}
      onDelete={onDelete}
      onCancel={() => history.push(Routes.SHARES)}
      validateFunc={validateShare}
      message='Share'
      formRender={({ editing, object, onUpdate }) => (
        <PlanStructure
          isShares
          levels={object.get('levels')}
          compLevels={commissions}
          editing={editing}
          onUpdate={(levels) => onUpdate(object.set('levels', levels))}
        />
      )}
    />
  );
}

Share.propTypes = {
  share: PropTypes.shape({
    id: PropTypes.number.isRequired,
    siteId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    rate: PropTypes.number.isRequired,
    levels: PropTypes.array.isRequired,
  }),

  getSiteShare: PropTypes.func.isRequired,
  postSiteShares: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    commissions: state.sites.get('site').commissions,
    role: state.user.getIn(['user', 'role']),
  };
}

export default connect(mapStateToProps, {
  getSiteShare,
  postSiteShares,
  deleteSiteShare,
  getSiteShareSnapshot,
  postSiteShareSnapshot
})(Share);

const useStyles = makeStyles({
  progress: {
    marginTop: 20,
    marginBottom: 20,
  },
  header: {
    marginBottom: 25,
  },
});

const SHARE_FIELDS = [
  {
    key: 'name',
    label: 'Name',
    disabled: true,
  },
  {
    key: 'rate',
    label: 'Rate',
    type: 'numeric',
    formatter: (v) => (_.isNil(v) ? v : _.round(v * 100, 3)),
    parser: (v) => v / 100,
    extraProps: {
      minValue: 0,
      maxValue: 100,
      unitText: '%',
      inputProps: {
        size: 100,
        onChange: (e) => {
          const value = e.target.value;
          if (value.includes('.') && value.split('.')[1].length > 3) {
            e.target.value = parseFloat(value).toFixed(3);
          }
        },
        onPaste: (e) => {
          e.preventDefault();
        },
      },
      numericOptions: { floatingPoint: true },
      textFieldProps: {
        style: { width: '200px' }
      }
    },
  },
  {
    key: 'access',
    label: 'Public/Private',
    type: 'toggle',
    toggleOptions: {
      value1: 'PUBLIC',
      value2: 'PRIVATE',
    },
  }
];
