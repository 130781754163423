import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import * as _ from 'lodash';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

/*
 * Create helper functions for handling numeric textfields
 */
export function formatCurrency(v) {
  return numeral(v || 0).format("$0,000.00");
}

export function formatPercentage(v) {
  return numeral(v || 0).format("0.0%");
}

export function NumericTextFieldHelper({allowEmpty=false, emptyValue=null, floatingPoint=false}) {
  // if it's not a number, it has to be emptyValue
  this.validate = value => _.isFinite(value) || (allowEmpty && value === emptyValue);
  this.format = value => value === emptyValue ? '' : _.toString(value);
  this.parse = str => str === '' ? emptyValue : (floatingPoint ? parseFloat : parseInt)(str);
  this.validateString = value => this.validate(this.parse(value));
}

/*
 * Keep internal input state for text, update when valid
 */
export default function NumericInput(props) {
  const numericHelper = useMemo(() => new NumericTextFieldHelper(props.numericOptions || {}),
    [props.numericOptions]);

  const [strValue, updateStrValue] = useState(numericHelper.format(props.value));
  const [prevValue, updatePrevValue] = useState(null);
  const [prevEditable, updatePrevEditable] = useState(null);

  const {
    unitText,
    unitTextPosition,
    minValue,
    maxValue,
    step,
    textFieldProps,
    unitTextProps,
    inputProps,
  } = props;

  const finalTextFieldProps = {label: props.label, inputProps, ...textFieldProps};

  // begin djanky state change monitoring for when we need to reset the strValue

  const editable = !_.get(finalTextFieldProps, 'InputProps.readOnly', false) ||
    !_.get(finalTextFieldProps, 'inputProps.readOnly', false);
  const valueChange = prevValue !== props.value;
  const editableChange = prevEditable !== editable;

  if (valueChange || editableChange)
    updateStrValue(numericHelper.format(props.value));

  if (valueChange)
    updatePrevValue(props.value);

  if (editableChange)
    updatePrevEditable(editable);

  // end garbage

  const checkInput = val => {
    // val is a string
    // numericVal is unit aware! (hence parse)
    const numericVal = numericHelper.parse(val);

    // if valid and if min/max specified, enforce
    return {
      inputError: !numericHelper.validateString(val),
      minError: !_.isNil(minValue) && minValue > numericVal,
      maxError: !_.isNil(maxValue) && maxValue < numericVal,
    };
  }

  const validate = val => {
    return !_.some(_.values(checkInput(val)));
  }

  const onChange = val => {
    // save string internally
    updateStrValue(val);

    // if valid, report back to parent
    if (validate(val))
      props.onChange(numericHelper.parse(val));
  };

  if (_.isNil(finalTextFieldProps.inputProps))
    finalTextFieldProps.inputProps = {};

  // FUCKIN APPARENTLY THIS WILL MAKE THE FORM FIELD NOT FULL WIDTH, FUCKING LOVE MUI
  if (maxValue !== undefined)
    finalTextFieldProps.inputProps.max = maxValue;
  if (minValue !== undefined)
    finalTextFieldProps.inputProps.min = minValue;
  if (step !== undefined)
    finalTextFieldProps.inputProps.step = step;

  // capitalized InputProps are for the base MUI Input, lowercase inputProps are for the underlying
  // DOM input
  if (unitText) {
    finalTextFieldProps.InputProps = {
      ...finalTextFieldProps.InputProps,
    };

    const comp = (
      <InputAdornment position={unitTextPosition} {...unitTextProps}>
        {unitText}
      </InputAdornment>
    );

    if (unitTextPosition === 'start')
      finalTextFieldProps.InputProps.startAdornment = comp;
    else
      finalTextFieldProps.InputProps.endAdornment = comp;
  }

  const {inputError, minError, maxError} = checkInput(strValue);
  return (
    <TextField
      error={!validate(strValue)}
      value={strValue}
      type='number'
      onChange={e => onChange(e.target.value)}
      helperText={(minError || maxError) && !inputError && (minError ? 'Too low' : 'Too high')}
      {...finalTextFieldProps} />
  );
}

NumericInput.propTypes = {
  unitText: PropTypes.string,
  unitTextPosition: PropTypes.oneOf(['start', 'end']),
  onChange: PropTypes.func.isRequired,
  // all these values are assumed to be in the same unit
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  label: PropTypes.string,
  textFieldProps: PropTypes.object,
  inputProps: PropTypes.object,
  numericOptions: PropTypes.object,
};

NumericInput.defaultProps = {
  unitTextPosition: 'end'
}
