import * as ActionTypes from './types';
import {
  SITES_URL,
  apiGet as libAPIGet,
  getCommissionURL,
  getChangePasswordURL,
  getSharesURL,
  getShareURL,
  getBonusesURL,
  deleteBonusURL,
  getBonusURL,
  getAdminURL,
  getCommissionHistoryURL,
  commissionSnapshotURL,
  getBonusesSnapshotsURL,
  getBonusSnapshotURL,
  getSharesSnapshotsURL,
  getShareSnapshotURL,
  putBonusSnapshotURL,
  postShareSnapshotURL,
  createMonthlySnapshotURL
} from '../lib/api';
import { apiGet, apiPut, apiPost, apiDelete } from '.';

function getSiteAPIKey(state) {
  const site = state.sites.get('site');
  return site ? site.apiToken : null;
}

export function getSites() {
  return async (dispatch) => {
    let sites = null;
    try {
      const resp = await dispatch(apiGet(SITES_URL));
      sites = resp.sites;
    } catch (error) {
      console.log(error.response);
      console.error(error);
    }

    if (!sites) return;

    dispatch({
      type: ActionTypes.UPDATE_SITES,
      sites,
    });
  };
}

export function selectSite(siteId) {
  return async (dispatch) => {
    if (!siteId) {
      return dispatch({
        type: ActionTypes.SELECT_SITE,
        site: null,
      });
    }

    let site = null;
    try {
      const resp = await dispatch(apiGet(SITES_URL + `/${siteId}`));
      site = resp.site;
    } catch (error) {
      console.log(error.response);
      console.error(error);
    }

    if (!site) return;

    dispatch({
      type: ActionTypes.SELECT_SITE,
      site,
    });

    dispatch(getSiteCommissions());
  };
}

export function getSiteCommissions() {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getCommission without a site');

    let levels = null;
    try {
      const resp = await dispatch(siteAPIGet(getCommissionURL(site.id)));
      levels = resp.configuration.levels;
    } catch (error) {
      console.log(error.response);
      console.error(error);
    }

    if (!levels) return;

    dispatch({
      type: ActionTypes.UPDATE_SITE_COMMISSIONS,
      commissions: levels,
    });
  };
}

export function updateSiteCommisions(levels){
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call updateSiteCommisions without a site');

    if(levels == null) return;

    dispatch({
      type: ActionTypes.UPDATE_SITE_COMMISSIONS,
      commissions: levels,
    })
  }
}

export function getSiteCommissionsHistory() {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getSiteCommissionsHistory without a site');

    return dispatch(siteAPIGet(getCommissionHistoryURL(site.id)));
  };
}

export function getSiteCommissionsSnapshot(snapshotPeriod) {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getSiteCommissionsSnapshot without a site');

    return dispatch(siteAPIGet(commissionSnapshotURL(site.id, snapshotPeriod)));
  };
}

export function updateSiteCommisionsSnapshot(snapshotPeriod, levels){
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call updateSiteCommisionsSnapshot without a site');

    if(!levels) return;
    if(!snapshotPeriod) return;

    return dispatch(apiPost(commissionSnapshotURL(site.id, snapshotPeriod), levels))
  }
}

export function getSiteShares() {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getShares without a site');

    return dispatch(siteAPIGet(getSharesURL(site.id)));
  };
}

export function getSiteSharesSnapshots() {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getSiteSharesSnapshots without a site');

    return dispatch(siteAPIGet(getSharesSnapshotsURL(site.id)));
  };
}

export function getSiteShareSnapshot(id, period) {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getSiteShareSnapshot without a site');

    return dispatch(siteAPIGet(getShareSnapshotURL(site.id, id, period)));
  };
}

export function postSiteShareSnapshot(period, shares) {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site || !period) throw Error('Tried to call postSiteShareSnapshot without a site');

    return dispatch(apiPost(postShareSnapshotURL(site.id, period), shares));
  };
}

export function getSiteShare(id) {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getShare without a site');

    return dispatch(siteAPIGet(getShareURL(site.id, id)));
  };
}

export function getSiteAdmins(params) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getOrders without a site');

    return dispatch(siteAPIGet(getAdminURL(site.id), params));
  };
}

export function siteAPIReq(reqFunc, endpoint, params) {
  return (dispatch, getState) => {
    const token = getSiteAPIKey(getState());
    if (!token)
      throw Error(
        'Tried to call siteAPIReq with no site selected / site apiToken'
      );

    return reqFunc(token, endpoint, params);
  };
}

export function siteAPIGet(endpoint, params) {
  return (dispatch) => {
    return dispatch(siteAPIReq(libAPIGet, endpoint, params));
  };
}

export function siteAPIPut(id, siteData, camelTransform) {
  return async (dispatch) => {
    const endpoint = `${SITES_URL}/${id}`;
    await dispatch(apiPut(endpoint, siteData, camelTransform));

    await dispatch(getSites());
    await dispatch(selectSite(id));
  };
}

export function siteAPIDelete(id) {
  return async (dispatch) => {
    const endpoint = `${SITES_URL}/${id}`;
    await dispatch(apiDelete(endpoint));

    await dispatch(getSites());
    await dispatch(selectSite(''));
  };
}

export function siteAPIPost(siteData, camelTransform) {
  return async (dispatch) => {
    const resp = await dispatch(apiPost(SITES_URL, siteData, camelTransform));

    await dispatch(getSites());
    await dispatch(selectSite(resp.site.id));
  };
}

export function adminAPIPost(adminData, camelTransform) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call adminAPIPostt without a site');
    //TODO - Should return the response, it will have the id and other data for redirect and display.
    return dispatch(apiPost(getAdminURL(site.id), adminData, camelTransform));
  };
}

export function adminAPIDelete(adminData, camelTransform) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call adminAPIPostt without a site');
    //TODO - Should return the response, it will have the id and other data for redirect and display.
    console.log('Admin delete:', adminData);
    return dispatch(apiDelete(getAdminURL(site.id), adminData, camelTransform));
    //return null
  };
}

export function adminChangePasswordAPIPost(adminData, camelTransform) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call adminAPIPostt without a site');
    //TODO - Should return the response, it will have the id and other data for redirect and display.
    return dispatch(apiPost(getChangePasswordURL(), adminData, camelTransform));
  };
}

export function postSiteCommisions(commissions) {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    const endpoint = getCommissionURL(site.id);

    await dispatch(apiPost(endpoint, commissions));
    dispatch(getSiteCommissions());
  };
}

export function postSiteShares(shares) {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    const endpoint = getSharesURL(site.id);

    await dispatch(apiPost(endpoint, shares));
  };
}

export function deleteSiteShare(id) {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call deleteSiteShare without a site.');

    await dispatch(apiDelete(getShareURL(site.id, id)));
  };
}

export function getSiteBonuses() {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getBonuses without a site');

    return dispatch(siteAPIGet(getBonusesURL(site.id)));
  };
}

export function getSiteBonus(id) {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getBonus without a site');

    return dispatch(siteAPIGet(getBonusURL(site.id, id)));
  };
}

export function getSiteBonusSnapshot(id, period) {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getSiteBonusSnapshot without a site');

    return dispatch(siteAPIGet(getBonusSnapshotURL(site.id, id, period)));
  };
}

export function getSiteBonusesSnapshots() {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getSiteBonusesSnapshots without a site');

    return dispatch(siteAPIGet(getBonusesSnapshotsURL(site.id)));
  };
}

export function postSiteBonusSnapshot(period, bonuses) {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call putSiteBonusSnapshot without a site');

    await dispatch(apiPost(putBonusSnapshotURL(site.id, period), bonuses));
  };
}

export function postSiteBonuses(bonuses) {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call postSiteBonuses without a site');
    const endpoint = getBonusesURL(site.id);

    await dispatch(apiPost(endpoint, bonuses));
  };
}

export function deleteSiteBonus(id) {
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call deleteSiteBonus without a site');

    await dispatch(apiDelete(deleteBonusURL(site.id, id)));
  };
}

export function createMonthlySnapshot(snapshotMonth){
  return async (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call createSiteSnapshot without a site');
    
    const endpoint = createMonthlySnapshotURL(site.id, snapshotMonth);
    return dispatch(apiPost(endpoint), []);
  };
}