import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Commissions, KeyMetrics, Recruits } from '../components/Reports';


export const REPORT_LOOKUP = {
  'key-metrics': KeyMetrics,
  'period-recruits': props => <Recruits filterThisPeriod={true} {...props} />,
  'recruits': Recruits,
  'commissions': Commissions,
};

export default function Reports(props) {
  const match = useRouteMatch();

  const Report = REPORT_LOOKUP[match.params.id];

  return Report ? <Report /> : <h1>Big Sad: I don't know that report</h1>;
}
