import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import Immutable from 'immutable';

import { withStyles } from '@material-ui/core/styles';
import {
  Divider,
  Grid,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import DeleteButton from '../DeleteButton';
import { Tier, Trigger, VolumeTypeInput } from '.';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

class Level extends Component {
  onAddTier() {
    const { level, onChange, isShares, isBonuses } = this.props;
    onChange(
      Immutable.fromJS(level).update('tiers', (t) =>
        t.push(
          Immutable.Map({
            from: null,
            ...(isShares
              ? { shares: null }
              : isBonuses
                ? { rate: null, amount: null }
                : { rate: null }),
          }).toJS()
        )
      )
    );
  }

  onRemoveTier() {
    const { level, onChange } = this.props;
    onChange(
      Immutable.fromJS(level)
        .update('tiers', (t) => t.delete(t.count() - 1))
        .toJS()
    );
  }

  onChangeField(key) {
    const { level, onChange } = this.props;
    key = typeof key === 'string' ? [key] : key;
    return (value) => {
      if (value instanceof Date) value = moment(value).format('YYYY-MM-DD');
      onChange(Immutable.fromJS(level).setIn(key, value).toJS());
    }
  }

  shareOnLevelSelect(index) {
    // sadly duplicated from trigger, but whatever
    const { level } = this.props;

    const onChange = this.onChangeField('sumLevels');

    return () => {
      const newLevels = [...level.sumLevels];

      const currIndex = newLevels.indexOf(index);

      if (currIndex >= 0) newLevels.splice(currIndex, 1);
      else {
        newLevels.push(index);
        newLevels.sort();
      }

      onChange(newLevels);
    };
  }

  // render

  renderTier(tier, index, tierList) {
    const { editable, payoutType, isBonuses, isCompPlan } = this.props;
    const prevTier = index > 0 ? tierList[index - 1] : null;
    const onTierChange = (update) => {
      this.onChangeField(['tiers', index])(
        Immutable.fromJS(tier).merge(update)
      );
    };
    const extraProps = {
      minFromValue: prevTier ? prevTier.from + 1 : 0,
      // JS sucks, this + 0.01 can cause floating point weirdness so just round it to 2 decimals
      minRate: prevTier ? _.round(prevTier.rate + 0.01, 2) : 0,
      minAmount: prevTier ? prevTier.amount + 1 : 0,
      minShares: prevTier ? prevTier.shares + 1 : 0,
      editable,
      payoutType: payoutType,
    };

    if (index === tierList.length - 1) {
      extraProps.onAddTier = this.onAddTier.bind(this);

      if (tierList.length !== 1)
        extraProps.onRemoveTier = this.onRemoveTier.bind(this);
    }

    return (
      <Tier
        key={`tier-${index}`}
        tier={tier}
        payoutType={payoutType}
        isBonuses={isBonuses}
        hasTierTrigger={isCompPlan}
        onChangeTier={onTierChange}
        {...extraProps}
      />
    );
  }

  renderTrigger(trigger) {
    const { numLevels, editable, isBonuses } = this.props;
    const triggerOptions = _.range(numLevels);

    const onTriggerChange = (key) =>
      this.onChangeField(['trigger'].concat(key || []));

    return (
      <Trigger
        trigger={trigger}
        editable={editable}
        isBonuses={isBonuses}
        levelOptions={triggerOptions}
        onChangeThreshold={onTriggerChange('threshold')}
        onChangeBucket={onTriggerChange('bucket')}
        onChangeInclusionInput={onTriggerChange('logic')}
        onChangeRecruitThreshold={onTriggerChange('recruitThreshold')}
        onChangeRecruits={onTriggerChange('recruits')}
        onChangeRecruitBucket={onTriggerChange('recruitBucket')}
        onChangeLevels={(lvlsArr) =>
          onTriggerChange('sumLevels')(Immutable.List(lvlsArr))
        }
        onDeleteTrigger={() => this.onChangeField('trigger')(null)}
        onCreateTrigger={() =>
          onTriggerChange()(
            Immutable.fromJS(
              isBonuses
                ? {
                  threshold: 0,
                  bucket: '',
                  sumLevels: [],
                  logic: '',
                  recruits: 0,
                  recruitThreshold: 0,
                  recruitBucket: '',
                }
                : {
                  threshold: 0,
                  bucket: '',
                  sumLevels: [],
                }
            )
          )
        }
      />
    );
  }

  render() {
    const {
      classes,
      editable,
      level,
      className,
      onRemoveLevel,
      isShares,
      isBonuses,
      isCompPlan,
      numLevels,
    } = this.props;
    const sumLevelOptions = _.range(numLevels);

    return (
      <Paper className={classes.level + ' ' + className}>
        <div className={classes.levelSection}>
          <Grid
            container
            spacing={2}
            alignItems='center'
            className={classes.levelHeader}
          >
            <Grid item>
              <Typography display='inline' variant='h5'>
                {!isShares && !isBonuses ? 'Level' : 'Group'} {level.level}{' '}
                {(!isShares && !isBonuses) && level.level !== 0
                  ? 'Downline'
                  : !isShares && !isBonuses
                    ? 'Affiliate - Retail'
                    : ''}
              </Typography>
            </Grid>
            {editable && onRemoveLevel && (
              <Grid item>
                <DeleteButton onDelete={onRemoveLevel} />
              </Grid>
            )}
          </Grid>
          <Grid container alignItems='center' spacing={2}>
            {isCompPlan &&
              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    value={level.startDate ? moment(level.startDate).toDate() : null}
                    label='Start Date'
                    emptyLabel='None'
                    variant='dialog'
                    clearable
                    format='MM/dd/yyyy'
                    disabled={!editable}
                    onChange={this.onChangeField('startDate')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }} />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            <Grid item>
              <Typography variant='h6'>Pays out on:</Typography>
            </Grid>
          </Grid>
          <Grid container alignItems='center' spacing={1}>
            <Grid item>
              <VolumeTypeInput
                value={level.type}
                onChange={this.onChangeField('type')}
                readOnly={!editable}
              />
            </Grid>
          </Grid>
          {(isShares || isBonuses) && (
            <Grid container alignItems='center' spacing={1}>
              <Grid item key={`sum-level-label`}>
                <Typography display='inline' variant='subtitle2'>
                  Sum Levels:
                </Typography>
              </Grid>
              {sumLevelOptions.map((i) => (
                <Grid item key={`sum-level${i}`}>
                  <FormControlLabel
                    label={`Level ${i}`}
                    control={
                      <Checkbox
                        color='primary'
                        disabled={!editable}
                        checked={level.sumLevels.includes(i)}
                        onChange={this.shareOnLevelSelect(i)}
                        classes={{disabled: level.sumLevels.includes(i) ? classes.primaryColor : undefined}}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          )}
          <div className={classes.levelSection}>
            <Typography variant='h6' className={classes.levelSectionTitle}>
              Tiers:
            </Typography>
            {level.tiers.map(this.renderTier.bind(this))}
          </div>
        </div>

        {/* <div className={classes.levelSection}>
          <Typography variant='h6' className={classes.levelSectionTitle}>Tiers:</Typography>
          {level.tiers.map(this.renderTier.bind(this))}
        </div> */}

        <Divider className={classes.dividerFullWidth} />

        <div className={classes.levelSection}>
          {this.renderTrigger(level.trigger)}
        </div>
      </Paper>
    );
  }
}

Level.propTypes = {
  level: PropTypes.shape({
    level: PropTypes.number.isRequired,
    startDate: PropTypes.string,
    type: PropTypes.string.isRequired,
    tiers: PropTypes.array.isRequired,
    trigger: PropTypes.object,
  }),
  numLevels: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
  isShares: PropTypes.bool.isRequired,
  isBonuses: PropTypes.bool,
  isCompPlan: PropTypes.bool,

  onChange: PropTypes.func.isRequired,
  onRemoveLevel: PropTypes.func,
};

Level.defaultProps = {
  editable: true,
  isShares: false,
  isBonuses: false,
};

const styles = (theme) => ({
  levelHeader: {
    marginBottom: 20,
  },
  dividerFullWidth: {
    margin: `5px 0 0 0px`,
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  levelSection: {
    marginTop: 20,
    marginBottom: 20,
  },
  levelSectionTitle: {
    marginBottom: 20,
  },
  primaryColor: {
    color: `${theme.palette.primary.main} !important`
  }
});

export default withStyles(styles)(Level);
