import * as ActionTypes from './types';
import {
  getForgotPasswordURL,
  getForgotPasswordConfirmURL
} from '../lib/api';
import { getSites } from './sites';
import { apiPost } from '.';

export function logIn({user, accessToken, refreshToken}) {
  return dispatch => {
    dispatch({
      type: ActionTypes.LOG_IN,
      user,
      accessToken,
      refreshToken,
    });

    dispatch(refreshUserData());
  }
}

export function userGetResetCode(data, camelTransform) {
  return (dispatch, getState) => {
    return dispatch(apiPost(getForgotPasswordURL(), data, camelTransform));
  };
}

export function userSendResetCode(data, camelTransform) {
  return (dispatch, getState) => {
    return dispatch(apiPost(getForgotPasswordConfirmURL(), data, camelTransform));
  };
}

export function logOut() {
  localStorage.removeItem('appState', JSON.stringify('appState'));
  return {
    type: ActionTypes.LOG_OUT,
  }
}


export function refreshUserData() {
  return dispatch => {
    // TODO get the actual user info from the API
    dispatch(getSites());
  }
}
