import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import { Box, Container } from '@material-ui/core';

import { refreshUserData } from './actions/user';
import Routes from './routes';
import Nav from './components/Nav';
import Footer from './components/Footer';

import SignInSide from './pages/SignInSide';
import HomePage from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import Affiliates from './pages/Affiliates';
import Affiliate from './pages/Affiliate';
import Orders from './components/Orders';
import Payouts from './components/Payouts';
import Reports from './pages/Reports';
import Admins from './pages/Admins';
import Admin from './pages/Admin';
import AdminChangePassword from './components/Admins/AdminChangePassword';
import Commissions from './pages/Commissions';
import Shares from './pages/Shares';
import Share from './pages/Share';
import Site from './pages/Site';
import FormAlert from './components/Form/FormAlert';
import Bonuses from './pages/Bonuses';
import Bonus from './pages/Bonus';
import Order from './pages/Order';
import CommissionsHistory from './pages/CommissionsHistory';

function App(props) {
  const { user, alert, refreshUserData } = props;
  const classes = useStyles();
  const hist = useHistory();

  // This render happens after persist has rehydrated
  useEffect(() => {
    if (!user) {
      // Send the user back to the home screen if they're logged out
      hist.replace('/');
    } else {
      // Reload user data
      refreshUserData();
    }
  }, [user, hist, refreshUserData]);

  if (!user) return <SignInSide />;

  return (
    <div className={classes.root}>
      {alert && <FormAlert {...alert} />}
      <Nav />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='lg' className={classes.container}>
          <Switch>
            <Route exact path='/' component={HomePage} />
            {/* {!site?.commissions && loc.pathname !== Routes.NEW_SITE && (
              <Route>
                <Redirect to='/' />
              </Route>
            )} */}

            <Route path={Routes.DASHBOARD} component={Dashboard} />
            <Route path={Routes.SITE} component={Site} />
            <Route path={Routes.AFFILIATES} component={Affiliates} />
            <Route path={Routes.AFFILIATE} component={Affiliate} />
            <Route path={Routes.NEW_AFFILIATE} component={Affiliate} />
            <Route path={Routes.ORDERS} component={Orders} />
            <Route path={Routes.ORDER} component={Order} />
            <Route path={Routes.NEW_ORDER} component={Order} />
            <Route path={Routes.PAYOUTS}>
              <Payouts isProcess />
            </Route>
            <Route path={Routes.PAYOUTHISTORY} component={Payouts} />
            <Route path={Routes.REPORTS} component={Reports} />
            <Route path={Routes.ADMINS} component={Admins} />
            <Route
              exact
              path={Routes.ADMIN_CHANGE_PASSWORD}
              component={AdminChangePassword}
            />
            <Route path={Routes.ADMIN} component={Admin} />
            <Route path={Routes.COMMISSIONS_SNAPSHOT} component={Commissions} />
            <Route path={Routes.COMMISSIONS_HISTORY} component={CommissionsHistory} />
            <Route path={Routes.COMMISSIONS} component={Commissions} />
            <Route path={Routes.BONUSES} component={Bonuses} />
            <Route path={Routes.BONUS_SNAPSHOT} component={Bonus} />
            <Route path={Routes.BONUS} component={Bonus} />
            <Route path={Routes.NEW_BONUS} component={Bonus} />
            <Route path={Routes.SHARES} component={Shares} />
            <Route path={Routes.SHARE_SNAPSHOT} component={Share} />
            <Route path={Routes.SHARE} component={Share} />
            <Route path={Routes.NEW_SHARE} component={Share} />
            {/*<Route path={Routes.SITES} component={Sites}/>
            <Route path={Routes.ABOUT} component={About}/>
            <Route path={`${Routes.SEARCH}/:query`} component={Search}/>*/}
          </Switch>
          <Box alignItems='center' pt={10}>
            <Footer />
          </Box>
        </Container>
      </main>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.get('user'),
    site: state.sites.get('site'),
    alert: state.display.get('alert'),
  };
}

export default connect(mapStateToProps, { refreshUserData })(App);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  // menuButton: {
  //   marginRight: 36,
  // },
  // menuButtonHidden: {
  //   display: 'none',
  // },
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));
