import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SelectInput from '../SelectInput';

const LEVEL_TYPES = [{
  value: 'CV',
  label: 'CV Only',
}, {
  value: 'PV',
  label: 'PV Only',
}, {
  value: 'CV_PV',
  label: 'CV & PV',
}, {
  value: 'NV',
  label: 'NV',
}];

export default function VolumeTypeInput(props) {
  const classes = useStyles();

  return (
    <SelectInput
      className={classes.typeSelect}
      options={LEVEL_TYPES}
      label='Type'
      {...props} />
  );
}

const useStyles = makeStyles({
  typeSelect: {
    minWidth: 65,
  },
});
