import axios from 'axios';
import * as _ from 'lodash';

export const API_URL = process.env.REACT_APP_API_URL,
  LOG_IN_URL = `${API_URL}/login`,
  AUTH_REFRESH_ENDPOINT = `${API_URL}/login/refresh-token`,
  SITES_URL = `${API_URL}/sites`,
  AXIOS_POST_CONFIG = { 'Content-Type': 'application/json' };

function siteURLFormat(siteId, endpoint) {
  return [SITES_URL, siteId, endpoint].join('/');
}

function rootURLFormat(endpoint) {
  return [API_URL, endpoint].join('/');
}

export function getOrderURL(siteId) {
  return siteURLFormat(siteId, 'orders');
}

export function getAffiliateURL(siteId) {
  return siteURLFormat(siteId, 'affiliates');
}

export function getAffiliateDisableURL(siteId) {
  return siteURLFormat(siteId, 'affiliates/disable');
}

export function getMonthlyURL(siteId) {
  return siteURLFormat(siteId, 'affiliates/commission/monthly');
}

export function getWeeklyURL(siteId) {
  return siteURLFormat(siteId, 'affiliates/commission/weekly');
}

export function getMonthlySharesURL(siteId) {
  return siteURLFormat(siteId, 'shares');
}

export function getCommissionURL(siteId) {
  return siteURLFormat(siteId, 'configuration');
}

export function getCommissionHistoryURL(siteId) {
  return siteURLFormat(siteId, 'snapshots/comp/all');
}

export function createMonthlySnapshotURL(siteId, snapshotMonth) {
  return siteURLFormat(siteId, 'snapshots?snapshot_period=' + snapshotMonth);
}

export function commissionSnapshotURL(siteId, snapshotPeriod) {
  return siteURLFormat(siteId, 'snapshots/comp?snapshot_period=' + snapshotPeriod);
}

export function getSharesURL(siteId) {
  return siteURLFormat(siteId, 'configuration/shares');
}

export function getShareURL(siteId, id) {
  return siteURLFormat(siteId, 'configuration/shares/' + id);
}

export function getSharesSnapshotsURL(siteId) {
  return siteURLFormat(siteId, 'snapshots/shares/all');
}

export function getShareSnapshotURL(siteId, id, period) {
  return siteURLFormat(siteId, `snapshots/shares?snapshot_period=${period}&share=${id}`);
}

export function postShareSnapshotURL(siteId, period) {
  return siteURLFormat(siteId, `snapshots/shares?snapshot_period=${period}`);
}

export function getBonusURL(siteId, id) {
  return siteURLFormat(siteId, 'bonuses/' + id);
}

export function getBonusesURL(siteId) {
  return siteURLFormat(siteId, 'bonuses');
}

export function getBonusesMonthlyURL(siteId) {
  return siteURLFormat(siteId, 'bonuses/monthly');
}

export function getBonusesSnapshotsURL(siteId) {
  return siteURLFormat(siteId, 'snapshots/bonuses/all');
}

export function getBonusSnapshotURL(siteId, id, period) {
  return siteURLFormat(siteId, `snapshots/bonuses?snapshot_period=${period}&bonus=${id}`);
}

export function putBonusSnapshotURL(siteId, period) {
  return siteURLFormat(siteId, `snapshots/bonuses?snapshot_period=${period}`);
}

export function deleteBonusURL(siteId, id) {
  return siteURLFormat(siteId, 'bonuses/' + id);
}

export function getAdminURL(siteId) {
  return siteURLFormat(siteId, 'admin');
}

export function getLoginURL(siteId) {
  return siteURLFormat(siteId, 'login');
}

export function getChangePasswordURL() {
  return rootURLFormat('login/change-password');
}

export function getForgotPasswordURL() {
  return rootURLFormat('login/forgot-password');
}

export function getForgotPasswordConfirmURL() {
  return rootURLFormat('login/forgot-password/confirm');
}

// export function getSuperAdminsURL(siteId) {
//   return `${SITES_URL}/admin`;
// }

function getAuthHeaders(token) {
  return {
    Authorization: token,
  };
}

export async function refreshAccessToken(refreshToken) {
  try {
    const resp = await axios.post(AUTH_REFRESH_ENDPOINT, null, {
      ...AXIOS_POST_CONFIG,
      headers: getAuthHeaders(refreshToken),
    });
    return snakeToCamel(resp.data).accessToken;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export async function bodyRequest(
  token,
  endpoint,
  method,
  data,
  camelTransform = true
) {
  if (camelTransform) data = camelToSnake(data);

  let config = AXIOS_POST_CONFIG;
  if (token) {
    config = {
      ...config,
      headers: getAuthHeaders(token),
    };
  }

  let resp = null;

  if (_.isNil(data)) {
    if (method === 'delete') {
      resp = await axios.delete(endpoint, { ...config, data });
    } else {
      resp = await axios[method](endpoint, data, config);
    }
  } else {
    if (method === 'delete') {
      resp = await axios.delete(endpoint, { ...config, data });
    } else {
      resp = await axios[method](endpoint, data, config);
    }
  }
  return snakeToCamel(resp.data);
}

export async function apiGet(token, endpoint, params) {
  // console.log('In apiGET: ', endpoint, params);
  const resp = await axios.get(endpoint, {
    headers: token ? getAuthHeaders(token) : {},
    params,
  });
  return snakeToCamel(resp.data);
}

export function apiPost(token, endpoint, data, camelTransform = true) {
  return bodyRequest(token, endpoint, 'post', data, camelTransform);
}

export function apiPut(token, endpoint, data, camelTransform = true) {
  return bodyRequest(token, endpoint, 'put', data, camelTransform);
}

export function apiDelete(token, endpoint, data, camelTransform = true) {
  return bodyRequest(token, endpoint, 'delete', data, (camelTransform = true));
}

export async function* apiPager(initialBody, requestFunction) {
  let nextToken = null;
  let body = { ...initialBody };
  do {
    const resp = await requestFunction(body);
    if (!resp) return;

    // TODO idk if the API uses a next token
    nextToken = resp.nextToken;
    yield resp;

    if (nextToken) body.nextToken = nextToken;
  } while (nextToken);
}

export async function pageAll(pager) {
  const results = [];
  // eslint-disable-next-line
  for await (const resp of pager) {
    results.push(resp.data);
  }
  return results.flat();
}

function convertCasing(obj, conversion, ignoreKeys = null) {
  if (!_.isObject(obj)) return obj;

  if (_.isArray(obj))
    return obj.map((elem) => convertCasing(elem, conversion, ignoreKeys));
  let newObj = {};
  _.forOwn(obj, (val, key) => {
    key = !ignoreKeys || !ignoreKeys.has(key) ? conversion(key) : key;
    newObj[key] = convertCasing(val, conversion, ignoreKeys);
  });

  return newObj;
}

export function snakeToCamel(obj, ignoreKeys = null) {
  return convertCasing(obj, _.camelCase, ignoreKeys);
}

export function camelToSnake(obj, ignoreKeys = null) {
  return convertCasing(obj, _.snakeCase, ignoreKeys);
}
