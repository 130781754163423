import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import {
  Button,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  FormGroup,
} from '@material-ui/core'
import { SUPER, ADMIN, USER, READ_ONLY } from '../lib';
import { LOG_IN_URL, apiPost } from '../lib/api';
import { logIn, userGetResetCode, userSendResetCode } from '../actions/user';
import { setGlobalAlert } from '../actions'; 

import Copyright from '../components/Copyright';
import FormAlert from '../components/Form/FormAlert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { blue } from '@material-ui/core/colors';
//
const SUPER_USERS = new Set([
  'kevin@vitalibis.com', 
  'sraack@vitalibis.com', 
  'kevin@gigfiliate.com', 
  'sraack@gigfiliate.com', 
  'dev.lexelinfotech@gmail.com',
  '06alyraza@gmail.com'
]);
const ADMIN_USERS = new Set([
  'bill@lordeandbelle.com',
  "cpeterson@boombeauty.com",
  "swolf@boombeauty.com",
  'gina.hanzel@orabio.com',
  'abdulrehmanali82@gmail.com',
  'jebusiness723@gmail.com',
  'ian.magazine@orabio.com',
  'it@5hourintl.com',
  'dev.lexelinfotech@gmail.com',
  'jesi@alignyourskin.com',
  'payroll@traviswatkinstax.com', 
  'tthomas@traviswatkinstax.com', 
  'schord@beesknees-wellness.com', 
  'bob@bobhipple.com', 
  'bhipple@beesknees-wellness.com', 
  'sonal@beesknees-wellness.com', 
  'alignyourskin@gmail.com',
  'justin@justinestrada.com', 
  'rachel@radicalskincare.com', 
  'smishra@partylite.com', 
  'kbrand@partylite.com', 
  'kevin@corecode.com', 
  'justin@mydigitalsauce.com', 
  'scoskun@partylite.eu', 
  'gpereira@partylite.com', 
  'hvietzke@partylite.eu', 
  'echevaleyre@partylite.com'
]); //Full Access except for Sites
const REG_USERS = new Set([
  'user@corecode.com', 
  'jgrew@partylite.com',
  'jgoldstein@partylite.com', 
  'rkane@partylite.com', 
  'mburger@partylite.eu', 
  'mkripfgans@partylite.eu', 
  'sjohns@partylite.co.uk', 
  'MKripfgans@partylite.eu', 
  'dconway@partylite.co.uk'
]); //Limited Access TBD

function SignInSide (props) {
  const { alert } = props;
  const classes = useStyles()
  const [creds, setCreds] = useState({
    email: '',
    password: ''
  });

  const dispatch = useDispatch();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [step, setStep] = useState(0);

  const handleChange = (event) => {
    setCreds({ ...creds, [event.target.id]: event.target.value })
  }

  const [loading, setLoading] = useState(false);
  const logIn = async () => {
    if (!loading) {
      setLoading(true);
    }

    let user = {
      email: process.env.REACT_APP_API_USER_EMAIL,
      password: process.env.REACT_APP_API_USER_PASS,
    };

    if (creds.email !== '') {
      user.email = creds.email;
      user.password = creds.password;
    }

    try {
      const resp = await apiPost(null, LOG_IN_URL, user);

      let role = READ_ONLY;

      if (SUPER_USERS.has(user.email))
        role = SUPER;
      else if (ADMIN_USERS.has(user.email))
        role = ADMIN; 
      else if (REG_USERS.has(user.email))
        role = USER;

      props.logIn({
        user: {
          email: user.email,
          role
        },
        ...resp
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(setGlobalAlert('The email and password entered does match any users in our system. Please try again!', 'error'));
      console.error('Login Failed!')
      console.error(error);
    }
  }

  const [forgotLoading, setForgotLoading] = useState(false);
  async function onGetResetCode() {
    if (!loading) {
      setForgotLoading(true);
    }
    try {
      const data = {
        email: creds.resetEmail,
      }
      await props.userGetResetCode(data, true);
      setForgotLoading(false);
      dispatch(setGlobalAlert('Check your email for the Password Reset Code!!!'));
      setStep(1);
    }
    catch(error) {
      setForgotLoading(false);
      console.error({error});
      dispatch(setGlobalAlert('Invalid Email Address!', 'error'));
    }
  }

  const [resetLoading, setResetLoading] = useState(false);
  async function onResetPassword() {
    if (!loading) {
      setResetLoading(true);
    }

    try {
      const data = {
        email: creds.resetEmail,
        confirmation_code: creds.code,
        new_password: creds.newPassword,
      }
      await props.userSendResetCode(data, true);
      setResetLoading(false);
      setStep(0);
      setForgotPassword();
      dispatch(setGlobalAlert('Password has been Reset!!!'));
      //return {success: true, message: 'Password has been Changed!!!'};
    }
    catch(error) {
      setResetLoading(false);
      console.error({error});
      dispatch(setGlobalAlert('Password must contain letters, numbers and at least one uppercase letter!', 'error'));
      //return {success: false, message: 'Password must contain letters, numbers and at least one uppercase letter!!!'};
    }
  }

  return (
    <>
      {alert && <FormAlert {...alert} />}
      <Grid container component='main' className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />

        {!forgotPassword &&
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={classes.paper}>
              <Typography component='h1' variant='h5'>
                Partner Portal
              </Typography>
              <Typography component='h1' variant='h4'>
                Sign in
              </Typography>
              <FormGroup>
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='email'
                  autoFocus
                  onChange={handleChange}
                />
                <TextField
                  variant='outlined'
                  margin='normal'
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  autoComplete='current-password'
                  onChange={handleChange}
                />
                {/* <FormControlLabel
                  control={<Checkbox value='remember' color='primary' />}
                  label='Remember me'
                /> */}
                <div className={classes.wrapper}>
                  <Button
                    type='submit'
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={loading}
                    onClick={logIn}
                  >
                    Sign In
                  </Button>
                  {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
                <Grid container>
                  <Grid item xs={12} align="center">
                    <Link href='#' variant='body2' onClick={setForgotPassword}>
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
                <Box mt={5}>
                  <Copyright />
                </Box>
                {/* </form> */}
              </FormGroup>
            </div>
          </Grid>
        }

        {forgotPassword && step===0 &&
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Typography component='h1' variant='h5'>
              Reset Password - Get Code
            </Typography>
            <FormGroup>
              {/* <form className={classes.form} noValidate> */}
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='resetEmail'
                label='Email Address'
                name='resetEmail'
                autoComplete='resetEmail'
                autoFocus
                onChange={handleChange}
              />
              <div className={classes.wrapper}>
                <Button
                  type='submit'
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={forgotLoading}
                  onClick={onGetResetCode}
                >
                  Submit
                </Button>
                {forgotLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </FormGroup>
          </div>
        </Grid>
        }

        {step===1 &&
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Typography component='h1' variant='h5'>
              Reset Password - Change Password
            </Typography>
            <FormGroup>
              {/* <form className={classes.form} noValidate> */}
              <TextField
                variant='outlined'
                margin='normal'
                value={creds.resetEmail}
                required
                fullWidth
                id='resetEmail'
                label='Email Address'
                name='resetEmail'
                autoComplete='resetEmail'
                autoFocus
                onChange={handleChange}
              />
            <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='code'
                label='Code'
                id='code'
                autoComplete='code'
                onChange={handleChange}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='newPassword'
                label='New Password'
                type='password'
                id='newPassword'
                autoComplete='newPassword'
                onChange={handleChange}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='confirmPassword'
                label='Confirm Password'
                type='password'
                id='confirmPassword'
                autoComplete='confirmPassword'
                onChange={handleChange}
              />
              <div className={classes.wrapper}>
                <Button
                  type='submit'
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={resetLoading}
                  onClick={onResetPassword}
                >
                  Reset Password
                </Button>
                {resetLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </FormGroup>
          </div>
        </Grid>
        }
      </Grid>
    </>
  )
}

function mapStateToProps(state) {
  return {
    alert: state.display.get('alert'),
  }
}

export default connect(mapStateToProps, { logIn, userGetResetCode, userSendResetCode })(SignInSide);

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundImage: 'url(/image2.jpeg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: blue,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -12
  }
}));
