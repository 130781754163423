import React, { useEffect, useState } from 'react'
import { LinearProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { paperStyle } from '../styles';
import { connect } from 'react-redux'
import { getOrder, orderAPIPost, orderAPIPut, orderAPIDelete } from '../actions/orders';
import { useHistory, useRouteMatch } from 'react-router';
import OrderForm from '../components/Orders/OrderForm';
import Title from '../components/Title';
import moment from 'moment';
import _ from 'lodash';
import { camelToSnake } from '../lib/api';
import Routes from '../routes';

const disallowedKeys = new Set(['createdAt', 'updatedAt', 'id']);

const defaultOrder = {
  id: undefined,
  affiliateId: '',
  customerEmail: '',
  customerVolume: 0,
  newVolume: 0,
  orderDate: moment(new Date()).toISOString(true),
  orderNumber: '',
  personalVolume: 0,
  volumeType: 'CUSTOMER',
  volumeTotal: 0
};

function Order(props) {
  const { getOrder,
    orderAPIPost,
    orderAPIPut,
    //orderAPIDelete,
    site,
    role } = props;
  const match = useRouteMatch();
  const classes = useStyles();
  const history = useHistory();

  const [order, setOrder] = useState(null);

  useEffect(() => {
    if (match.params.id) {
      getOrder(match.params.id).then(res => {
        setOrder({
          ...res.order,
          volumeType: res.order.personalVolume > 0 ? 'PERSONAL' : 'CUSTOMER',
          volumeTotal: res.order.personalVolume > 0 ? res.order.personalVolume : res.order.customerVolume
        });
      });
    } else {
      setOrder(defaultOrder)
    }
  }, [getOrder, site, match.params.id]);

  async function onSave(newOrder) {
    newOrder = _.pickBy(newOrder, (v, k) => !disallowedKeys.has(k));

    newOrder = camelToSnake(newOrder);

    try {
      let savedOrder = order.id ?
        await orderAPIPut(order.id, newOrder, false)
        :
        await orderAPIPost(newOrder, false);

      setOrder({
        ...savedOrder.order,
        volumeType: savedOrder.order.personalVolume > 0 ? 'PERSONAL' : 'CUSTOMER',
        volumeTotal: savedOrder.order.personalVolume > 0 ? savedOrder.order.personalVolume : savedOrder.order.customerVolume
      });
      return order.id ?
        { success: true, message: 'Order Updated Successfully!' }
        :
        { success: true, message: 'Order Saved Successfully!' };

    } catch (err) {
      console.error({ err });
      return {
        success: false,
        message: order.id
          ? 'Order Update Failed!'
          : 'Order Save Failed!',
      }
    }
  };

  if (!order) return <LinearProgress className={classes.progress} />;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={classes.paper}>
          <Grid container justifyContent='space-between'>
            <Grid item xs>
              <Title>
                {order.id
                  ? 'Order - ' +
                  order.orderNumber
                  : 'New Order'}
              </Title>
            </Grid>
          </Grid>
        </div>
      </Grid>
      <Grid item xs={12}>
        <OrderForm
          order={order}
          onSave={onSave}
          onCancel={() => history.push(Routes.ORDERS)}
          role={role}
        />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  site: state.sites.get('site'),
  role: state.user.getIn(['user', 'role']),
});

export default connect(mapStateToProps, {
  getOrder, orderAPIPost, orderAPIPut, orderAPIDelete
})(Order);

const useStyles = makeStyles({
  paper: paperStyle,
  progress: {
    marginTop: 20,
    marginBottom: 20,
  },
});
