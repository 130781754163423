export const colors = {
  primary: {
    default: '#36adf4',
    light: '#7cceff',
    dark: '#107fc1'
  },
  grey: {
    default: '#dedede',
    light: '#f2f2f2',
    medium: '#b4b4b4',
    dark: '#4e4e4e'
  },
  green: '#209625',
  black: '#000',
  white: '#fff'
}

export const constants = {
  navHeight: {
    mobile: 110,
    tablet: 130,
    desktop: 140
  },
  footerHeight: {
    mobile: 110,
    tablet: 130,
    desktop: 140
  }
}

export const transitions = {
  ease: (properties, time) => `${properties} ${time}s ease`,
  linear: (properties, time) => `${properties} ${time}s linear`
}

export const paperStyle = {
  display: 'flex',
  overflow: 'hidden', // setting this to auto causes weird bugs when used with Grid
  flexDirection: 'column',
  padding: 15,
}
