import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import MaterialTable from '@material-table/core';
import ExportPdf from '@material-table/exporters/pdf';
import ExportCsv from '@material-table/exporters/csv';

export default function AdminTable(props) {
  const { rows } = props;

  const renderLink = useCallback(rowData => <Link to={{ pathname: `/admin/` + rowData.username }}>{rowData.username}</Link>, [])

  return (
    <MaterialTable
      title="Admins"
      columns={[
        {
          title: 'Email', field: 'username', align: "left",
          render: renderLink
        },
        {
          title: 'First Name', field: 'attributes.name', align: "left",
        },
        {
          title: 'Last Name', field: 'attributes.familyName', align: "left"
        },
        { title: 'Enabled', field: 'enabled', align: "right", type: 'boolean' }
      ]}
      data={rows}
      options={{
        showTitle: false,
        searchFieldAlignment: 'left',
        exportAllData: true,
        filtering: false,
        paging: true,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50, 100, 500],
        paginationType: 'normal',
        tableLayout: "fixed",
        padding: "dense",
        draggable: false,
        emptyRowsWhenPaging: false,
        exportMenu: [{
          label: 'Export PDF',
          exportFunc: (cols, datas) => ExportPdf(cols, datas, "Admins")
        },
        {
          label: 'Export CSV',
          exportFunc: (cols, datas) => ExportCsv(cols, datas, "Admins")
        }]
      }}
    />
  );
}

AdminTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    username: PropTypes.string.isRequired,
  })),
}