import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import * as _ from 'lodash';

import MaterialTable from '@material-table/core';
import ExportPdf from '@material-table/exporters/pdf';
import ExportCsv from '@material-table/exporters/csv';

import { makeStyles } from '@material-ui/core/styles';

import { paperStyle } from '../../styles';

import Title from "../Title";

export default function AffiliateTable(props) {
  const { rows, title } = props;
  const classes = useStyles();

  const renderLink = useCallback(rowData => {
    let v = rowData.id;
    if (rowData.tableData.id !== 0 && rowData.disabled === false)
      v = <Link to={{ pathname: `/affiliate/` + rowData.id }}>{rowData.id}</Link>

    return v
  }, []);

  const firstName = useCallback(rowData => {
    let v = rowData.name;
    if (rowData.disabled === true)
      v = '******'
    else 
      v = rowData.name;
    return v
  }, []); 

  const lastName = useCallback(rowData => {
    let v = rowData.familyName;
    if (rowData.disabled === true)
      v = '******'
    else 
      v = rowData.familyName;
    return v
  }, []);

  const calculateTRV = useCallback(rowData => numeral(rowData.personalVolume + rowData.customerVolume).format("$0,000.00"), []);

  const calculateL1 = useCallback(rowData => {
    const AFFILIATE_TV = _.sumBy(rows, r => r.personalVolume + r.customerVolume);
    let v = rowData.customerVolume + rowData.personalVolume;
    if (rowData.tableData.id === 0)
      v = AFFILIATE_TV - (rowData.customerVolume + rowData.personalVolume);

    return numeral(v).format("$0,000.00")
  }, [rows]);

  return (
    <>
      {title &&
        // <Paper className={classes.paperTitle}>
        <div className={classes.paperTitle}>
          <Title>{title}</Title>
        </div>
        // </Paper>
      }
      <MaterialTable
        title="Affiliates"
        columns={[
          {
            title: 'Affiliate ID', field: 'id', type: 'numeric', align: "left",
            render: renderLink
          },
          { title: 'First Name', field: 'name',
          render: firstName },
          { title: 'Last Name', field: 'familyName',
          render: lastName },
          {
            title: 'PV', field: 'personalVolume', type: 'currency', headerStyle: { textAlign: 'center' }
          },
          {
            title: 'CV', field: 'customerVolume', type: 'currency', headerStyle: { textAlign: 'center' }
          },
          {
            title: 'NV', field: 'newVolume', type: 'currency', headerStyle: { textAlign: 'center' }
          },
          {
            title: 'TRV', align: "right", headerStyle: { textAlign: 'center' },
            render: calculateTRV,
            exportTransformer: calculateTRV
          },
          {
            title: 'L1', align: "right", headerStyle: { textAlign: 'center' },
            render: calculateL1,
            exportTransformer: calculateL1,
          },
          {
            title: 'Email', field: 'email'
          }
        ]}
        data={rows}
        options={{
          showTitle: false,
          searchFieldAlignment: 'left',
          exportAllData: true,
          filtering: false,
          draggable: false,
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 20, 50, 100, 500],
          tableLayout: "fixed",
          padding: "dense",
          emptyRowsWhenPaging: false,
          exportMenu: [{
            label: 'Export PDF',
            exportFunc: (cols, datas) => ExportPdf(cols, datas, "AffiliateDownline")
          },
          {
            label: 'Export CSV',
            exportFunc: (cols, datas) => ExportCsv(cols, datas, "AffiliateDownline")
          }]
        }}
      />
    </>
  );
}

AffiliateTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
  },
  paperTitle: {
    marginBottom: theme.spacing(2),
    ...paperStyle,
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  }
}));
