import React, { useState }from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

import {
  MenuItem,
  InputLabel,
  Select,
  Grid,
  Tooltip,
  IconButton
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import TodayIcon from '@material-ui/icons/Today';

import DataFilters from "./DataFiltersLabel";
import { paperStyle } from '../styles';

import { invert } from 'lodash';

export function formatDate(date, type) {
  return type === 'monthly' ? moment(date).format('YYYY-MM') : moment(date).format('GGGG-[W]WW');
};

export default function PeriodPicker(props) {
  const { type, date, onTypeChange, onDateChange, disabled, typeDisabled, disableToday } = props;

  const [selectedDate, setSelectedDate] = useState(moment(date, 'YYYY-MM').startOf('month'));

  const classes = useStyles();

  const onChangeWrapper = f => e => f(e.target.value);

  const typeChange = onChangeWrapper(newType => {
    let newDateString = null;

    if (type === "monthly" && date === moment().startOf('month').format('YYYY-MM')) {
      newDateString = formatDate(moment().startOf('week'), newType);
    }
    else {
      newDateString = formatDate(moment(selectedDate), newType);
    }

    onTypeChange(newType);
    onDateChange(newDateString);
  });

  const todayChange = onChangeWrapper(newDate => {
    let newDateString = moment().format('YYYY-MM-DD');

    onDateChange(newDateString);
  });

  const handleDateChange = (date) => {
    let newDateString = null;

    //console.log('Week of Year iso', moment(date).isoWeekYear(), moment(date).isoWeek())
    if (type === "monthly" && date === moment().startOf('month').format('YYYY-MM')) {
      newDateString = formatDate(moment().startOf('week'), type);
    }
    else {
      newDateString = formatDate(moment(date), type);
    }

    setSelectedDate(date);
    onDateChange(newDateString);
  };

  return (
    <Grid container align="center" justifyContent="center" spacing={5}>
      <Grid item>
        <DataFilters>Pick a Period: </DataFilters>
      </Grid>
      {!typeDisabled &&
        <Grid item>
          <InputLabel shrink id="type">Period Type</InputLabel>
          <Select
            labelId="type"
            value={type}
            displayEmpty
            placeholder="Select a Period"
            onChange={typeChange}
            disabled={disabled} >
            <MenuItem value='monthly'>
              Monthly
            </MenuItem>
            <MenuItem value="weekly">
              Weekly
            </MenuItem>
          </Select>
        </Grid>
      }
      <Grid item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            autoOk={true}
            format={type === 'monthly' ? 'MMMM yyyy' : moment(date).format('WW, GGGG')}
            // margin="normal"
            id="date-picker-inline"
            label={type === 'monthly' ? "Month of" : "Week of"}
            value={selectedDate}
            onChange={handleDateChange}
            disabled={disabled}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      {disableToday &&
        <>
          <Grid item>
            <DataFilters>Today: </DataFilters>
          </Grid>
          <Grid item>
            <Tooltip title="Today">
              <IconButton
                className={classes.iconPosition}
                edge='start'
                color='inherit'
                aria-label='Today'
                value={moment()}
                onClick={todayChange}
                >
                <TodayIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </>
      }
    </Grid>
  );
}

PeriodPicker.propTypes = {
  type: PropTypes.oneOf(["monthly", "weekly"]).isRequired,
  date: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  typeDisabled: PropTypes.bool,
  onTypeChange: PropTypes.func,
  onDateChange: PropTypes.func.isRequired,
}

const useStyles = makeStyles({
  iconPosition: {
    paddingTop: '18px',
    paddingLeft: '4px',
    ...paperStyle,
  },
  invertCalendar: {
    filter: invert(0),
  },
});
