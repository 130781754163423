import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import { paperStyle } from '../../styles';
import Level from './Level';

class PlanStructure extends Component {
  addLevel() {
    const { levels, isShares, isBonuses } = this.props;

    this.props.onUpdate(
      levels.push(
        Immutable.fromJS({
          level: levels.count(),
          type: '',
          tiers: [
            {
              from: 0,
              ...(isShares
                ? { shares: 0 }
                : isBonuses
                ? { amount: 0, rate: 0 }
                : { rate: 0 }),
            },
          ],
          trigger: null,
          ...(isShares || isBonuses
            ? { sumLevels: [] }
            : { description: 'whatever' }),
        })
      )
    );
  }

  removeLevel() {
    const { levels } = this.props;
    this.props.onUpdate(levels.delete(levels.count() - 1));
  }

  render() {
    const {
      classes,
      editing,
      levels,
      isShares,
      isBonuses,
      isCompPlan,
      payoutType,
      compLevels,
    } = this.props;

    return (
      <>
        {levels.toJS().map((lvl, index) => (
          <Level
            isShares={isShares}
            isBonuses={isBonuses}
            isCompPlan={isCompPlan}
            payoutType={payoutType}
            key={`level-${index}`}
            editable={editing}
            className={classes.level}
            level={lvl}
            numLevels={
              !isShares && !isBonuses ? levels.count() : compLevels.length
            }
            onRemoveLevel={
              index === levels.count() - 1 ? this.removeLevel.bind(this) : null
            }
            onChange={(level) => this.props.onUpdate(levels.set(index, level))}
          />
        ))}
        {editing && (
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            onClick={this.addLevel.bind(this)}
          >
            {!isShares && !isBonuses ? 'Add Level' : 'Add Group'}
          </Button>
        )}
      </>
    );
  }
}

PlanStructure.propTypes = {
  onUpdate: PropTypes.func,

  editing: PropTypes.bool.isRequired,
  compLevels: PropTypes.array,
  levels: ImmutablePropTypes.list.isRequired,
  isShares: PropTypes.bool.isRequired,
  isBonuses: PropTypes.bool.isRequired,
  isCompPlan: PropTypes.bool,
  payoutType: PropTypes.string,
};

PlanStructure.defaultProps = {
  title: 'Plan Structure',
  isShares: false,
  isBonuses: false,
};

const styles = {
  titleArea: {
    marginBottom: 15,
    ...paperStyle,
  },
  paperTitle: {
    marginBottom: 2,
    ...paperStyle,
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  },
  level: {
    marginBottom: 30,
    ...paperStyle,
  },
};

export default withStyles(styles)(PlanStructure);
