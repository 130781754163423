import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { paperStyle } from '../../styles';
import Title from "../../components/Title";
import PeriodPicker from "../PeriodPicker";
import AffiliateDownlineTable from './AffiliateDownlineTable';
import { getAffiliateDownline } from '../../actions/affiliates';

function AffiliateDownline (props) {
  const { getAffiliateDownline, affiliateId } = props;

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState(null);
  const [periodDate, setPeriodDate] = useState(moment().format('YYYY-MM'));

  useEffect(() => {
    setLoading(true);

    let startDate = moment(periodDate, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
    let endDate = moment(periodDate, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');

    getAffiliateDownline(affiliateId, {from: startDate, to: endDate})
      .then(resp => setSummary(resp.summary))
      .catch(error => {
        // TODO show error
        console.log({error});
      })
      .finally(() => setLoading(false));
  }, [getAffiliateDownline, periodDate, affiliateId])

  return (
    <>
      <div className={classes.paper}>
        <Title>Downline</Title>
        <PeriodPicker
          onDateChange={setPeriodDate}
          type='monthly'
          typeDisabled
          date={periodDate}
          disabled={loading} />
      </div>

      {loading && <LinearProgress className={classes.progress} />}

      <AffiliateDownlineTable rows={summary || []} />
    </>
  )
}

AffiliateDownline.propTypes = {
  rows: PropTypes.array
}

function mapStateToProps (state) {
  return {
    site: state.sites.get('site'),
  }
}

export default connect(mapStateToProps, { getAffiliateDownline })(AffiliateDownline)

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    ...paperStyle,
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  }
}));
