
export const SUPER = 'SUPER';
export const ADMIN = 'ADMIN';
export const USER = 'USER';
export const READ_ONLY = 'READ_ONLY';

export function hasSuper(role) {
  return role === SUPER;
}

export function hasAdmin(role) {
  return role === ADMIN || hasSuper(role);
}

export function hasUser(role) {
  return role === USER || hasAdmin(role);
}

export function hasReadOnly(role) {
  return role === READ_ONLY || hasUser(role);
}
