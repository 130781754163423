import Immutable from 'immutable';

import { createReducer, simpleSetter } from '../lib/reducers';
import {
  UPDATE_SITES,
  SELECT_SITE,
  UPDATE_SITE_COMMISSIONS,
  LOG_OUT,
} from '../actions/types';

const initialState = Immutable.Map({
  sites: [],
  site: null,
});
export default createReducer(initialState, {
  // Action type -> function
  [UPDATE_SITES]: simpleSetter('sites'),
  [SELECT_SITE]: simpleSetter('site'),
  [UPDATE_SITE_COMMISSIONS]: simpleSetter(['site', 'commissions'], 'commissions'),
  [LOG_OUT]: () => initialState,
});
