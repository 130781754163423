import React, { useState } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/styles";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const TableStyledAccordion = (props) => {
    const classes = useStyles();

    const { title, helpText, content } = props;
    const [opened, setOpened] = useState(false);

    const handleOpenChange = () => {
        opened ? props.onClose && props.onClose() : props.onOpen && props.onOpen();
        setOpened(prevState => !prevState);
    }

    return <>
        <Accordion expanded={opened} onChange={handleOpenChange} TransitionProps={{ unmountOnExit: true }} className={classes.accordion}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="TableStyledAccordion-content"
                id="TableStyledAccordion-header"
                style={{ alignItems: 'center' }}
            >
                <Typography className={classes.accordionHeading}>{title}</Typography>
                {!opened && <Typography className={classes.accordionSecondaryHeading}>{helpText}</Typography>}
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                {content}
            </AccordionDetails>
        </Accordion>
    </>
};

const useStyles = makeStyles((theme) => ({
    accordion: {
        borderRadius: '4px'
    },
    accordionHeading: {
        fontSize: '1.2rem',
        color: theme.palette.primary.main,
        flexBasis: '40%',
        flexShrink: 0,
    },
    accordionSecondaryHeading: {
        color: theme.palette.text.secondary,
    },
    accordionDetails: {
        padding: 0
    }
}));

TableStyledAccordion.propTypes = {
    title: PropTypes.string.isRequired,
    helpText: PropTypes.string,
    content: PropTypes.node.isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
};

export default TableStyledAccordion;