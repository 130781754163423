import React from 'react';
import * as _ from 'lodash';

import Report from '.';
import { COLUMNS, formatAffiliates, parseCommission } from '../../lib/reports';
import { useMonthlyCommissionsData } from '../../lib/hooks';


function calculateRows({ affiliates, comms, levels, periodDate }) {
  const levelColNames = _.map(_.range(1, levels), l => `level${l}`);

  affiliates = formatAffiliates({affiliates, periodDate});
  // calculate stuff
  _.forEach(comms, comm => {
    const aff = affiliates[comm.affiliateId];

    if (!aff) {
      console.warn('Missing Affilate', comm)
      return
    }
    
    if (comm.level === 0) {
      aff.PV = comm.personalVolume;
      aff.CV = comm.customerVolume;
      aff.NV = comm.newVolume;
      aff.AV = aff.PV + aff.CV;
    }
    else {
      aff[`level${comm.level}`] = _.sum(_.values(parseCommission(comm)));
    }
  });
  // calculate TV col
  return _.map(_.values(affiliates), aff => (
    {...aff, TV: _.sum(_.values(_.pick(aff, ['AV', ...levelColNames])))})
  );
}

export default function KeyMetrics(props) {
  const report = useMonthlyCommissionsData({ calculateRows });
  const { levels } = report;

  const columns = COLUMNS.BASE_AFFILIATE_COLS.concat([
    COLUMNS.PV,
    COLUMNS.CV,
    COLUMNS.NV,
    COLUMNS.AV,
    ..._.map(_.range(1, levels), l => ({
      title: `L${l}`,
      field: `level${l}`,
      type: 'currency',
    })),
    COLUMNS.TV,
  ]);


  return (
    <Report
      title="Key Metrics for Period"
      exportTitle="Key Metrics"
      columns={columns}
      {...report} />
  )
}
