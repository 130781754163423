import React from 'react';
import * as _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import  { 
  Typography,
  Divider,
  Grid
 } from '@material-ui/core';

import Title from '../Title';

import { formatCurrency } from '../NumericInput'

function LevelRow(props) {
  return (
    <Grid container justifyContent="space-between">
      <Grid item>{props.label}</Grid>
      <Grid item>{formatCurrency(props.value)}</Grid>
    </Grid>
  );
}

export default function Revenue(props) {
  const { orders } = props;
  const classes = useStyles();

  const PV = _.sumBy(orders, 'personalVolume');
  const CV = _.sumBy(orders, 'customerVolume');
  const NV = _.sumBy(orders, 'newVolume');
  const totalOrders =  orders.length;
  const AOV = (PV + CV) / totalOrders;

  return (
    <>
      <Title>Revenue</Title>

      <Typography variant='h6'>
        <LevelRow label='PV:' value={PV} />
      </Typography>
      <Typography variant='h6'>
        <LevelRow label='CV:' value={CV} />
      </Typography>
      <Typography variant='h6'>
        <LevelRow label='NV:' value={NV} />
      </Typography>

      <Divider className={classes.divider} />

      <Typography variant='subtitle1'>
        <LevelRow label='Total Retail:' value={PV + CV} />
      </Typography>
      <Typography variant='subtitle1'>
        <Grid container justifyContent="space-between">
          <Grid item>Total Orders:</Grid>
          <Grid item>{totalOrders}</Grid>
        </Grid>
      </Typography>
      <Typography variant='subtitle1'>
        <LevelRow label='AOV:' value={AOV} />
      </Typography>
    </>
  );
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  divider: {
    margin: '5px 0 10px',
  },
});

