import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { LinearProgress, Grid, Tooltip, IconButton, Divider } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';

import { paperStyle } from '../styles';
import Title from '../components/Title';
import SharesTable from '../components/Shares/SharesTable';
import { getSiteShares, getSiteSharesSnapshots } from '../actions/sites';
import Routes from '../routes';

import { hasAdmin } from '../lib';
import { useHistory } from 'react-router-dom';
import TableStyledAccordion from '../components/TableStyledAccordion';
function Shares(props) {
  const { getSiteShares, getSiteSharesSnapshots, site, role } = props;
  const classes = useStyles();

  const [shares, setShares] = useState([]);
  const [sharesSnapshots, setSharesSnapshots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSnapshots, setLoadingSnapshots] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);

    getSiteShares()
      .then((resp) => setShares(resp.shares))
      .finally(() => setLoading(false));
  }, [getSiteShares, site]);

  function handleClick() {
    history.push(Routes.NEW_SHARE);
  }

  const handleSnapshotsOpen = () => {
    setLoadingSnapshots(true);

    getSiteSharesSnapshots()
      .then((res) => setSharesSnapshots(res.sharesSnapshots))
      .finally(() => setLoadingSnapshots(false));
  }


  return (
    <>
      <div className={classes.paper}>
        <Grid container justifyContent='space-between'>
          <Grid item xs>
            <Title>Shares</Title>
          </Grid>
          <Grid item>
            {hasAdmin(role) && (
              <Tooltip title='Add Shares'>
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='Add Shares'
                  onClick={handleClick}
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </div>

      {loading ?
        <LinearProgress className={classes.progress} />
        :
        <>
          <SharesTable rows={shares || []} />
          <Divider className={classes.divider} />
          <TableStyledAccordion
            title='Shares History'
            helpText='Browse Share Plans in the Past'
            onOpen={handleSnapshotsOpen}
            content={<SharesTable rows={sharesSnapshots || []} isSnapshots isLoading={loadingSnapshots} />}
          />
        </>
      }
    </>
  );
}

Shares.propTypes = {
  rows: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    site: state.sites.get('site'),
    role: state.user.getIn(['user', 'role']),
  };
}

export default connect(mapStateToProps, { getSiteShares, getSiteSharesSnapshots })(Shares);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    ...paperStyle,
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
}));
