import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import MaterialTable from '@material-table/core';
import ExportPdf from '@material-table/exporters/pdf';
import ExportCsv from '@material-table/exporters/csv';

export default function AffiliatesTable(props) {
  const { rows } = props;

  const renderLink = useCallback(rowData => (<Link to={{ pathname: `/affiliate/` + rowData.id }}>{rowData.id}</Link>), []);

  const hideColumnStyle = {
    display: 'none'
  };

  return (
    <MaterialTable
      title="Affiliates"
      columns={[
        {
          title: 'Affiliate ID', field: 'id', type: 'numeric', align: "left",
          render: renderLink
        },
        { title: 'First Name', field: 'name' },
        { title: 'Last Name', field: 'familyName' },
        { title: 'Email', field: 'email' },
        { title: 'Address 1', field: 'address.line1', 
          cellStyle: hideColumnStyle,  headerStyle: hideColumnStyle,
        },
        { title: 'Address 2', field: 'address.line2', 
          cellStyle: hideColumnStyle,  headerStyle: hideColumnStyle,
        },
        {
          title: 'Referrer ID',
          field: 'referrerId',
          render: rowData => <Link to={{ pathname: `/affiliate/` + rowData.referrerId }}>{rowData.referrerId}</Link>,
        },
        {
          title: 'Ref. First Name',
          field: 'referrerFName'
        },
        {
          title: 'Ref. Last Name',
          field: 'referrerLName'
        },
        { title: 'City', field: 'address.city' },
        { title: 'State', field: 'address.state' },
        { title: 'Country', field: 'address.country' },
        { title: 'Zip Code', field: 'address.zipCode', 
          cellStyle: hideColumnStyle,  headerStyle: hideColumnStyle,
        }
      ]}
      data={rows}
      options={{
        showTitle: false,
        searchFieldAlignment: 'left',
        draggable: false,
        exportAllData: true,
        filtering: false,
        paging: true,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50, 100, 500],
        tableLayout: "fixed",
        padding: "dense",
        emptyRowsWhenPaging: false,
        exportMenu: [{
          label: 'Export PDF',
          exportFunc: (cols, datas) => ExportPdf(cols, datas, "Affiliates")
        },
        {
          label: 'Export CSV',
          exportFunc: (cols, datas) => ExportCsv(cols, datas, "Affiliates")
        }]
      }}
    />
  );
}

AffiliatesTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })),
}
