import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  LinearProgress,
  Grid,
  Tooltip,
  IconButton
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';

import { paperStyle } from '../styles';

import Title from "../components/Title";

import AdminTable from '../components/Admins/AdminTable';
import { getSiteAdmins } from '../actions/sites';

import Routes from '../routes';

import { hasAdmin } from '../lib';

function Admins (props) {
  const { getSiteAdmins, site, role } = props;
  const classes = useStyles();
  const history = useHistory();

  const [admins, setSiteAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  

  useEffect(() => {
    setLoading(true);

    getSiteAdmins()
      .then(resp => setSiteAdmins(resp.admins))
      .finally(() => setLoading(false));
  }, [getSiteAdmins, site])

  return (
    <>
      <div className={classes.paper}>
        <Grid container justifyContent="space-between">
          <Grid item xs>
            <Title>Administrators</Title>
          </Grid>
          <Grid item>
            {(hasAdmin(role)) &&
              <Tooltip title="Add Administrator">
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='Add Administrator'
                  onClick={() => {history.push(Routes.NEW_ADMIN)}}
                  >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            }
          </Grid>
        </Grid>
      </div>

      {loading && <LinearProgress className={classes.progress} />}

      <AdminTable rows={admins} />
    </>
  )
}

Admins.propTypes = {
  rows: PropTypes.array,
  getSiteAdmins: PropTypes.func.isRequired,
}

function mapStateToProps (state) {
  return {
    site: state.sites.get('site'),
    role: state.user.getIn(['user', 'role'])
  }
}

export default connect(mapStateToProps, { getSiteAdmins })(Admins)

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    ...paperStyle,
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  }
}));

