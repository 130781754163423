import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import * as _ from 'lodash';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Routes from '../routes';
import { LinearProgress } from '@material-ui/core';

import { CompleteForm } from '../components/Form';
import PlanStructure from '../components/MLM/PlanStructure';
import {
  getSiteBonus,
  deleteSiteBonus,
  postSiteBonuses,
  getSiteBonusSnapshot,
  postSiteBonusSnapshot
} from '../actions/sites';
import { hasAdmin, hasSuper } from '../lib';

const defaultBonus = {
  name: '',
  payoutType: 'PERCENTAGE',
  maxTierDisable: false,
  access: 'PUBLIC',
  levels: [],
};

function Bonus(props) {
  const { getSiteBonus, postSiteBonuses, deleteSiteBonus, getSiteBonusSnapshot, postSiteBonusSnapshot, commissions, role } =
    props;
  const [bonus, setBonus] = useState();
  const [payoutType, setPayoutType] = useState();
  const [reloadBonus, setReload] = useState(false);
  const [isSnapshot, setIsSnapshot] = useState(false);
  const match = useRouteMatch();
  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    if (match.params.id) {
      match.params.period ?
        getSiteBonusSnapshot(match.params.id, match.params.period)
          .then(res => { setBonus(res.bonusesSnapshot); setIsSnapshot(true); })
        :
        getSiteBonus(match.params.id)
          .then(res => { setBonus(res.bonuses); setIsSnapshot(false) });
    } else {
      setBonus(defaultBonus);
      setIsSnapshot(false);
    }
  }, [match.params.id, match.params.period, reloadBonus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    bonus && setPayoutType(bonus.payoutType);
  }, [bonus]);

  // TODO: save snapshot after edit
  async function onSave(newBonus) {

    if (isSnapshot) {
      try {
        await postSiteBonusSnapshot(match.params.period, { bonuses_snapshot: newBonus });
        return { success: true, message: 'Bonus snapshot saved successfully' };
      }
      catch(error){
        return {
          success: false,
          message: 'Something went wrong while saving bonus snapshot.',
        };
      }
    }

    delete newBonus.siteId;
    try {
      await postSiteBonuses({ bonuses: newBonus });
      setReload(!reloadBonus);
    } catch (error) {
      return {
        success: false,
        message: 'Something went wrong while saving bonus.',
      };
    }
    return { success: true, message: 'Bonus saved successfully' };
  }

  async function onDelete() {
    try {
      setBonus(null);
      await deleteSiteBonus(bonus.id);
      history.push(Routes.BONUSES);
    } catch (error) {
      return { success: false, message: 'Something broke deleting bonus' };
    }
    return { success: true, message: 'Bonus deleted successfully' };
  }

  function validateBonus(newBonus) {
    let error = null;

    _.forEach(newBonus.levels, (lvl) => {
      if (error) return;

      if (lvl.sumLevels.length === 0)
        error = 'You must select level for you group';

      if (!lvl.type) error = 'You must set a type for your group';

      _.forEach(lvl.tiers, (tier, i) => {
        const prevTier = i > 0 ? lvl.tiers[i - 1] : null;

        if (
          !_.every([tier.from, tier.amount], _.isNumber) &&
          (!prevTier ||
            (prevTier.from < tier.from &&
              prevTier.amount < tier.amount &&
              prevTier.rate < tier.rate))
        )
          error = 'Invalid tier settings';
      });

      if (lvl.trigger) {
        if (lvl.trigger.sumLevels.length === 0)
          error = 'Trigger requires a selected level';
        // if (!lvl.trigger.bucket) error = 'Trigger requires a bucket';
        // else if (lvl.trigger.sumLevels.length === 0)
        //   error = 'Trigger requires selected levels';
        // else if (!lvl.trigger.logic)
        //   error = 'Trigger requires a inclusion logic type';
        // else if (!lvl.trigger.recruits)
        //   error = 'Trigger requires number of recruits';
        // else if (!lvl.trigger.threshold) error = 'Trigger requires a threshold';
        // else if (!lvl.trigger.recruitThreshold)
        //   error = 'Trigger requires a recruit threshold';
        // else if (!lvl.trigger.recruitBucket)
        //   error = 'Trigger requires a recruit bucket';
      }
    });

    return error;
  }

  if (!bonus) return <LinearProgress className={classes.progress} />;

  function handlePayoutTypeSelect(payoutType) {
    setPayoutType(payoutType);
  }

  return (
    <CompleteForm
      className={classes.header}
      title={bonus.name ? `Bonus Plan - ${bonus.name}${isSnapshot ? ` - Snapshot on ${match.params.period}` : ''}` : 'New bonus plan'}
      object={bonus}
      newObject={match.params.id ? false : true}
      handlePayoutTypeSelect={handlePayoutTypeSelect}
      fields={BONUS_FIELDS}
      noEdit={isSnapshot ? !hasSuper(role) : !hasAdmin(role)}
      noDelete={isSnapshot ? true : !hasAdmin(role)}
      onSave={onSave}
      onDelete={onDelete}
      onCancel={() => history.push(Routes.BONUSES)}
      message='Bonus'
      validateFunc={validateBonus}
      formRender={({ editing, object, onUpdate }) => (
        <PlanStructure
          isBonuses
          payoutType={payoutType}
          levels={object.get('levels')}
          compLevels={commissions}
          editing={editing}
          onUpdate={(levels) => {
            onUpdate(object.set('levels', levels));
          }}
        />
      )}
    />
  );
}

Bonus.propTypes = {
  bonus: PropTypes.shape({
    id: PropTypes.number.isRequired,
    siteId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    rate: PropTypes.number.isRequired,
    levels: PropTypes.array.isRequired,
    payoutType: PropTypes.string.isRequired,
  }),

  getSiteBonus: PropTypes.func.isRequired,
  postSiteBonuses: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    commissions: state.sites.get('site').commissions,
    role: state.user.getIn(['user', 'role']),
  };
}

export default connect(mapStateToProps, {
  getSiteBonus,
  postSiteBonuses,
  deleteSiteBonus,
  getSiteBonusSnapshot,
  postSiteBonusSnapshot
})(Bonus);

const useStyles = makeStyles({
  progress: {
    marginTop: 20,
    marginBottom: 20,
  },
  header: {
    marginBottom: 25,
  },
});

const BONUS_FIELDS = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'payoutType',
    label: 'Payout Type',
    type: 'select',
    selectOptions: [
      {
        label: 'Fixed Amount',
        value: 'FIXED AMOUNT',
      },
      {
        label: 'Percentage',
        value: 'PERCENTAGE',
      },
    ],
  },
  {
    key: 'access',
    label: 'Public/Private',
    type: 'toggle',
    toggleOptions: {
      value1: 'PUBLIC',
      value2: 'PRIVATE',
    },
  },
  {
    key: 'maxTierDisable',
    label: 'Disable if Max Tier reached',
    type: 'toggle',
    toggleOptions: {
      value1: false,
      value2: true,
    },
  },
];
