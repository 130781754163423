import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { connect } from 'react-redux';

import { getSiteShares } from '../../actions/sites';
import { CompleteForm } from '../Form';
import { hasUser, hasAdmin } from '../../lib';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const defaultAffiliate = {
  id: '',
  name: '',
  familyName: '',
  email: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    phoneNumbers: [''],
  },
  company: '',
  groupIds: [],
  ecomAccount: '',
  referrerId: '',
};

function AffiliateForm(props) {
  const { affiliate, role, getSiteShares } = props;
  const [shares, setShares] = useState([]);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  //Get share groups for a dropdown
  useEffect(() => {
    setLoading(true);
    getSiteShares().then(res => {
      let newSh = res.shares.filter(sh => sh.access === 'PRIVATE').map(original => ({ value: original.id, label: original.name }));
      setShares(newSh);
    })
      .finally(() => setLoading(false));
  }, [getSiteShares]);
  /*
   * Return an error message if anything is bad, otherwise null.
   */
  function validateEdits(newAffiliate) {
    // TODO other validation

    if (
      _.some(
        [
          ['address', 'country'],
          ['address', 'state'],
        ],
        (k) => !_.get(newAffiliate, k)
      )
    )
      return "You haven't selected all the address fields!";

    return null;
  }

  return loading ?
    <LinearProgress className={classes.progress} />
    :
    <CompleteForm
      title={affiliate ? `Details` : 'New Affiliate'}
      object={affiliate || defaultAffiliate}
      newObject={!affiliate.id}
      validateFunc={validateEdits}
      noEdit={!hasUser(role)}
      noDelete={!hasAdmin(role)}
      onSave={props.onSave}
      onDelete={props.onDelete}
      message='Affiliate'
      onCancel={props.onCancel}
      //role={role}
      fields={{
        General: [
          {
            label: 'First Name',
            key: 'name',
          },
          {
            label: 'Last Name',
            key: ['familyName'],
          },
          {
            label: 'Email',
            key: ['email'],
          },
          {
            label: 'Company',
            key: ['company'],
            required: false,
          },
          {
            label: 'Address Line 1',
            key: ['address', 'line1'],
          },
          {
            label: 'Address Line 2',
            key: ['address', 'line2'],
            required: false,
          },
          {
            label: 'City',
            key: ['address', 'city'],
          },
          {
            label: 'Postal/Zip Code',
            key: ['address', 'zipCode'],
          },
          {
            // Country
            key: ['address', 'country'],
            type: 'country',
          },
          {
            // Region
            key: ['address', 'state'],
            type: 'region',
          },
          {
            label: 'Phone Number',
            key: ['address', 'phoneNumbers', 0],
          },
        ],
        'Other Details': [
          {
            label: 'Share Plans',
            key: 'groupIds',
            required: false,
            type: 'autocomplete',
            autocompleteProps: {
              multiple: true,
              disableCloseOnSelect: true,
              filterSelectedOptions: true,
              getOptionLabel: option => option.label,
              getOptionValue: option => option.value,
              options: shares,
              noOptionsText: 'No Share Plans available'
            }
          },
          {
            label: 'Ecom Account',
            key: 'ecomAccount',
          },
          {
            label: 'Referrer ID',
            key: 'referrerId',
            required: false,
          },
        ],
      }}
    />
};

function mapStateToProps(state) {
  return {
    site: state.sites.get('site'),
    role: state.user.getIn(['user', 'role']),
  };
}

export default connect(mapStateToProps, {
  getSiteShares,
})(AffiliateForm);

const useStyles = makeStyles({
  progress: {
    marginTop: 20,
    marginBottom: 20,
  },
});

AffiliateForm.propTypes = {
  affiliate: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};
