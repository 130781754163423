import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import PeriodPicker, { formatDate } from "../components/PeriodPicker";
import { getAffiliates } from '../actions/affiliates';
import { getMonthly } from '../actions/payouts';

export function usePeriodDate(config) {
  const {
    typeDisabled,
    disabled,
    initialType,
    initialDate,
  } = config;

  const [periodType, setPeriodType] = useState(initialType || 'monthly');
  const [periodDate, setPeriodDate] = useState(formatDate(initialDate || moment(), periodType));

  return {
    periodDate,
    periodType,
    PeriodDate: (
      <PeriodPicker
        onDateChange={setPeriodDate}
        onTypeChange={setPeriodType}
        typeDisabled={typeDisabled}
        type={periodType}
        date={periodDate}
        disabled={disabled} />
    ),
  };
}

/*
 * Hook for boiler plate reports stuff
 */
export function useMonthlyCommissionsData({ calculateRows, monthlyFilters }) {
  const dispatch = useDispatch();
  const site = useSelector(state => state.sites.get('site'));
  const levels = (site && site.commissions) ? site.commissions.length : 0;

  function internalCalculate(config) {
    return calculateRows({levels, ...config});
  }

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { periodDate, periodType, PeriodDate } = usePeriodDate({
    disabled: loading,
    typeDisabled: true,
  });

  useEffect(() => {
    async function getData() {
      setLoading(true);

      let affResp = null, monthlyResp = null;

      try {
        [affResp, monthlyResp] = await Promise.all([
          dispatch(getAffiliates()),
          dispatch(getMonthly({period: periodDate, ...monthlyFilters })),
        ]);
      }
      catch (error) {
        console.warn({error});
      }

      if (affResp && monthlyResp) {
        const comms = monthlyResp.commissions;
        setData(internalCalculate({
          affiliates: affResp.affiliates,
          comms,
          periodDate,
        }));
      }

      setLoading(false);
    }
    getData();
  }, [site, periodDate, setData]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    periodDate,
    periodType,
    PeriodDate,
    data,
    loading,
    levels,
  };
}
