import React from 'react'
// import clsx from 'clsx';
// import useAppStyles from '../styles/App';
import {
  Typography,
  Container
} from '@material-ui/core'

import Title from "../components/Title";

export default function HomePage () {
  // const classes = useAppStyles();
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <>
      <Title >Welcome to the Partner Portal!</Title>
      <div>
        <Container maxWidth="lg" gutterbottom="true">
          <Typography component="div" gutterbottom="true">
            <Typography variant="subtitle1" gutterbottom="true">
              To get started:<br/>
                -- Select a Site from the Select Site dropdown<br/>
                -- A “Sandbox” Site has been created for testing purposes
            </Typography>
            <Typography variant="subtitle1" gutterbottom="true">
              Once a Site is selected, the left-hand menu options can be used to navigate the system. <br/>
              For integration purposes you’ll need your unique Site ID and API Key. Select the Site menu option and scroll down to the bottom of the page.
            </Typography>
            <Typography variant="subtitle1" ggutterbottom="true">
              If you need assistance, support or have questions, please contact CustomerCare@vitalibis.com.
            </Typography>
          </Typography>
        </Container>
      </div>
    </>
  )
}
