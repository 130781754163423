// Credit: jlebensold - http://github.com/jlebensold/peckish
export function createReducer(initialState, handlers) {
  return function reducer(state=initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}

/**
 * Set Immutable state attr to "value" or an attribute with the right name in the action
 */
export function simpleSetter(attr, actionName, value) {
  actionName = actionName || attr;
  if (typeof attr === 'string')
    attr = [attr];

  return (state, action) => state.setIn(attr, value !== undefined ? value : action[actionName]);
}
