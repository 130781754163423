import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash'

import {
  PieChart, Pie, Cell,
} from 'recharts';

import {
  Grid,
  Typography,
  Divider
} from '@material-ui/core';
import ColorSwatchIcon from '@material-ui/icons/Brightness1';
import { makeStyles } from '@material-ui/core/styles';

import { paperStyle } from '../../styles';

import { formatCurrency, formatPercentage } from '../NumericInput'
import { Fragment } from 'react';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#ee3fe0', '#684fec'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function LevelRow(props) {
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between">
      <Grid item className={classes.colorLabel}>{props.label}
        {props.attr === "AV" &&
          <ColorSwatchIcon
            fontSize='small'
            style={{
              color: COLORS[props.index],
              padding: '5px'
            }}
          />
        }
      </Grid>
      <Grid item>{
        props.format !== 'P' && props.format !== 'I' ? formatCurrency(props.value) :
          ((props.format === 'P') ? formatPercentage(props.value) :
            props.value)
      }</Grid>
    </Grid>
  );
}

function LevelRowsWithMultipleValues(props) {
  const classes = useStyles();

  //find max value for every column and add padding on smaller for proper alignment
  let maxColumnValuesLength = {};
  props.rows.forEach(row => {
    row.forEach((column, index) => {
      if (column.value) {
        let valueLength = column.value.toString().split('.')[0].length;
        if ((maxColumnValuesLength[index] && valueLength > maxColumnValuesLength[index]) || maxColumnValuesLength[index] == null) {
          maxColumnValuesLength[index] = valueLength;
        }
      }
    })
  });

  const alignedRows = props.rows.map(row => row.map((column, index) => {
    let valueLength = column.value?.toString().split('.')[0].length;
    if (valueLength < maxColumnValuesLength[index]) {
      //compensate alignment on commas on 1,000.00 and 1,000,000.00
      if (maxColumnValuesLength[index] > 3 && valueLength <= 3) {
        valueLength -= 0.5
      }
      if (maxColumnValuesLength[index] > 6 && valueLength <= 6) {
        valueLength -= 0.5
      }

      column.style = index === row.length - 1
        ?
        { paddingLeft: `${maxColumnValuesLength[index] - valueLength}ch` }
        :
        { paddingRight: `${maxColumnValuesLength[index] - valueLength}ch` }
    }

    return column;
  }));

  return <>{alignedRows.map((row, indexRow) =>
    <Grid container justifyContent='space-between' key={`row-${indexRow}`}>
      {
        row.map((item, indexCol) =>
          <Grid item key={`column-${indexRow}-${indexCol}`}>
            {item.label && <div className={classes.colorLabel} style={{ display: 'inline' }}>{item.label}</div>}
            {item.value !== undefined &&
              <div style={{ display: 'inline', paddingLeft: item.label ? '5px' : undefined, ...item.style }}>{
                item.format !== 'P' && item.format !== 'I' ? formatCurrency(item.value) :
                  ((item.format === 'P') ? formatPercentage(item.value) :
                    item.value)
              }
              </div>
            }
          </Grid>
        )
      }
    </Grid>)}
  </>
}

function LevelStatRows(props) {
  const { aData, labelFunc, attr, type, format } = props;
  return (
    <>
      {aData.map((levelData, index) => (
        <LevelRow
          label={type !== 'S' ?
            (labelFunc(index === 0 ? "Retail" : `Level ${index}`, levelData)) :
            (labelFunc(`Group ${index}`, levelData))
          }
          value={levelData[attr]}
          key={index + levelData}
          type
          format={format}
          attr={props.attr}
          index={index}
        />
      ))}
    </>
  );
}

function calculateSharePerformance(shares) {
  const shareLevels = (!shares ? [] : shares.affiliates);

  let TSP = 0;//Shares Price
  //let ASE = 0; //Affiliate Shares Earned
  let ASV = 0; //Affiliate Shares Earned

  const aShareData = _.map(shareLevels, level => ({
    level: level.level,
    SE: 0,
    ST: 0,
  }));

  if (shares) {
    TSP = shares.sharePrice;//Shares Price
    //ASE = _.sumBy(shareLevels, 'shareEarned'); //Affiliate Shares Earned
    ASV = _.sumBy(shareLevels, 'shareEarnedPrice'); //ASE * TSP; //Affiliate Shares Dollars Earned
  }
  _(shareLevels)
    .groupBy(o => o.level)
    .forEach((group, level) => {
      aShareData[level] = {
        ...aShareData[level],
        SE: _.sumBy(group, o => o.shareEarned),
      };
    });

  return { TSP: TSP, ASV: ASV, aShareData: aShareData, name: shares.name }
}

function Performance(props) {
  const { levels, monthly, shares, payoutPeriod, bonuses, customLevels } = props;
  const classes = useStyles();

  const sharePerformance = shares?.map(sh => calculateSharePerformance(sh));

  const aData = _.map(customLevels ? customLevels : levels, level => ({
    level: level.level,
    type: level.type,
    PV: 0,
    CV: 0,
    NV: 0,
    AV: 0,
    ER: 0,
    EP: 0, //TODO level.tiers.level.rate
  }));

  const TV = _.sumBy(monthly, 'base');//Total Commisionable Volume
  const TC = _.sumBy(monthly, 'periodEarnings');//Total Commissions
  const PE = _.sumBy(monthly, 'periodEarningsPaid'); //Paid Earnings
  //const PO = _.sumBy(monthly, 'payout'); //Still Owed
  let periodProcessed = _.sumBy(monthly, 'isProcessed'); //TODO: need better way to set this
  periodProcessed > 0 ? periodProcessed = 1 : periodProcessed = 0 //Added by Kevin 3/29/2024

  _(monthly)
    .groupBy(o => o.level)
    .forEach((group, level) => {
      aData[level] = {
        ...aData[level],
        PV: _.sumBy(group, o => o.personalVolume),
        CV: _.sumBy(group, o => o.customerVolume),
        NV: _.sumBy(group, o => o.newVolume),
        AV: _.sumBy(group, o => o.base),
        ER: _.sumBy(group, o => o.periodEarnings),
        EP: _.sumBy(group, o => o.periodRate),
      };
    });

  let TBV = 0 //Total Bonus Volume
  let bonusStatsItems = [];

  if (bonuses?.length !== 0) {
    //TBV = bonuses.reduce((prev, current) => prev.bonusEarnings + current.bonusEarnings);

    bonusStatsItems = bonuses.map(bonus => { //Bonus colsolidatioo is happening on the DB side now
      let statsRow = [
        { label: 'All Levels:' }
      ];
      if (bonus.isRecruitThreshold) {
        statsRow.push(
          { label: 'Recruits:', value: bonus.recruitsTotal, format: 'I' },
          { label: 'Qualified Recruits:', value: bonus.recruitsQualified, format: 'I' });
      }
      statsRow.push({ value: bonus.bonusEarnings });
      TBV += bonus.bonusEarnings;
      return statsRow;
    })
  };

  const pieData = aData.map((levelData, index) => ({
    name: index === 0 ? 'CV' : `L${index}`,
    value: levelData.AV,
  }));

  const topLevel = aData[0];

  //console.log(payoutPeriod, periodProcessed)
  return (
    <>
      <Grid container justifyContent="space-evenly" alignItems="center" className={classes.paper}>
        <Grid item>
          <Typography component="p" variant="h5" style={{ textAlign: 'center' }}>
            Period Performance
          </Typography>
          <PieChart width={400} height={400}>
            <Pie
              data={pieData}
              cx={200}
              cy={200}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
            >
              {
                pieData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
              }
            </Pie>
          </PieChart>
        </Grid>
        <Grid item md={4} lg={4}>
          <Grid item>
            <Typography component="p" variant="h5">
              Retail Performance
            </Typography>

            <LevelRow label='Personal Volume (PV):' value={topLevel.PV} />
            <LevelRow label='Customer Volume (CV):' value={topLevel.CV} />
            <LevelRow label='New Volume (NV):' value={topLevel.NV} />
            <Divider />
            <LevelRow label='Total Retail Volume (TRV):' value={topLevel.PV + topLevel.CV} />
            <Divider />

            <Typography component="p" variant="h5">
              Comp Performance
            </Typography>

            <LevelStatRows
              attr='AV'
              labelFunc={(label, levelData) => `${label} (${levelData.type}) `}
              aData={aData} />
            <Divider />
            <LevelRow label='Total Comp Volume (TCV):' value={TV} />
            <Divider />

            {bonuses?.length !== 0 &&
              <>
                <Typography component="p" variant="h5">
                  Bonus Performance
                </Typography>
                <LevelRowsWithMultipleValues rows={bonusStatsItems} />
                <Divider />
                <LevelRow label='Total Bonus Volume:' value={TBV} />
                <Divider />
              </>
            }

            {shares && shares.length > 0 && sharePerformance.some(sp => sp.ASV > 0) &&
              <>
                <Typography component="p" variant="h5">
                  Shares Performance
                </Typography>

                {/* <LevelRow label='Total Site Sales (TS):' value={TS} />
                <LevelRow label='Total Site Pool (TP):' value={TP} />
                <LevelRow label='Site Pool Rate:' format='P' value={TPR} />
                <LevelRow label='Total Shares Earned:' format='I' value={TPSE} /> */}
                {sharePerformance.filter(sp => sp.ASV > 0).map((sp, i) =>
                  <Fragment key={i}>
                    <LevelRow label='Share Plan:' value={sp.name} format='I'/>
                    <LevelRow label='Share Price:' value={sp.TSP} />
                    <LevelStatRows
                      attr='SE'
                      labelFunc={(label, levelData) => `${label} Shares:`}
                      type='S'
                      format='I'
                      aData={sp.aShareData}
                    />
                    <Divider />
                    <LevelRow label='Total Share Volume:' value={sp.ASV} />
                    <Divider />
                  </Fragment>
                )}
              </>
            }

            <Typography component="p" variant="h5">
              Earnings
            </Typography>

            <LevelStatRows
              attr='ER'
              labelFunc={(label, levelData) => {
                return `${label} (${levelData.type}) * ${formatPercentage(levelData.EP)}:`;
              }}
              aData={aData} />

            {bonuses?.length !== 0 &&
              <LevelRow label='Bonus Earnings:' value={TBV} />
            }

            {shares && shares.length > 0 && _.sumBy(sharePerformance, sp => sp.ASV) > 0 &&
              <LevelRow label='Share Earnings:' value={_.sumBy(sharePerformance, sp => sp.ASV)} />
            }

            <Divider />

            <Typography component="p" variant="h5">
            </Typography>
            <LevelRow label='Total Earnings:' value={TC + _.sumBy(sharePerformance, sp => sp.ASV) + TBV} />

            {/* <LevelRow label='Paid to Date M:'
              value={
                payoutPeriod === 'monthly' && periodProcessed === 0 ? 0 
                : payoutPeriod === 'weekly' && periodProcessed === 0 ? PE 
                : TC + _.sumBy(sharePerformance, sp => sp.ASV) + TBV } /> 
            <LevelRow label='Amount Due M:' 
              value={
                payoutPeriod === 'monthly' && periodProcessed === 0 ? TC + _.sumBy(sharePerformance, sp => sp.ASV) + TBV
                : payoutPeriod === 'weekly' && periodProcessed === 0 ? TC + _.sumBy(sharePerformance, sp => sp.ASV) + TBV - PE 
                : 0} /> */}

            {/* <LevelRow label='Paid to Date:'
             value={
              payoutPeriod === 'monthly' && periodProcessed === 0 ? 0 
              : PE + _.sumBy(sharePerformance, sp => sp.ASV) + TBV} />
            <LevelRow label='Amount Due:' 
              value={
                payoutPeriod === 'monthly' && periodProcessed === 0 ? (TC + _.sumBy(sharePerformance, sp => sp.ASV) + TBV) 
                : (PO)} />  */}

            <LevelRow label='Paid to Date:'
              value={
                payoutPeriod === 'monthly' && periodProcessed === 0 ? 0 
                : payoutPeriod === 'monthly' && periodProcessed === 1 ? PE + _.sumBy(sharePerformance, sp => sp.ASV) + TBV //Not usin is_processed correctly
                : payoutPeriod === 'weekly' && periodProcessed === 0 ? PE //Not usin is_processed correctly
                : payoutPeriod === 'weekly' && periodProcessed === 1 ? TC + _.sumBy(sharePerformance, sp => sp.ASV) + TBV
                : (PE ? (payoutPeriod === 'weekly' && periodProcessed === 1)
                : PE + _.sumBy(sharePerformance, sp => sp.ASV)) 
                } /> 
            <LevelRow label='Amount Due:' 
              value={
                payoutPeriod === 'monthly' && periodProcessed === 0 ? TC + _.sumBy(sharePerformance, sp => sp.ASV) + TBV
                : payoutPeriod === 'weekly' && periodProcessed === 0 ? TC + _.sumBy(sharePerformance, sp => sp.ASV) + TBV - PE
                : 0
                } />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
// (PE + ASV + TBV)
function mapStateToProps(state) {
  return {
    payoutPeriod: state.sites.getIn(['site', 'payoutPeriod']),
    levels: state.sites.getIn(['site', 'commissions'])
  };
}
export default connect(mapStateToProps)(Performance);

const useStyles = makeStyles({
  paper: {
    ...paperStyle,
    flexDirection: 'inherit',
  },
  divider: {
    margin: '4px 0 4px',
  },
  colorLabel: {
    display: 'flex',
  },
});
