import { red } from '@material-ui/core/colors'
import { createTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: 'dark',
    // grey: {
    //   800: "#000000", // overrides failed
    //   900: "#121212" // overrides success
    // },
    primary: {
      main: '#90caf9' // #90caf9 Header and Button
    },
    secondary: {
      main: '#ff8a65' //'#424242', //
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#000' // #fff
      // dark: '#000',
    }
  },
  overrides: {
    MuiAppBar: {
      root: {
        padding: '0px 24px',
      },
    },
  },
})

export default theme
