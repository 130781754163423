import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { LinearProgress, Grid, Tooltip, IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';

import { paperStyle } from '../../styles';
import PeriodPicker from '../PeriodPicker';
import OrderTable from './OrderTable';
import Title from '../Title';

import { getOrders } from '../../actions/orders';
import { hasAdmin } from '../../lib';
import Routes from '../../routes';
import { useHistory } from 'react-router';
import { getAffiliates } from '../../actions/affiliates';
import * as _ from 'lodash';

function Orders(props) {
  const { getAffiliates, getOrders, site, affiliateId, role } = props;
  const classes = useStyles();
  const history = useHistory();

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [periodDate, setPeriodDate] = useState(moment().format('YYYY-MM'));

  useEffect(() => {
    setLoading(true);

    let startDate = moment(periodDate, 'YYYY-MM')
      .startOf('month')
      .format('YYYY-MM-DD');
    let endDate = moment(periodDate, 'YYYY-MM')
      .endOf('month')
      .format('YYYY-MM-DD');

    const params = { from: startDate, to: endDate };
    if (affiliateId) params.affiliate = affiliateId;

    async function fetchAffiliatesAndOrders() {
      try {
        // Fetch affiliates data first
        const affiliatesResp = await getAffiliates();

        const affiliatesData = _.keyBy(_.map(affiliatesResp.affiliates, aff => ({
          ..._.pick(aff, ['id', 'name', 'familyName', 'createdAt', 'referrerId']),
        })), 'id');

        // Fetch orders
        const ordersResp = await getOrders(params);

        // Enhance orders with referrerId from affiliates
        _.forEach(ordersResp.orders, (order, id) => {
          if (order.affiliateId && affiliatesData[order.affiliateId]) {
            order.referrerId = affiliatesData[order.affiliateId].referrerId;
          }
        });

        setOrders(ordersResp.orders);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchAffiliatesAndOrders();

  }, [getAffiliates, getOrders, periodDate, site, affiliateId]);

  const handleCreateOrder = () => history.push(Routes.NEW_ORDER);

  return (
    <>
      <div className={classes.paper}>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Title>Orders</Title>
          </Grid>
          {periodDate &&
            <Grid item xs>
              <PeriodPicker
                onDateChange={setPeriodDate}
                typeDisabled
                type='monthly'
                date={periodDate}
                disabled={loading}
              />
            </Grid>}
          {hasAdmin(role) &&
            <Grid item>
              <Tooltip title='Create Order'>
                <IconButton
                  edge='end'
                  color='inherit'
                  aria-label='Create Order'
                  onClick={handleCreateOrder}
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Grid>}
        </Grid>
      </div>

      {loading && <LinearProgress className={classes.progress} />}

      <OrderTable rows={orders || []} />
    </>
  );
}

Orders.propTypes = {
  rows: PropTypes.array,
  affiliateId: PropTypes.number,

  getOrders: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    site: state.sites.get('site'),
    role: state.user.getIn(['user', 'role']),
  };
}

export default connect(mapStateToProps, { getOrders, getAffiliates })(Orders);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    ...paperStyle,
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  },
}));
