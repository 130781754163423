import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import MaterialTable from '@material-table/core';
import ExportPdf from '@material-table/exporters/pdf';
import ExportCsv from '@material-table/exporters/csv';

export default function BonusesTable(props) {
  const { rows, isSnapshots } = props;

  // useEffect(() => {
  //   if(isSnapshots) rows.map(row => ({...row, id: `${row.bonus_group_id}-${row.snapshot_period}`}));
  // }, [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(() =>
    [
      ...isSnapshots ? [{
        title: 'Period', field: 'snapshotPeriod',
        align: 'left',
        render: rowData => (
          <Link to={{ pathname: `/bonus/snapshot/${rowData.bonusGroupId}/${rowData.snapshotPeriod}` }}>{rowData.snapshotPeriod}</Link>),
        defaultSort: 'desc'
      },
      {
        title: 'Bonus ID',
        field: 'bonusGroupId',
        type: 'numeric',
        align: 'left',
        render: rowData => (
          <Link to={{ pathname: `/bonus/snapshot/${rowData.bonusGroupId}/${rowData.snapshotPeriod}` }}>{rowData.bonusGroupId}</Link>),
      }]
        :
        [{
          title: 'Bonus ID',
          field: 'id',
          type: 'numeric',
          align: 'left',
          render: rowData => (
            <Link to={{ pathname: `/bonus/` + rowData.id }}>{rowData.id}</Link>),
        }],
      { title: 'Group', field: 'name' },
      { title: 'Payout Type', field: 'payoutType' },
    ]
    , [isSnapshots]);

  return (
    <MaterialTable
      title='Bonuses'
      columns={columns}
      data={rows}
      isLoading={props.isLoading}
      options={{
        sorting: true,
        showTitle: false,
        searchFieldAlignment: 'left',
        exportAllData: true,
        filtering: false,
        paging: true,
        pageSize: 10,
        pageSizeOptions: [10, 20, 50, 100, 500],
        tableLayout: 'fixed',
        padding: 'dense',
        emptyRowsWhenPaging: false,
        exportMenu: [{
          label: 'Export PDF',
          exportFunc: (cols, datas) => ExportPdf(cols, datas, "Bonuses")
        },
        {
          label: 'Export CSV',
          exportFunc: (cols, datas) => ExportCsv(cols, datas, "Bonuses")
        }]
      }}
    />
  );
}

BonusesTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  isSnapshots: PropTypes.bool,
  isLoading: PropTypes.bool
};
