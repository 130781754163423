const Routes = {
  HOMEPAGE: '',
  DASHBOARD: '/dashboard',
  ABOUT: '/about',
  AFFILIATES: '/affiliates',
  AFFILIATE: '/affiliate/:id',
  NEW_AFFILIATE: '/affiliate/',
  ORDERS: '/orders',
  ORDER: '/order/:id',
  NEW_ORDER: '/order',
  PAYOUTS: '/payouts',
  REPORTS: '/report/:id',
  PAYOUTHISTORY: '/payout-history',
  SITE: '/site',
  NEW_SITE: '/site/new',
  ADMINS: '/admins',
  ADMIN: '/admin/:id',
  NEW_ADMIN: '/admin/new',
  ADMIN_CHANGE_PASSWORD: '/admin/change-password',
  COMMISSIONS: '/commissions',
  COMMISSIONS_HISTORY: '/commissions/history',
  COMMISSIONS_SNAPSHOT: '/commissions/history/:period',
  SHARES: '/shares',
  SHARE: '/share/:id',
  SHARE_SNAPSHOT: '/share/snapshot/:id/:period',
  NEW_SHARE: '/share',
  BONUSES: '/bonuses',
  BONUS: '/bonus/:id',
  BONUS_SNAPSHOT: '/bonus/snapshot/:id/:period',
  NEW_BONUS: '/bonus',
};

export default Routes;
