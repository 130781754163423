import React, { useState }  from 'react'
import { connect } from 'react-redux'
import * as _ from 'lodash';
import { useLocation, useHistory } from 'react-router';

import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import SiteForm from '../components/Site/SiteForm'
import ActionDialog from '../components/ActionDialog';

import {
  siteAPIPut,
  siteAPIPost,
  siteAPIDelete
} from '../actions/sites';
import Routes from '../routes';
import { camelToSnake } from '../lib/api';
//import { getStoredState } from 'redux-persist';

const disallowedKeys = new Set(['apiToken', 'createdAt', 'updatedAt', 'id']);

function ProcessDialog(props) {
  return (
    <ActionDialog
      title='Delete Site'
      message={
        `Are you sure you want to delete this site?`
      }
      submitLabel='Delete'
      {...props} />
  );
}

function Site(props) {
  const { role } = props;
  const loc = useLocation();
  const hist = useHistory();
  const creatingNewSite = loc.pathname === Routes.NEW_SITE;

  const [addAlert, setAlert] = useState(false);
  const [deleteSite, setDeleteSite] = useState(null);

  async function onSave(newSite) {
    newSite = _.pickBy(newSite, (v,k) => !disallowedKeys.has(k));

    // ignore the line keys in transform
    newSite = camelToSnake(newSite, new Set(['line1', 'line2']));

    try {
      if (!creatingNewSite) {
        await props.siteAPIPut(props.site.id, newSite, false);
        return {success: true, message: 'Site Updated!!!'};
      }
      else
      {
        await props.siteAPIPost(newSite, false);
        setAlert(true);
        hist.push(Routes.SITE);
        return {success: true, message: null};
      }
    }
    catch(error) {
      console.error({error});
      return {success: false, message: 'Site Update failed!!!'};
    }
  }

  async function onDelete() {
    try {
      await props.siteAPIDelete(props.site.id);
      hist.push(Routes.HOMEPAGE);
    } catch (error) {
      return {
        success: false,
        message: 'Something went wrong when deleting the Site!',
      };
    }
    return { success: true, message: 'Successfully deleted Site!' };
  }

  const { site } = props;

  return (
    <>
      <ProcessDialog
        open={Boolean(deleteSite)}
        onSubmit={onDelete}
        onCancel={() => setDeleteSite(null)} />
      {addAlert && site &&
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'center'}}
          open={true}
          autoHideDuration={2000}
          onClose={() => setAlert(false)} >
          <Alert severity='success'>{site.name} Created!!!</Alert>
        </Snackbar>
      }
      <SiteForm
        site={site}
        role={role}
        onSave={onSave}
        onDelete={onDelete} />
    </>
  )
}

function mapStateToProps (state) {
  return {
    site: state.sites.get('site'),
    role: state.user.getIn(['user', 'role'])
  }
}

export default connect(mapStateToProps, { siteAPIPut, siteAPIPost, siteAPIDelete })(Site);
