import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'

export default function DataFilters (props) {

  const classes = useStyles();

  return (
    <Typography className={classes.dataFilterLabel}>
      {props.children}
    </Typography>
  )
}

DataFilters.propTypes = {
  children: PropTypes.node
}

const useStyles = makeStyles({
  dataFilterLabel: {
    margin: `15px 0 0 0px`,
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold'
  }
});
