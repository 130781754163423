import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export default function FormAlert(props) {
  const {message, severity, duration, onClose} = props;

  if (!message)
    return null;

  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      open={true}
      autoHideDuration={duration}
      onClose={onClose} >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
}

FormAlert.propTypes = {
  severity: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,

  duration: PropTypes.number
};

FormAlert.defaultProps = {
  duration: 2000,
}
