import Immutable from 'immutable';

import { createReducer, simpleSetter } from '../lib/reducers';
import {
  LOG_IN,
  LOG_OUT,
  UPDATE_ACCESS_TOKEN,
} from '../actions/types';

function logIn(state, action) {
  return state.withMutations(s =>
    s.set('user', action.user)
      .set('accessToken', action.accessToken)
      .set('refreshToken', action.refreshToken)
  );
}

const initialState = Immutable.fromJS({
  user: null,
  accessToken: null,
  refreshToken: null,
});

export default createReducer(initialState, {
  // Action type -> function
  [LOG_IN]: logIn,
  [LOG_OUT]: () => initialState,
  [UPDATE_ACCESS_TOKEN]: simpleSetter('accessToken'),
});
