import * as _ from 'lodash';

import {
  apiGet as libAPIGet,
  apiPost as libAPIPost,
  apiPut as libAPIPut,
  apiDelete as libAPIDelete,
  refreshAccessToken,
} from '../lib/api';

import * as ActionTypes from './types';
import { logOut } from './user';

export function setGlobalAlert(message, severity = 'success', duration = 2000) {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SET_ALERT,
      alert: {
        message,
        severity,
        duration,
        onClose: () => dispatch({ type: ActionTypes.SET_ALERT, alert: null }),
      },
    });
  };
}

// API For Requests requiring user access_token
async function apiRequestWrapper(dispatch, getState, f) {
  let refreshToken = getState().user.get('refreshToken');
  let refreshed = false;

  while (true) {
    try {
      // on success, return result
      return await f();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        if (refreshed || _.isNil(refreshToken)) {
          // we've already tried to refresh or no refresh token, we're logged out
          dispatch(logOut());
          throw error;
        } else {
          // try to get a new token and let loop retry
          console.log('Trying to get Refresh Token!!!');
          refreshed = true;
          let accessToken = await refreshAccessToken(refreshToken);
          if (!_.isNil(accessToken)) {
            dispatch({
              type: ActionTypes.UPDATE_ACCESS_TOKEN,
              accessToken,
            });
          }
        }
      } else {
        // fucked, just show error and break
        throw error;
      }
    }
  }
}

// api wrappers
export function apiPost(endpoint, body, camelTransform = undefined) {
  return (dispatch, getState) => {
    return apiRequestWrapper(dispatch, getState, () => {
      // important to get token in the wrapped function
      const token = getState().user.get('accessToken');
      return libAPIPost(token, endpoint, body, camelTransform);
    });
  };
}

export function apiPut(endpoint, body, camelTransform = undefined) {
  return (dispatch, getState) => {
    return apiRequestWrapper(dispatch, getState, () => {
      // important to get token in the wrapped function
      const token = getState().user.get('accessToken');
      return libAPIPut(token, endpoint, body, camelTransform);
    });
  };
}

export function apiDelete(endpoint, body, camelTransform = undefined) {
  console.log(endpoint);
  return (dispatch, getState) => {
    return apiRequestWrapper(dispatch, getState, () => {
      // important to get token in the wrapped function
      const token = getState().user.get('accessToken');
      console.log('In Delete root:', token, endpoint, body);
      return libAPIDelete(token, endpoint, body, (camelTransform = undefined));
    });
  };
}

export function apiGet(endpoint, testing = false) {
  return (dispatch, getState) => {
    return apiRequestWrapper(dispatch, getState, () => {
      // important to get token in the wrapped function
      let token = getState().user.get('accessToken');
      if (testing) token = token.slice(1);
      return libAPIGet(token, endpoint);
    });
  };
}
