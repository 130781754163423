import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { LinearProgress, Grid, Tooltip, IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';

import { paperStyle } from '../styles';
import Title from '../components/Title';

import AffiliatesTable from '../components/Affiliates/AffiliatesTable';
import { getAffiliates } from '../actions/affiliates';

import { hasAdmin } from '../lib';
import { useHistory } from 'react-router';
import Routes from '../routes';
import * as _ from 'lodash';

function Affiliates(props) {
  const { getAffiliates, site, role } = props;
  const classes = useStyles();
  const history = useHistory();

  const [affiliates, setAffiliates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    getAffiliates()
      .then((resp) => {

        let affiliatesData = _.keyBy(_.map(resp.affiliates, aff => ({
            ..._.pick(aff, ['id', 'name', 'familyName', 'createdAt', 'referrerId']),
          })
        ), 'id');

        _.forEach(resp.affiliates, (aff, id) => {
          if (!_.isNil(aff.referrerId) && affiliatesData[aff.referrerId]) {
            aff.referrerFName = affiliatesData[aff.referrerId].name;
            aff.referrerLName = affiliatesData[aff.referrerId].familyName;
          }
        });

        setAffiliates(resp.affiliates);
      })
      .finally(() => setLoading(false));
  }, [getAffiliates, site]);

  function handleClick() {
    history.push(Routes.NEW_AFFILIATE);
  }

  return (
    <>
      <div className={classes.paper}>
        <Grid container justifyContent='space-between'>
          <Grid item xs>
            <Title>Affiliates</Title>
          </Grid>
          <Grid item>
            {hasAdmin(role) && (
              <Tooltip title='Add Affiliate'>
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='Add Affiliate'
                  onClick={handleClick}
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </div>

      {loading && <LinearProgress className={classes.progress} />}

      <AffiliatesTable rows={affiliates || []} />
    </>
  );
}

Affiliates.propTypes = {
  rows: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    site: state.sites.get('site'),
    role: state.user.getIn(['user', 'role']),
  };
}

export default connect(mapStateToProps, { getAffiliates })(Affiliates);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    ...paperStyle,
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  },
}));
