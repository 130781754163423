import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SiteIcon from "@material-ui/icons/Web";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import UsersIcon from "@material-ui/icons/PeopleAlt";
import BarChartIcon from "@material-ui/icons/BarChart";
import SharesIcon from "@material-ui/icons/ShowChart";
import BonusesIcon from "@material-ui/icons/CardGiftcard";
import LayersIcon from "@material-ui/icons/Layers";
import PayoutIcon from "@material-ui/icons/AttachMoney";
import HistoryIcon from "@material-ui/icons/History";
import CommissionsIcon from "@material-ui/icons/DataUsage";
import SettingsIcon from "@material-ui/icons/Settings";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { hasSuper, hasAdmin } from "../lib";

import Routes from "../routes";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export function MainListItems(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div>
      <List>
        <Tooltip title="Dashboard">
          <ListItem button component={NavLink} to={Routes.DASHBOARD}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
        </Tooltip>
        <ListItem button component={NavLink} to={Routes.SITE}>
          <ListItemIcon>
            <SiteIcon />
          </ListItemIcon>
          <ListItemText primary="Site" />
        </ListItem>
        <ListItem button component={NavLink} to={Routes.AFFILIATES}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Affiliates" />
        </ListItem>
        <ListItem button component={NavLink} to={Routes.ORDERS}>
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Orders" />
        </ListItem>
        <ListItem button component={NavLink} to={Routes.PAYOUTS}>
          <ListItemIcon>
            <PayoutIcon />
          </ListItemIcon>
          <ListItemText primary="Process Payouts" />
        </ListItem>
        <ListItem button component={NavLink} to={Routes.PAYOUTHISTORY}>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary="Payout History" />
        </ListItem>
        <ListItem button onClick={() => setOpen(!open)}>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Reports" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              component={NavLink}
              to={"/report/key-metrics"}
              className={classes.nested}
            >
              <ListItemText primary="Key Metrics" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={"/report/period-recruits"}
              className={classes.nested}
            >
              <ListItemText primary="New Recruits" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={"/report/recruits"}
              className={classes.nested}
            >
              <ListItemText primary="Total Recruits" />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to={"/report/commissions"}
              className={classes.nested}
            >
              <ListItemText primary="Commission Summary" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </div>
  );
}

export function SecondaryListItems(props) {
  const { role } = props;

  return (
    <div>
      <List>
        <ListSubheader inset>Admin</ListSubheader>
        <ListItem button component={NavLink} to={Routes.COMMISSIONS}>
          <ListItemIcon>
            <CommissionsIcon />
          </ListItemIcon>
          <ListItemText primary="Comp Plan" />
        </ListItem>
        <ListItem button component={NavLink} to={Routes.SHARES}>
          <ListItemIcon>
            <SharesIcon />
          </ListItemIcon>
          <ListItemText primary="Shares" />
        </ListItem>
        <ListItem button component={NavLink} to={Routes.BONUSES}>
          <ListItemIcon>
            <BonusesIcon />
          </ListItemIcon>
          <ListItemText primary="Bonuses" />
        </ListItem>
        {hasAdmin(role) && (
          <>
            <ListItem button>
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
              <ListItemText primary="Integrations" />
            </ListItem>
            <ListItem button component={NavLink} to={Routes.ADMINS}>
              <ListItemIcon>
                <UsersIcon />
              </ListItemIcon>
              <ListItemText primary="Admins" />
            </ListItem>
          </>
        )}
        {hasSuper(role) && (
          <ListItem button>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItem>
        )}
      </List>
    </div>
  );
}
