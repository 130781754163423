import React from 'react'
import Typography from '@material-ui/core/Typography'

function Copyright () {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}{new Date().getFullYear()} {' '}
      {/* <Link color='inherit' href='https://material-ui.com/' target='new'> */}
        gigfiliate
      {/* </Link> */}
      {/* {new Date().getFullYear()} */}
      {'.'}
    </Typography>
  )
}

export default Copyright
