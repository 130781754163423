import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  IconButton,
  Grid,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

export default function DeleteButton(props) {
  const { deleteIcon, cancelIcon, onDelete } = props;
  const [confirmDelete, setConfirm] = useState(false);

  function onDeleteClick() {
    if (!confirmDelete)
      setConfirm(true);
    else
      onDelete();
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
        <IconButton
          onClick={onDeleteClick}
          edge='start'
          color={confirmDelete ? 'secondary' : 'inherit'}
          aria-label='Delete' >
          {deleteIcon}
        </IconButton>
      </Grid>
      {confirmDelete &&
        <Grid item>
          <IconButton
            color='inherit'
            edge='start'
            onClick={() => setConfirm(false)}
            aria-label='Cancel' >
            {cancelIcon}
          </IconButton>
        </Grid>
      }
    </Grid>
  );
}

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  deleteIcon: PropTypes.node.isRequired,
  cancelIcon: PropTypes.node.isRequired,
}

DeleteButton.defaultProps = {
  deleteIcon: <DeleteForeverIcon/>,
  cancelIcon: <ClearIcon/>,
}
