import React from 'react';
import { Typography, Switch, Grid, makeStyles } from '@material-ui/core';

export default function ToggleSwitch(props) {
  const { onChange, label, value, toggleOptions, inputProps } = props;
  const classes = useStyles();

  return (
    <Grid container style={{ margin: '10px 0 0 0', gap: '15px' }} spacing={2}>
      <Typography color='textSecondary' display='block'>
        {label}
      </Typography>
      <Switch
        disabled={inputProps.readOnly}
        checked={value === toggleOptions.value2}
        onChange={(e) => onChange(e.target.checked)}
        classes={
          {
            disabled: value === toggleOptions.value2 ? classes.colorPrimary : classes.colorRevert,
            checked: classes.colorPrimary,
            track: value === toggleOptions.value2 ? classes.backgroundColorPrimary : undefined
          }}
      />
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  colorRevert: {
    color: 'revert !important'
  },
  colorPrimary: {
    color: `${theme.palette.primary.main} !important`
  },
  backgroundColorPrimary: {
    backgroundColor: `${theme.palette.primary.dark} !important`
  }
}));