import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SelectInput from '../SelectInput';

const LEVEL_TYPES = [
  {
    value: 'AND',
    label: 'AND',
  },
  {
    value: 'OR',
    label: 'OR',
  },
];
export default function InclusionInput(props) {
  const classes = useStyles();

  return (
    <SelectInput
      className={classes.typeSelect}
      options={LEVEL_TYPES}
      label='Logic'
      {...props}
    />
  );
}

const useStyles = makeStyles({
  typeSelect: {
    minWidth: 65,
  },
});
