import React from 'react';
import * as _ from 'lodash'
import moment from 'moment';
import { useTheme } from '@material-ui/core/styles';

import { LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
} from 'recharts';

import Title from '../Title';

export default function OrdersByHour(props) {
  const { orders } = props;
  const theme = useTheme();

  const data = _(orders)
    .groupBy(o => moment(o.orderDate).get('hour'))
    .map((group, hour) => ({
      time: hour,
      amount: Math.round(_.sumBy(group, o => o.personalVolume + o.customerVolume))
    }))
    .value();

  return (
    <>
      <Title>Orders by Hour</Title>
      
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 16,
            right: 16,
            bottom: 15,
            left: 24,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" stroke={theme.palette.text.secondary} >
            <Label
              offset={-12}
              position="insideBottom"
              style={{ fill: theme.palette.text.primary }}
            >
              Time of Day
            </Label>
          </XAxis>
          <YAxis stroke={theme.palette.text.secondary}>
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
            >
              Sales ($)
            </Label>
          </YAxis>
          <Line type="monotone" dataKey="amount" stroke={theme.palette.primary.main} dot={true} />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
