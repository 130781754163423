import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { LinearProgress, Grid, Tooltip, IconButton, Divider } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';

import { paperStyle } from '../styles';
import Title from '../components/Title';
import BonusesTable from '../components/Bonuses/BonusesTable';
import { getSiteBonuses, getSiteBonusesSnapshots } from '../actions/sites';
import Routes from '../routes';
import { useHistory } from 'react-router-dom';

import { hasAdmin } from '../lib';
import TableStyledAccordion from '../components/TableStyledAccordion';

function Bonuses(props) {
  const { getSiteBonuses, getSiteBonusesSnapshots, site, role } = props;
  const classes = useStyles();

  const history = useHistory();

  const [bonuses, setBonuses] = useState([]);
  const [bonusesSnapshots, setBonusesSnapshots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSnapshots, setLoadingSnapshots] = useState(false);

  useEffect(() => {
    setLoading(true);

    getSiteBonuses()
      .then((res) => setBonuses(res.bonuses))
      .finally(() => setLoading(false));
  }, [getSiteBonuses, site]);

  function handleClick() {
    history.push(Routes.NEW_BONUS);
  }

  const handleSnapshotsOpen = () => {
    setLoadingSnapshots(true);

    getSiteBonusesSnapshots()
      .then((res) => setBonusesSnapshots(res.bonusesSnapshots))
      .finally(() => setLoadingSnapshots(false));
  }

  return (
    <>
      <div className={classes.paper}>
        <Grid container justifyContent='space-between'>
          <Grid item xs>
            <Title>Bonuses</Title>
          </Grid>
          <Grid item>
            {hasAdmin(role) && (
              <Tooltip title='Add Bonuses'>
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='Add Bonuses'
                  onClick={handleClick}
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </div>

      {loading ?
        <LinearProgress className={classes.progress} />
        :
        <>
          <BonusesTable rows={bonuses || []} />
          <Divider className={classes.divider} />
          <TableStyledAccordion
            title='Bonuses History'
            helpText='Browse Bonus Plans in the Past'
            onOpen={handleSnapshotsOpen}
            content={<BonusesTable rows={bonusesSnapshots || []} isSnapshots isLoading={loadingSnapshots} />}
          />
        </>
      }
    </>
  );
}

Bonuses.propTypes = {
  rows: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    site: state.sites.get('site'),
    role: state.user.getIn(['user', 'role']),
  };
}

export default connect(mapStateToProps, { getSiteBonuses, getSiteBonusesSnapshots })(Bonuses);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    ...paperStyle,
  },
  progress: {
    marginTop: 20,
    marginBottom: 20,
  },
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
}));
