import React from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { useHistory } from 'react-router';

import { CompleteForm } from '../Form';

const defaultAdmin = {
  name: '',
  familyName: '',
  email: '',
};

export default function AdminForm(props) {
  const { admin, message } = props;
  const hist = useHistory();

  /*
   * Return an error message if anything is bad, otherwise null.
   */
  function validateEdits(newAdmin) {
    // TODO other validation

    if (
      _.some(
        [
          ['attributes', 'name'],
          ['attributes', 'familyName'],
          ['attributes', 'email'],
        ],
        (k) => !_.get(newAdmin, k)
      )
    )
      return "You haven't updated all the required fields!";

    return null;
  }

  return (
    <CompleteForm
      noEdit
      title={
        admin
          ? `Administrator - ${admin.attributes.name} ${admin.attributes.familyName}`
          : 'New Administrator'
      }
      object={admin || defaultAdmin}
      newObject={!admin}
      message={message}
      validateFunc={validateEdits}
      onSave={props.onSave}
      onDelete={props.onDelete}
      onCancel={() => hist.goBack()}
      fields={ADMIN_FIELDS}
    />
  );
}

AdminForm.propTypes = {
  admin: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

const ADMIN_FIELDS = {
  General: [
    {
      label: 'First Name',
      key: ['attributes', 'name'],
    },
    {
      label: 'Last Name',
      key: ['attributes', 'familyName'],
    },
    {
      label: 'Email',
      key: ['attributes', 'email'],
    },
  ],
};
