import { getOrderURL } from '../lib/api';
import { siteAPIGet } from './sites';
import { apiPut, apiPost, apiDelete } from '.';

export function getOrders(params) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site)
      throw Error('Tried to call getOrders without a site');

    return dispatch(siteAPIGet(getOrderURL(site.id), params));
  }
}

export function getOrder(orderId, params) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call getOrder without a site');

    return dispatch(siteAPIGet(getOrderURL(site.id) + `/${orderId}`, params));
  }
}

export function orderAPIPut(orderId, params, camelTransform) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call orderAPIPut without a site');

    return dispatch(apiPut(
      getOrderURL(site.id) + `/${orderId}`, params, camelTransform
    ));
  }
}

export function orderAPIPost(order, camelTransform) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call orderAPIPost without a site');

    return dispatch(apiPost(getOrderURL(site.id), order, camelTransform));
  }
}

export function orderAPIDelete(orderId) {
  return (dispatch, getState) => {
    const site = getState().sites.get('site');
    if (!site) throw Error('Tried to call orderAPIDelete without a site');

    return dispatch(apiDelete(getOrderURL(site.id) + `/${orderId}`));
  }
}