import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import * as _ from 'lodash';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Title from '../Title';
import { paperStyle } from '../../styles';
import FormActions from './FormActions';
import FormAlert from './FormAlert';
import GeneralForm from './Form';
import ActionDialog from '../ActionDialog';

export { default as FormActions } from './FormActions';
export { default as FormAlert } from './FormAlert';
export { default as GeneralForm } from './Form';

function ProcessDialog(props) {
  return (
    <ActionDialog
      {...props}
      title={`Delete ${props.message}`}
      message={`Are you sure you want to delete ${props.message}?`}
      submitLabel='Delete'
    />
  );
}

export function useForm({
  obj,
  saveFunc,
  cancelFunc,
  deleteFunc,
  validateFunc = null,
  editingInitial = false,
  noEdit = false
}) {
  const [editObj, updateObj] = useState(Immutable.fromJS(obj));
  const [editing, setEditing] = useState(editingInitial);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  function onCancel() {

    if (!editingInitial && !noEdit) {
      updateObj(Immutable.fromJS(obj));
      setEditing(false);
    } else if (cancelFunc) {
      cancelFunc();
    }
  }

  async function onSave() {
    const obj = editObj.toJS();

    if (validateFunc) {
      const error = validateFunc(obj);

      if (!_.isNil(error)) {
        setError(error);
        return;
      }
    }

    setSaving(true);

    const { success, message } = await saveFunc(obj);

    if (success) setSuccess(message);
    else setError(message);

    setEditing(!success);
    setSaving(false);
  }

  async function onDelete() {
    const { success, message } = await deleteFunc();

    if (success) setSuccess(message);
    else setError(message);
  }

  // reset the editing stuff if obj changes (as defined by an id)
  useEffect(() => {
    updateObj(Immutable.fromJS(obj));
    // this is not just setEditing(false) for a good reason, don't change
    setEditing(editingInitial);
  }, [editingInitial, obj]);

  return {
    obj: editObj,
    editing,
    saving,
    success,
    error,
    disableSave: saving || Immutable.fromJS(obj).equals(editObj),

    onSave: editing ? onSave : null,
    onDelete: onDelete,
    onCancel: editing || noEdit ? onCancel : undefined,
    onEdit: !editing ? () => setEditing(true) : null,
    onUpdateObj: updateObj,
    clearAlert: () => {
      setSuccess(null);
      setError(null);
    },
  };
}

export function CompleteForm(props) {
  const classes = useStyles();

  const {
    object,
    title,
    newObject,
    noEdit,
    noDelete,
    fields,
    fieldsWhenEditing,
    message,
    handlePayoutTypeSelect,
    extraFormActions
  } = props;
  const {
    obj,
    editing,
    success,
    error,
    disableSave,

    onSave,
    onDelete,
    onCancel,
    onEdit,
    onUpdateObj,
    clearAlert,
  } = useForm({
    obj: object,
    saveFunc: props.onSave,
    cancelFunc: props.onCancel,
    deleteFunc: props.onDelete,
    validateFunc: props.validateFunc,
    editingInitial: newObject,
    noEdit: noEdit
  });

  const [deleting, setDeleting] = useState(false);

  function closeModal() {
    setDeleting(false);
  }

  function openModal() {
    setDeleting(true);
  }

  return (
    <>
      <ProcessDialog
        open={Boolean(deleting)}
        message={message}
        onSubmit={onDelete}
        onCancel={() => closeModal()}
      />
      <Paper className={classes.paper + ' ' + (props.className || '')}>
        <FormAlert
          onClose={clearAlert}
          message={error ? error : success}
          severity={error ? 'error' : 'success'}
        />

        <Grid container justifyContent='space-between'>
          <Grid item xs>
            <Title>{title}</Title>
          </Grid>
          <Grid item>
            {/* NOTE: the timeout on the save call avoids a pretty dumb bug with snackbar
                where it seems that setting state that would launch open the snackbar
                from within a button handler can cause it to immediately close
              */}
            <FormActions
              noEdit={noEdit}
              noDelete={noDelete || editing}
              onEdit={onEdit}
              onCancel={onCancel}
              onSave={onSave ? () => setTimeout(onSave, 50) : null}
              disableSave={disableSave}
              onDelete={openModal}
              customActions={extraFormActions}
            />
          </Grid>
        </Grid>
        {fieldsWhenEditing && editing ?
          <Grid container>
            <Grid item xs={6} s={6}>
              <GeneralForm
                object={obj}
                handlePayoutTypeSelect={handlePayoutTypeSelect}
                editing={editing}
                newObject={newObject}
                onUpdate={onUpdateObj}
                fields={fieldsWhenEditing}
              />
            </Grid>
          </Grid>
          :
          fields && (
            <Grid container>
              <Grid item xs={6} s={6}>
                <GeneralForm
                  object={obj}
                  handlePayoutTypeSelect={handlePayoutTypeSelect}
                  editing={editing}
                  newObject={newObject}
                  onUpdate={onUpdateObj}
                  fields={fields}
                />
              </Grid>
            </Grid>
          )}
      </Paper>
      {props.formRender &&
        props.formRender({
          editing,
          onSave,
          onDelete,
          object: obj,
          onUpdate: onUpdateObj,
        })}
    </>
  );
}

CompleteForm.propTypes = {
  title: PropTypes.string.isRequired,
  object: PropTypes.any.isRequired,
  fields: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fieldsWhenEditing: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  noEdit: PropTypes.bool,
  newObject: PropTypes.bool,
  formRender: PropTypes.func,
  message: PropTypes.string,
  handlePayoutTypeSelect: PropTypes.func,
  extraFormActions: PropTypes.arrayOf(PropTypes.node),

  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  validateFunc: PropTypes.func,
};

const useStyles = makeStyles({
  paper: paperStyle,
});
