import Immutable from 'immutable';

import { createReducer, simpleSetter } from '../lib/reducers';
import {
  SET_ALERT,
} from '../actions/types';

export default createReducer(Immutable.Map({
  alert: null,
}), {
  // Action type -> function
  [SET_ALERT]: simpleSetter('alert'),
});
